import { LitElement, html, css, customElement, property } from 'lit-element';
import { cache } from 'lit-html/directives/cache.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

import 'components/cast-spinner';

import pauseIcon from 'icons/pause.svg';
import playIcon from 'icons/play.svg';

/**
 * cast-player-overlay component
 *
 * Information and status overlay shown above the player
 *
 * @class cast-player-overlay
 * @hideconstructor
 */
@customElement('cast-player-overlay')
class PlayerOverlay extends LitElement {
  /**
   * Overlay state
   *
   * One of `"idle"`, `"buffering"`, `"paused"`, or `"playing"`
   *
   * @name cast-player-overlay#state
   * @type {string}
   * @default "idle"
   */
  @property({
    type: String,
    reflect: true
  })
  state = 'idle';

  /**
   * Flag indicating the scrubber is shown
   *
   * Can be set by the `show-scrubber` attribute. This will be assumed to be
   * true if `showMetadata` is true
   *
   * @name cast-player-overlay#showScrubber
   * @type {boolean}
   * @default false
   */
  @property({
    type: Boolean,
    reflect: true,
    attribute: 'show-scrubber'
  })
  showScrubber = false;

  /**
   * Flag indicating the entity metadata is shown
   *
   * Can be set by the `show-metadata` attribute.
   *
   * @name cast-player-overlay#showMetadata
   * @type {boolean}
   * @default false
   */
  @property({
    type: Boolean,
    reflect: true,
    attribute: 'show-metadata'
  })
  showMetadata = false;

  /**
   * Flag indicating the up-next info is shown
   *
   * Can be set by the `show-up-next` attribute.
   *
   * @name cast-player-overlay#showUpNext
   * @type {boolean}
   * @default false
   */
  @property({
    type: Boolean,
    reflect: true,
    attribute: 'show-up-next'
  })
  showUpNext = false;

  static styles = css`
    :host {
      --horizontal-pad: 13.3rem;
      --vertical-pad: 12.2rem;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }

    :host([show-metadata]),
    :host([show-scrubber]),
    :host([show-up-next]) {
      background: linear-gradient(to top, rgba(0,0,0,0.9) 0, rgba(0,0,0,0) 61.6rem);
    }

    :host([state="paused"]) {
      background: rgba(0,0,0,0.7);
    }

    .pause svg {
      height: 9rem;
    }

    .play svg {
      height: 9rem;
    }

    .info {
      position: absolute;
      padding: var(--vertical-pad) var(--horizontal-pad);
      left: 0;
      right: 0;
      bottom: 0;
    }

    ::slotted([slot="metadata"]) {
      opacity: 0;
    }

    :host([show-metadata]) ::slotted([slot="metadata"]) {
      opacity: 1;
    }

    ::slotted([slot="scrubber"]) {
      opacity: 0;
    }

    :host([show-metadata]) ::slotted([slot="scrubber"]),
    :host([show-scrubber]) ::slotted([slot="scrubber"]) {
      opacity: 1;
    }

    ::slotted([slot="up-next"]) {
      opacity: 0;
    }

    :host([show-up-next]) ::slotted([slot="up-next"]) {
      opacity: 1;
    }

    /* Hide metadata and scrubber when showing up-next */
    :host([show-up-next]) ::slotted([slot="metadata"]),
    :host([show-up-next]) ::slotted([slot="scrubber"]) {
      opacity: 0;
    }
  `;

  /**
   * Show metadata for a period of time
   *
   * @param {Number} displayMs - Number of ms to display metadata for
   */
  showMetadataFor(displayMs) {
    this.showMetadata = true;

    if (this.showMetadataTimer) {
      clearTimeout(this.showMetadataTimer);
    }

    if (displayMs) {
      this.showMetadataTimer = setTimeout(() => {
        this.showMetadata = false;
        this.showMetadataTimer = null;
      }, displayMs);
    }
  }

  /**
   * Show scrubber for a period of time
   *
   * @param {Number} displayMs - Number of ms to display scrubber for
   */
  showScrubberFor(displayMs) {
    this.showScrubber = true;

    if (this.showScrubberTimer) {
      clearTimeout(this.showScrubberTimer);
    }

    if (displayMs) {
      this.showScrubberTimer = setTimeout(() => {
        this.showScrubber = false;
        this.showScrubberTimer = null;
      }, displayMs);
    }
  }

  stateRender = {
    idle: () => html``,
    buffering: () => html`<cast-spinner active/>`,
    paused: () => html`<div class="pause">${ unsafeHTML(pauseIcon) }</div>`,
    playing: () => html`<div class="play">${ unsafeHTML(playIcon) }</div>`
  };

  render() {
    const renderer = this.stateRender[this.state] || this.stateRender.idle;

    return html`
      ${ cache(renderer()) }
      <div class="info">
        <slot name="metadata"></slot>
        <slot name="scrubber"></slot>
      </div>
      <div class="info">
        <slot name="up-next"></slot>
      </div>
    `;
  }
}

export default PlayerOverlay;
