import ChannelModel from 'model/channel';
import StreamModel from 'model/stream';
import WatchableModel from 'model/watchable';
import watchableTypes from 'constants/watchable-types';

const getChannel = async (resource) => {
  if (resource.hasEmbedded('provider/channel') || resource.hasLink('provider/channel')) {
    const provider = await resource.fetchEmbedded('provider/channel');
    return ChannelModel.load(provider.data.getProp('channelId'));
  }
};

const getStreams = async (resource) => {
  const streamUrl = resource.getFirstAction('stream').getActionUrl();
  return [await StreamModel.fromUrl(streamUrl)];
};

/**
 * Recording object
 *
 * @hideconstructor
 */
class RecordingModel extends WatchableModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();
    const [inheritedProps, channel, streams] = await Promise.all([
      super._propertiesFromResource(resource),
      getChannel(resource),
      getStreams(resource)
    ]);

    return {
      ...inheritedProps,
      id: props.id,
      dateRecordedTimestamp: new Date(props.dateRecorded).valueOf(),
      derivedTitle: props.derivedTitle,
      recordingStatus: props.recordingStatus,
      rating: props['contentRating/detailed'],
      isAdult: props.isAdult,
      channel,
      streams
    };
  }

  /**
   * Build recording watchable from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {RecordingModel}
   */
  static async fromResource(resource) {
    return new RecordingModel(await RecordingModel._propertiesFromResource(resource));
  }
}

WatchableModel.addType(RecordingModel, ({ _type }) =>
  _type === watchableTypes.Recording);

export default RecordingModel;
