export const XVPServices = {
  browseProgramsApi: {
    xvpServiceName: 'DiscoAPI',
    endPoints: [
      { xtvEndpoint: 'getPrograms',
        xvpApiCall: 'programsGet',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['availabilityResolution', 'cacheProfile', 'location', 'drmToken', 'entitledStations']
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getApps',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['availabilityResolution', 'cacheProfile', 'location', 'drmToken', 'entitledStations']
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getWatchOptionsForProgram',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getEntityMetaDataForNewProgram',
        required: ['entityId', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getWatchOptionsForSeriesMaster',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getWatchOptionsForTvSeason',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getWatchOptionsForTvSeasonUngrouped',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['availabilityResolution', 'cacheProfile', 'location', 'drmToken', 'entitledStations']
      }
      /*
      { xtvEndpoint: '',
        xvpApiCall: 'getEntityMetaDataForTvSeason', // rewa: `getTvSeasonMetadata`
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getEntityMetaDataForSportsTeam', // rewa: `getTeamMetadata`
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getEntityMetaDataForProgramReviews', // rewa: `getProgramMetadata` not sure on this one
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      } */
    ]
  },
  deviceSessionAPIapi: {
    xvpServiceName: 'SessionAPI',
    endPoints: [
      { xtvEndpoint: 'getDeviceSessionInfo',
        xvpApiCall: 'getDeviceSession',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['availabilityResolution', 'cacheProfile', 'location', 'drmToken', 'entitledStations']
      },
      { xtvEndpoint: 'createDeviceSessionOptionalPartner',
        xvpApiCall: 'createDeviceSessionOptionalPartner',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['availabilityResolution', 'cacheProfile', 'location', 'drmToken', 'entitledStations']
      }]
  },
  satTokenApi: {
    xvpServiceName: 'BestieAPI',
    endPoints: [
      { xtvEndpoint: '',
        xvpApiCall: 'satInfo',
        required: [],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'satPing',
        required: [],
        optionalParams: []
      }
    ]
  },
  watchOptionsApi: {
    xvpServiceName: 'BestieAPI',
    endPoints: [
      { xtvEndpoint: '',
        xvpApiCall: 'watchOptionInfo',
        required: [],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'watchOptionsPing',
        required: [],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getWatchOptions',
        required: [],
        optionalParams: []
      }
    ]
  },
  privacyPreferencesApi: {
    xvpServiceName: 'BestieAPI',
    endPoints: [
      { xtvEndpoint: '',
        xvpApiCall: 'getAdPersonalizationPreference',
        required: [],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'privacyPrefsPing',
        required: [],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'privacyPrefsInfo',
        required: [],
        optionalParams: []
      }
    ]
  },
  channelsApi:
  { //  metadata for the actual xvp service - overkill?
    xvpServiceName: 'LinearAPI',
    //  end points supported
    endPoints: [
      { xtvEndpoint: 'getChannelMap',
        xvpApiCall: 'getEntitledTvGridChannels',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getEntitledTvGridChannels',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'getLocalTvGridChannels',
        required: ['f2m', 'include', 'deviceId', 'clientId', 'partnerId', 'xboAccountId'],
        optionalParams: []
      }
    ]
    // LinearAPI.channelsApi.getChannels(reqParams, opts)
  },
  listingsApi: {
    xvpServiceName: 'LinearAPI',
    endPoints: [
      {
        xtvEndpoint: '',
        xvpApiCall: 'getListingsByDate',
        requiredParams: ['startTime', 'endTime', 'partnerId', 'deviceId', 'clientId', 'xboAccountId'],
        optionalParams: ['include', 'ifNoneMatch']
      }]
  },
  resumePointsApi: {
    xvpServiceName: 'PlaybackAPI',
    endPoints: [
      { xtvEndpoint: 'updateResumePoint',
        xvpApiCall: 'createUpdateResumePoint',
        requiredParams: ['entityType', 'entityId', 'resumePointRequest', 'partnerId', 'clientId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'createUpdateResumePoint',
        requiredParams: ['entityType', 'entityId', 'resumePointRequest', 'partnerId', 'clientId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: '',
        xvpApiCall: 'deleteResumePoint',
        requiredParams: ['entityType', 'entityId', 'partnerId', 'clientId', 'xboAccountId'],
        optionalParams: []
      },
      { xtvEndpoint: 'getResumePoints',
        xvpApiCall: 'getResumePoints',
        requiredParams: ['partnerId', 'clientId', 'xboAccountId'],
        optionalParams: ['companyId', 'durableAppId', 'ownerReferenceFilter', 'limit']
      }
    ]
  },
  rightsApi: {
    xvpServiceName: 'RightsAPI',
    endPoints: [
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatLinear',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'streamId'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatLinear',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'streamId'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatTVELinear',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'streamId'],
        optionalParams: ['devicePostalCode']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatTVELinear',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'streamId'],
        optionalParams: ['devicePostalCode']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatVod',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: ['includePurchases', 'mediaAccountName']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatVod',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatTveVod',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: ['includePurchases', 'mediaAccountName']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatTveVod',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatPurchase',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: ['includePurchases', 'mediaAccountName']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatPurchase',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaGuid'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatRecording',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'recordingId'],
        optionalParams: ['includePurchases', 'mediaAccountName']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'deleteHeartbeatRecording',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'recordingId'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'putHeartbeatTveRecording',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'recordingId'],
        optionalParams: []
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'startLinearTveExternal',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'externalStreamId', 'streamId', 'provider'],
        optionalParams: ['eventId', 'serviceZip']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'startVodTveExternal',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'mediaLocator'],
        optionalParams: ['eventId']
      },
      {
        xtvEndpoint: '',
        xvpApiCall: 'canStreamTve',
        requiredParams: ['partnerId', 'deviceId', 'clientId', 'xboAccountId', 'streamId'],
        optionalParams: ['serviceZone', 'locatorType']
      }
    ]
  }
};

export const getXVPEndpoint = (endPoint) =>{
  const APIs = Object.entries(XVPServices);
  const resourceData = APIs.find((apiResource) => {
    const endResult = apiResource.find((endPointData) => {
      return endPointData.endPoints && endPointData.endPoints.find((endParams) => {
        const serviceEndPoint = endParams.xtvEndpoint ? endParams.xtvEndpoint : endParams.xvpApiCall;
        return serviceEndPoint && serviceEndPoint.toLowerCase() === (endPoint && endPoint.toLowerCase());
      });
    });
    return endResult;
  });
  return { xvpApi: resourceData[0], endPointData: resourceData[1] };
};
export const getProgramsGetRequest = (optionField = {} ) => {
  return {
    // partnerId: '',
    // accountId: '',
    // deviceId: '',
    // clientId: '',
    // contentCatalogTypes: [],
    pawProgramId: '',
    usePawSeed: undefined,
    numShowings: 'all',
    numLinearShowings: 'all',
    numVodShowings: '100',
    limit: 100,
    offset: 0,
    episodeLimit: 100,
    episodeOffset: 1,
    entityField: [],
    episodeField: [],
    showingField: [],
    // facetField: ['productContextId', 'productContextType', 'providerCompanies',
    //  'linearCompanies', 'vod', 'linear', 'preorder', 'est', 'ppv', 'downloadable',
    //  'expirationDate', 'availableDate', 'newUntil', 'lastChanceFrom', 'videoQuality'],
    preferredVideoQuality: 'UHD',
    preferredLanguage: 'en-us',
    entitySort: ['airtime'],
    episodeSort: ['desc:tvSeasonNumber'],
    vodShowingsSort: [],
    livodShowingsSort: [],
    linearShowingsSort: [],
    f2m: 'only',
    f2mSample: '',
    entityTag: '',
    disableEpisodeEntityTag: false,
    adult: 'exclude',
    kids: 'include',
    hasDvs: false,
    isSap: undefined,
    isClosedCaption: undefined,
    isDownloadable: undefined,
    colorDepth: [],
    isSubtitled: undefined,
    isIVod: undefined,
    newVod: undefined,
    airingType: '',
    videoFormat: '',
    videoQuality: ['UHD', 'HD'],
    tvSeasonundefined: '',
    companyTypeTag: '',
    companyId: '',
    providerCompanyId: '',
    distributorCompanyId: '',
    providerDisplayCode: '',
    stationId: '',
    durableAppId: 'Comcast_StreamApp',
    audioTrackLanguage: ['en', 'es'],
    closedCaptionLanguage: ['en', 'es'],
    dvsLanguage: ['en'],
    rating: '',
    isSubjectToBlackout: undefined,
    vodType: [],
    livodType: [],
    offerType: [],
    onNow: '',
    upcoming: '',
    licenseStartVod: '<7d',
    licenseEndVod: '<7d',
    windowStart: [],
    windowEnd: [],
    personId: '',
    teamId: '',
    parentId: '',
    relatedPersonByTeam: '',
    relatedTeamByPerson: '',
    //  titleStart: [],
    releaseYear: '',
    rottenTomatoesCriticScore: '',
    rottenTomatoesFanScore: '',
    programId: [],
    programType: '(movie OR seriesMaster)',
    language: '',
    tvRating: '',
    mpaaRating: '',
    starRating: '',
    collectionId: '',
    nonCollectionPrograms: '',
    collectionRandomizeTopN: undefined,
    watched: '',
    favorite: '',
    episodeOnlyCredit: undefined,
    entityIdOperator: '',
    include: [],
    isThreeD: undefined,
    cacheKey: '',
    usecase: ''
  };
};

export default { getProgramsGetRequest, getXVPEndpoint, XVPServices };
