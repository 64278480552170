/**
 * API middleware to allow late customiztion of request headers and body
 *
 * The `customHeader` option can be used to provide headers to be merged into
 * the request headers. The `customBody` option provides a request body.
 *
 * @memberof module:API
 */
class CustomizeRequestMiddleware {
  // No jsdoc
  handler = (url, { customHeaders, customBody, ...options }, next) => {
    const addons = {};

    if (typeof customHeaders === 'object') {
      addons.headers = {
        ...options.headers,
        ...customHeaders
      };
    }

    if (customBody) {
      addons.body = customBody;
    }

    return next(url, {
      ...options,
      ...addons
    });
  }
}

export default CustomizeRequestMiddleware;
