/**
 * @module DeviceSessionApi
 */
import Logger from 'lib/logger';
import { DeviceSessionAPIApi } from '@devplat/xvp-session-client';

import config from 'config';
import { senderDebugger } from '../../debug/sender-receiver-debug';

const SWAGGER_CLIENT_ID = 'swagger-ui';
const STG_BASE_PATH = 'https://session-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://session.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP', { background: 'blue', color: 'white' });


class SessionAPI {
  _session = null;
  _ready = false;
  _deviceSessionAPIApi = null;
  environment = 'prod';

  get ready() {
    return this._ready;
  }
  constructor() {
    console.log('  session constructor here? ');
  }
  init( params = {} ) {
    // TODO error check on session config data
    this._session = params.session;
    this._ready = true;
    this.environment = config.env || config.environment.ENV;
    this._deviceSessionApi = this._getDeviceSessionApi();

    senderDebugger.sendDebugMessage(' can I access getdeviceSessionApi( here? ' + JSON.stringify(config));
  }
  async getDeviceSession() {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getDeviceSession using _deviceSessionApi:', paramObj);
    return await this._deviceSessionApi && this._deviceSessionApi.getDeviceSession(paramObj);
  }
  async createDeviceSessionOptionalPartner( options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      createDeviceSessionRequest: options.createDeviceSessionRequest,
      ...options
    };

    senderDebugger.debugNetworkMessage('[XVP][API] createDeviceSessionOptionalPartner using _deviceSessionApi:', paramObj);
    return await this._deviceSessionApi && this._deviceSessionApi.createDeviceSessionOptionalPartner(paramObj);
  }
  async deleteDeviceSession() {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId
    };

    senderDebugger.debugNetworkMessage('[XVP][API] deleteDeviceSession using _deviceSessionApi:', paramObj);
    return await this._deviceSessionApi && this._deviceSessionApi.deleteDeviceSession(paramObj);
  }
  get deviceSessionApi() {
    if (!this._deviceSessionApi || !this.ready) {
      logger.error('deviceSessionApi: deviceSessionApi is: ' + this.deviceSessionApi +
    ' Session Class Service ready is: ' + this.ready );
      return null;
    }
    return this._deviceSessionApi;
  }

  _getDeviceSessionApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('getdeviceSessionApi: did not provide tokens');
      return;
    }
    return new DeviceSessionAPIApi({
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
}
export default new SessionAPI();
export { DeviceSessionAPIApi };
