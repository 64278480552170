/**
 * API middleware to add error details to response object
 *
 * This adds an `xtv` {@link XTVErrorResponse} object to the response object
 * when the request fails.
 *
 * This must be in the middleware chain after the
 * [RetryMiddleware]{@link module:API.RetryMiddleware} since it uses the
 * `retriesRemaining` option provided by that middleware.
 *
 * @memberof module:API
 */
class ErrorMiddleware {
  normalizeResponse(response = {}) {
    if (response instanceof Response) {
      return response;
    }

    let statusText;
    if (response.message) {
      // Fetch exception (Network errors only) URL blocking, CORS, no connection, etc.
      statusText = `${ response.message } (Possible CORS error)`;
    } else if (response.error && response.error.msg) {
      // Timeout, etc. detected in HyperGard
      statusText = response.error.msg;
    } else {
      statusText = 'Possible CORS error';
    }

    return new Response('', {
      status: 503,
      statusText
    });
  }

  getHeaders(response) {
    const headers = {};
    (response.headers || []).forEach((value, key) => {
      headers[key.toLowerCase()] = value;
    });
    return headers;
  }

  // No jsdoc
  handler = async (url, options, next) => {
    try {
      return await next(url, options);
    } catch (err) {
      const { action, fatal, retriesRemaining } = options;
      const response = this.normalizeResponse(err);
      const headers = this.getHeaders(response);

      /**
       * Additional object for error responses with XTV-specific data
       *
       * @typedef {object} XTVErrorResponse
       * @property {boolean} fatal - Is this a fatal error. True if the `fatal` option
       *    was passed to the request and there are no retires remaining.
       * @property {string} code - Response error code
       * @property {string} subcode - Response error subcode (from `X-CDVR-Status-Subcode` header)
       * @property {string} msg - `X-CDVR-Status-Reason` header or response status text if appropriate
       * @property {string} fingerprint - Fingerprint from `Fingerprint` header
       * @property {object} headers - Response headers
       */
      response.xtv = {
        endpoint: action,
        fatal: (!!fatal && !retriesRemaining),
        code: (response.status || '').toString(),
        subCode: (headers['x-cdvr-status-subcode'] || '').split('-').pop(), // Some subcode headers include the code
        msg: headers['x-cdvr-status-reason'] || response.statusText || '',
        fingerprint: '',
        headers
      };

      if (headers.fingerprint) {
        response.xtv.fingerprint = headers.fingerprint;
      } else if (options.headers && options.headers.Fingerprint) {
        response.xtv.fingerprint = options.headers.Fingerprint;
      }

      throw response;
    }
  }
}

export default ErrorMiddleware;
