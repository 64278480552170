/**
 * Constants for events emitted by PlayerController
 *
 * @namespace constants.controllerEvents
 */
export default {
  load: 'load',
  play: 'play',
  pause: 'pause',
  seek: 'seek',
  unload: 'unload',
  setAudioTrack: 'setAudioTrack',
  setTextTracks: 'setTextTracks'
};
