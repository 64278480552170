import api from 'api';
import authTokenProvider from 'lib/auth-token-provider';
import config from 'config';
import * as Helpers from 'lib/helpers';
import LocalSettings from 'lib/storage/local-settings';

const XACT = 'xact';
const XACT_EXP = 'xact-exp';

class Session {
  constructor() {
    this.tokenStore = new LocalSettings({
      name: 'cast-device-token', // Change to tokens-fauxvision when endpoint is available
      maxUsers: 3
    });
    authTokenProvider.expiredCallback = this.refreshSession.bind(this);
  }

  /**
  * Get an XSCT token for this chromecast device and store by sender id

  * @param {string} senderXsct
  * @param {string} senderId
  * @param {string} xboAccountId
  * @param {string} senderPartnerId
  * @param {number} retries
  *
  * @return {object} promise
  */
  async getSession(senderXsct, senderId, xboAccountId, senderPartnerId) {
    this.senderXsct = senderXsct;
    this.senderId = senderId;
    this.senderPartnerId = senderPartnerId;
    this._xboAccountId = xboAccountId;
    this.tokenStore.setAccountId(xboAccountId || senderId);

    return await this.refreshSession();
  }

  async refreshSession(retries = 0) {
    try {
      this.provisionProps = await this._provision();
      // Waiting for create-session to accept fuax xact, device-id-not-found error for now
      return await this._createSession(this.provisionProps.xact);
    } catch (error) {
      const xtvError = Helpers.getProperty(error, 'xhr.xtv');

      if (xtvError.endpoint === 'createDrmSession' && ['108', '111', '112', '203', '312'].includes(xtvError.subCode) && retries < 3) {
        this.tokenStore.removeAll();
        return await this.refreshSession(++retries);
      }

      throw xtvError;
    }
  }

  async getInHome() {
    try {
      const response = await api.send({
        endpoint: 'inHomeRestrictions'
      });

      const status = response.resource.getProp('inHomeStatus');

      return status === 'in-home';
    } catch (error) {
      // Fail open for 4xx and 5xx errors
      return (Helpers.getProperty(error, 'xhr.xtv').code >= 400);
    }
  }

  /**
   * Returns the postal code of the device location
   * based on the IP from the header.
   * Returns null if the IP lookup fails.
   * @return {string | null}
   */
  async getCurrentPostalCode() {
    try {
      const response = await api.send({ endpoint: 'getPostalCode' });
      return response.resource.getProp('zipCode');
    } catch (error) {
      return null;
    }
  }

  /**
   * We need to update the channelMap cache with the current location postal code.
   * @param {string} postalCode
   * So the channelMap will be updated with local channels as well.
   * This is based on the session and needs to be updated sometime before the channelMap request.
   */
  async updateChannelMapCache(postalCode) {
    try {
      return await api.send({
        endpoint: 'updateChannelMapCache',
        params: {
          postalCode
        }
      });
    } catch (error) {
      return null;
    }
  }

  /**
   * Returns whether legit XACT exists in local storage
   * Note: token is short-lived and we need to pass up the XACT
   * whether it is expired, to maintain same device identity.
   *
   * @returns {boolean} return whether we have a decent looking XACT
   */

  _isXactAvailable() {
    const xact = this.tokenStore.load(XACT);
    const expTime = this.tokenStore.load(XACT_EXP);

    return !!(xact && expTime && !isNaN(expTime));
  }


  /**
   * Returns whether XACT exipred or not
   *
   * @returns {boolean} return whether we have a active XACT
   */

  _isValidXact() {
    const xact = this.tokenStore.load(XACT);
    const expTime = this.tokenStore.load(XACT_EXP);
    const currentTime = Date.now();

    return !!(xact && expTime && !isNaN(expTime) && (expTime-currentTime) > 21.6e+6);
  }

  _getProvisionEndpoint(senderXsct) {
    const xact = this.tokenStore.load(XACT);

    const endpoint = {
      fatal: true,
      params: { senderXsct: senderXsct }
    };

    if (this._isXactAvailable()) {
      endpoint.name = 'refreshXactChromecast';
      endpoint.params.xact = xact;
    } else {
      endpoint.name = 'provisionChromecast';
    }

    return endpoint;
  }

  _storeXactToken(session) {
    this.tokenStore.setAccountId(Helpers.getProperty(session, 'tokenSummary.xboAccountId') || this.senderId);
    this.tokenStore.save(XACT, this.provisionProps.xact);
    this.tokenStore.save(XACT_EXP, this.provisionProps.expires * 1000);
  }

  async _provision() {
    const xact = this.tokenStore.load(XACT);
    const expires = this.tokenStore.load(XACT_EXP);

    if (this._isValidXact()) {
      return { xact, expires };
    }

    const endpoint = this._getProvisionEndpoint(this.senderXsct);

    const response = await api.send({
      endpoint: endpoint.name,
      fatal: true,
      retry: 1,
      params: endpoint.params
    });

    return response.resource.getProps();
  }

  async _createSession(xact, { hardAcquisition = true } = {}) {
    const params = {
      xact: xact,
      partnerId: this.senderPartnerId || config.partner,
      hardAcquisition: hardAcquisition
    };

    const response = await api.send({
      endpoint: 'createDrmSession',
      params: params,
      fatal: true,
      retry: 1
    });

    const session = response.resource.getProps();

    if (response.resource.hasAction('features')) {
      session.featuresResource = response.resource.getFirstAction('features');
    }

    if (!this._isValidXact()) {
      this._storeXactToken(session);
    }

    return session;
  }

  get xboAccountId() {
    return this._xboAccountId;
  }
}

export default new Session();
export { Session }; // For unit test
