import api from 'api';
import { propertySort, selectVchipRating } from 'lib/helpers';
import BaseModel from 'model/base';
import VodModel from 'model/vod';
import TveModel from 'model/tve';
import RecordingModel from 'model/recording';
import ListingModel from 'model/listing';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';

const purchasePrioritySort = propertySort(
  ['isHD', -1],
  ['transactionDetails.date', -1],
  ['contentProvider.name', 1]
);
const rentalPrioritySort = propertySort(
  ['isHD', -1],
  ['contentProvider.name', 1]
);
const vodPrioritySort = propertySort(
  ['isHD', -1],
  ['contentProvider.name', 1]
);
const tvePrioritySort = propertySort(
  ['isHD', -1]
);
const completeRecordingPrioritySort = propertySort(
  ['dateRecordedTimestamp', -1]
);
const incompleteRecordingPrioritySort = propertySort(
  ['dateRecordedTimestamp', -1]
);
const linearPrioritySort = propertySort(
  ['isSubjectToBlackout', 1],
  ['channel.isTveFlag', 1],
  ['isHD', -1],
  ['startTime', -1],
  ['channel.number', 1]
);

const programTypeLabels = {
  episode: 's:TVEpisode',
  movie: 's:Movie',
  series: 's: TvSeries',
  sports: 's:SportsEvent'
};

const getProgramType = (programType = '') => {
  if (programType === 'episode') {
    return programTypeLabels.episode;
  }
  if (programType === 'movie') {
    return programTypeLabels.episode;
  }
  if (programType.includes('sport')) {
    return programTypeLabels.sports;
  }
};

/**
 * Creative work object
 *
 * @hideconstructor
 */
class CreativeWorkModel extends BaseModel {
  /**
   * Load a creative work based on program ID
   *
   * @param {string} programId - program ID of creative work to load
   * @return {CreativeWorkModel}
   */
  static async load(programId) {
    const response = await api.send({
      endpoint: 'getProgramEntity',
      params: {
        programId
      }
    });
    return await CreativeWorkModel.fromResource(response.resource);
  }

  static async _propertiesFromResource(resource) {
    const props = resource && resource.getProps && resource.getProps() || resource._resource || resource;
    const entityId = !resource.getFirstAction ? (resource.entityId || resource.programId) :
      resource.getFirstAction('entityDetail').getActionUrl()
        .split('/').reduceRight((prev, current) => prev ? prev : current) || props.merlinId || props.entityId || props.programId;
    const programType = !props._type && getProgramType(props.programType);
    senderDebugger.sendDebugMessage('[CREATIVE WORK][_propertiesFromResource] Entity ID attempt', {
      entityId: entityId,
      props: props
    });

    return {
      ...await super._propertiesFromResource(resource),
      _type: props._type || programType,
      title: props.name || props.title,
      duration: props.duration || props.runtime * 6e4,
      entityId: entityId || props.entityId,
      programId: props.programId,
      programType: props.programType,
      rating: props['contentRating/detailed'] || selectVchipRating(props.contentRatings || []),
      reviews: resource.hasEmbedded && resource.getEmbedded('programReviews') ?
        resource.getEmbedded('programReviews').reduce((reviews, reviewResource) => {
          const props = reviewResource.getProps();
          reviews[props.provider] = props.attributes;
          return reviews;
        }, {}) : props.reviews,
      isAdult: props.isAdult,
      image: resource.getFirstAction ? resource.getFirstAction('image') : props.image,
      season: props.partOfSeason && props.partOfSeason.seasonNumber || props.tvSeason && props.tvSeason.tvSeasonNumber,
      episode: props.episodeNumber || props.tvSeasonEpisodeNumber || props.tvSeriesEpisodeNumber,
      episodeTitle: props.episodeTitle,
      datePublished: props.datePublished || props.originalAirDate,
      datePublishedMs: Number(props.datePublishedMs) || props.originalAirDate && new Date(props.originalAirDate).getTime()
    };
  }

  /**
   * Build creative work from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {CreativeWorkModel}
   */
  static async fromResource(resource) {
    return new CreativeWorkModel(await CreativeWorkModel._propertiesFromResource(resource));
  }

  /**
   * Load series details
   *
   * This will not do anything if series details are already loaded or creative
   * work is not a TV episode
   */
  async loadSeries() {
    if (!this.isTvEpisode() || this.series) {
      return;
    }

    const _resource = this._resource || {};

    if (_resource.hasEmbedded) {
      const seriesResponse = await _resource.fetchEmbedded('partOfTvSeries');
      this.series = await CreativeWorkModel.fromResource(seriesResponse.data);
    } else {
      this.series = {
        duration: this.duration,
        entityId: this.entityId,
        programId: this.programId,
        rating: this.rating,
        title: _resource.title || this.title,
        _type: programTypeLabels.series
      };
      this.title = _resource.episodeTitle || this.episodeTitle || _resource.title;
    }
  }

  /**
   * Load watch options
   *
   * This will not do anything if watch options are already loaded
   */
  async loadWatchOptions() {
    if (this.watchOptions) {
      return;
    }

    const response = await this._resource.fetchEmbedded('watchOptions');
    const resource = response.data;

    const typeResponses = await Promise.all([
      resource.fetchEmbedded('tveVideos'),
      resource.fetchEmbedded('vodItems'),
      resource.fetchEmbedded('recordings')
    ]);
    const models = await Promise.all([
      await Promise.all(typeResponses[0].data.map(TveModel.fromResource)),
      await Promise.all(typeResponses[1].data.map(VodModel.fromResource)),
      await Promise.all(typeResponses[2].data.map(RecordingModel.fromResource))
    ]);

    // Assign creativeWork for all watchables
    models.forEach((collection) => collection.forEach((watchable) => watchable.creativeWork = this));

    this.watchOptions = {
      tve: models[0],
      purchases: models[1].filter((watchable) => watchable.isPurchase()),
      rentals: models[1].filter((watchable) => watchable.isRental()),
      vod: models[1].filter((watchable) => !watchable.isTransactional()),
      completeRecordings: models[2].filter((watchable) => watchable.recordingStatus === 'COMPLETE'),
      incompleteRecordings: models[2].filter((watchable) => watchable.recordingStatus !== 'COMPLETE')
    };
  }

  /**
   * Load upcoming listings
   *
   * This will not do anything if upcoming listings are already loaded
   */
  async loadListings() {
    if (this.upcomingListings) {
      return;
    }

    const response = await api.send({
      endpoint: 'getProgramUpcomingListings',
      params: {
        programId: this.programId,
        freetome: true
      }
    });
    const listingResources = response.resource.getEmbedded('listings');
    const listings = await Promise.all(listingResources.map(ListingModel.fromResource));

    this.upcomingListings = listings.filter((listing) => listing.channel.entitled);
    this.currentlyAiringListings = listings.filter((listing) => (
      listing.startTime <= Date.now() &&
      listing.endTime >= Date.now()));
  }

  /**
   * Check if creative work is a movie
   *
   * @return {boolean}
   */
  isMovie() {
    return this._type === programTypeLabels.movie;
  }

  /**
   * Check if creative work is a sports event
   *
   * @return {boolean}
   */
  isSportsEvent() {
    return this._type === programTypeLabels.sports;
  }

  /**
   * Check if creative work is a TV episode
   *
   * @return {boolean}
   */
  isTvEpisode() {
    return this._type === programTypeLabels.episode;
  }

  /**
   * Returns true if the creative work has any watch options
   *
   * This does not filter the options by cast-ability
   *
   * @return {boolean}
   */
  async hasWatchOptions() {
    await this.loadWatchOptions();

    const hasWatchOptions = Object.values(this.watchOptions).some((group) => group.length > 0);

    if (hasWatchOptions) {
      return true;
    }

    await this.loadListings();

    return this.currentlyAiringListings.length > 0;
  }

  /**
   * Return best option to watch for creative work
   *
   * @param {boolean} includeInHome - Include watch options that are restricted to in-home only
   * @return {WatchOptionModel}
   */
  async getBestOptionToWatch(includeInHome) {
    const optionPriority = [
      { field: 'purchases', sort: purchasePrioritySort },
      { field: 'completeRecordings', sort: completeRecordingPrioritySort },
      { field: 'rentals', sort: rentalPrioritySort },
      { field: 'incompleteRecordings', sort: incompleteRecordingPrioritySort },
      { field: 'vod', sort: vodPrioritySort },
      { field: 'tve', sort: tvePrioritySort }
    ];

    await this.loadWatchOptions();

    const castableOptions = Object.entries(this.watchOptions).reduce((result, [key, group]) => {
      result[key] = group.filter((watchable) => watchable.isCastable());
      return result;
    }, {});

    const nonLinearOptions = optionPriority.map(({ field, sort }) => {
      const options = castableOptions[field].filter((option) => {
        return includeInHome || !option.restrictStreaming.includes('out-of-home');
      });

      if (options.length > 0) {
        return options.sort(sort)[0];
      }
    }).filter(Boolean);

    if (nonLinearOptions.length > 0) {
      return nonLinearOptions[0];
    }

    await this.loadListings();

    const castableListings = this.currentlyAiringListings.filter((listing) => listing.isCastable());

    if (castableListings.length > 0) {
      return castableListings.sort(linearPrioritySort)[0];
    }

    return null;
  }

  /**
   * Return CreativeWorkModel for the next episode
   *
   * The returned model's watch options will be loaded and filtered to only
   * include watch options for the same company as the provided watchable.
   *
   * Will return null if there is no valid next episode.
   *
   * @param {WatchableModel} watchable - This must be non-linear, will be used to filter watch options
   * @return {CreativeWorkModel|null}
   */
  async getNextEpisode(watchable) {
    if (watchable.isLinear() ||
      !this.isTvEpisode() ||
      this.season === undefined) {
      return null;
    }

    const response = await api.send({
      endpoint: 'getWatchOptionsForSeason',
      params: {
        embedWatchNow: false,
        programsPerPage: 50,
        freetome: true,
        seriesId: this.entityId,
        seasonNumber: this.season
      }
    });

    const resource = response.resource;

    if (!resource.hasEmbedded('programs')) {
      return null;
    }

    const nextEpisodeNumber = this.episode + 1;
    const nextProgramResource = resource.getEmbedded('programs').find((program) =>
      program.getProp('episodeNumber') === nextEpisodeNumber);

    if (!nextProgramResource) {
      return null;
    }

    const nextProgram = await CreativeWorkModel.fromResource(nextProgramResource);

    await nextProgram.loadWatchOptions();

    const companyId = (watchable.contentProvider || watchable.channel || {}).companyId;
    nextProgram.watchOptions = Object.entries(nextProgram.watchOptions).reduce((result, [name, options]) => {
      result[name] = options.filter(() => (watchable.contentProvider || watchable.channel ||{}).companyId === companyId);
      return result;
    }, {});

    return nextProgram;
  }
}

export default CreativeWorkModel;
