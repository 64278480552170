import config from 'config';
import i18n from 'i18n';

/**
 * API middleware for Stream receiver-specific headers
 *
 * Adds `Accept`, `Client-Platform`, `X-Finity-Client-Info`, and
 * `X-Finity-Accept-Language` headers.
 *
 * @memberof module:API
 */
class ApplicationHeaderMiddleware {
  // No jsdoc
  handler = async (url, { headers, ...options }, next) => {
    await config.load();

    const clientPlatform = [
      'chromecast',
      'linear-tve',
      config.ownedAndOperated ? 'local-tve' : null,
      config.espnOTT ? 'espnott' : null,
      'est',
      'i18n-ratings',
      'beta-channels',
      'standard-sports-images',
      'session',
      config.nbcOTT ? 'livod' : null,
      config.telemundoEnabled ? 'local-tve-nbc-v3' : 'features',
      config.supportNEPL ? 'nonenglish' : null
    ].filter(Boolean).join(';');
    const clientInfo = {
      app_name: config.appName,
      app_version: config.version,
      os_name: 'Chromecast',
      web_user_agent: encodeURIComponent(navigator.userAgent)
    };

    return next(url, {
      headers: {
        'Client-Platform': clientPlatform,
        'X-Finity-Client-Info': Object.keys(clientInfo).map(
          (name) => `${ name }="${ clientInfo[name] }"`).join('; '),
        'X-Finity-Accept-Language': i18n.language,
        ...headers
      },
      ...options
    });
  }
}

export default ApplicationHeaderMiddleware;
