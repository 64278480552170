import { dispatches } from 'lib/event-dispatcher';
import controllerEvents from 'constants/controller-events';
import playerEvents from 'constants/player-events';

import Logger from 'lib/logger';
import { isAdSeekable, generateMyriadUrlForPoster } from 'lib/helpers';

const logger = new Logger('PLAY-CON', { background: 'green', color: 'white' });
const playerStateMapping = {
  initializing: 'IDLE',
  initialized: 'IDLE',
  preparing: 'IDLE',
  prepared: 'IDLE',
  ready: 'IDLE',
  playing: 'PLAYING',
  paused: 'PAUSED',
  complete: 'IDLE',
  released: 'IDLE',
  buffering: 'BUFFERING',
  error: 'IDLE'
};

/**
 * Interface to Google's CAF player messages
 *
 * A number of methods in this class **must** conform to Google's specs for
 * custom player integrations. This will be noted in the docs for those methods.
 */
@dispatches('controller')
class PlayerController {
  playerListeners = {
    [playerEvents.mediaOpened]: ({ detail }) => {
      logger.log('player.mediaOpened', detail);
      this.audioTracks = this.player.getAudioTracks() || [];
      this.captionTracks = this.player.getCaptionTracks() || [];
      this.maxSeekablePosition = 0;
    },
    [playerEvents.audioStreamsChanged]: () => {
      logger.log('player.audioStreamsChanged');
      this.audioTracks = this.player.getAudioTracks() || [];
    },
    [playerEvents.captionsStreamsChanged]: () => {
      logger.log('player.captionsStreamChanged');
      this.captionTracks = this.player.getCaptionTracks() || [];
    },
    [playerEvents.mediaFailed]: ({ detail }) => {
      logger.log('player.mediaFailed', detail);
      if ((detail || {}).offlineDetected) {
        return;
      }

      const event = new this.cast.framework.PlayerInterface.Event(
        this.cast.framework.PlayerInterface.EventType.ERROR);
      this.eventsListener(event);
    },
    [playerEvents.playStateChanged]: ({ detail }) => {
      logger.log('player.playStateChanged', detail);

      if (detail.type === 'PlaybackStarted') {
        this.playerState = this.cast.framework.messages.PlayerState.PLAYING;
        // No playState, so no need to update customData
      } else {
        if (detail.playState !== 'seeking') {
          // Ignoring seeking states since they don't map to CAF states
          const stateName = playerStateMapping[detail.playState] || 'IDLE';
          this.playerState = this.cast.framework.messages.PlayerState[stateName];
        }
        if (detail.playState === 'playing' && this.error) {
          this.error = null;
        }

        this._updateStatusCustomData({ playState: detail.playState });
      }
    },
    [playerEvents.bitrateChange]: ({ detail }) => {
      logger.log('player.bitrateChange', detail);
      this.bitrate = detail.bitRate;
    },
    [playerEvents.mediaProgress]: ({ detail }) => {
      let deltaT = 0;
      const position = (detail.relativePosition === undefined ? detail.position : detail.relativePosition) / 1000;
      const videoObjectPosition = detail.currentVideoTime || 0; // This is only set for cDVR now.
      if (videoObjectPosition) {
        // For cDVR the seek request is processed slower. So the progress update is resetting the scrubber.
        // Hence keeping a check here to see if it's actually updated.
        if (this.delayProgressOnSeek && Math.abs(position - this.currentTimeSec) > 30) {
          return;
        }
        // difference between the player's last position update
        const deltaT0 = position - this.currentTimeSec;
        // difference between the video object's last time update
        const deltaT1 = this.previousTime ? videoObjectPosition - this.previousTime : 0;

        deltaT = deltaT1 ? deltaT1 - deltaT0 : 0;
        // Usually deltaT won't be larger than .25s, except while seeking, where it can be a huge value.
        // so making it 0.25 if the value is more than 0.5s(less strict).
        deltaT = Math.abs(deltaT) < 0.5 ? deltaT : 0.25;
        this.previousTime = videoObjectPosition;
      }

      this.delayProgressOnSeek = false;
      this.currentTimeSec = position + deltaT;
      this.durationSec = (detail.endposition - detail.startposition) / 1000;
    },
    [playerEvents.adBreakStart]: ({ detail }) => {
      logger.log('adBreakStart');
      this.inAdBreak = true;
      this.seekableAd = false;
    },
    [playerEvents.adBreakComplete]: ({ detail }) => {
      logger.log('adBreakComplete');
      this.inAdBreak = false;
      this.seekableAd = false;
    },
    [playerEvents.adStart]: ({ detail }) => {
      this.seekableAd = !this.isLiveStream() && isAdSeekable(detail.videoAd, this.player.adData.adType);
    },
    [playerEvents.adProgress]: ({ detail }) => {
      this.currentTimeSec = detail.currentAdBreak.currentBreakPosition / 1000;
      this.durationSec = detail.currentAdBreak.duration / 1000;
    }
  };

  managerListeners = {
    REQUEST_EDIT_TRACKS_INFO: ({ requestData }) => {
      logger.log('EDIT_TRACKS_INFO', requestData);

      const { textTrackStyle } = requestData;
      if (textTrackStyle) {
        this.player.api.setClosedCaptionsStyle(this._mapToPPStyle(textTrackStyle));
      }
    }
  };

  /**
   * @param {object} options
   * @param {object} options.cast - Google CAF API object
   * @param {object} options.playerManager - CAF PlayerManager instance
   * @param {object} options.contentConfig - Content configuration for this player instance
   */
  constructor({ cast, playerManager, contentConfig }) {
    logger.log('constructor for PLAYERCONTROLLER START');
    this.cast = cast;
    this.playerManager = playerManager;
    this.contentConfig = contentConfig;
    this.statusCustomData = {
      playState: 'idle',
      prompt: {
        active: false,
        promptType: ''
      },
      error: undefined,
      eas: false
    };

    Object.assign(this.contentConfig.media.customData, {
      currentlyAiringProgramId: undefined,
      watchableUrl: undefined
    });

    Object.entries(this.managerListeners).forEach(([event, handler]) =>
      this.playerManager.addEventListener(this.cast.framework.events.EventType[event], handler));

    this.inAdBreak = false;
    this.isFFRestricted = false;
    this.isRWRestricted = false;
    this.isPauseRestricted = false;
    this.seekableAd = false;
    this.eventsListener = () => {};
    this.playerState = cast.framework.messages.PlayerState.IDLE;
    this.bitrate = 0;
    this.currentTimeSec = 0;
    this.delayProgressOnSeek = false;
    this.durationSec = -1;
    this.maxSeekablePosition = 0;
    this.audioTracks = [];
    this.captionTracks = [];
    logger.log('constructor for PLAYERCONTROLLER END ');
  }

  set player(value) {
    if (this._player) {
      this._player.removeEventListenerCollection(this.playerListeners);
    }

    this._player = value;

    if (this._player) {
      this._player.addEventListenerCollection(this.playerListeners);
    }
  }

  /**
   * Player interface associated with this controller
   */
  get player() {
    return this._player;
  }

  set playerState(value) {
    /*
     * NOTES on valid state transitions. READ BEFORE CHANGING! KEEP UP TO DATE!
     *
     * 1) Once we transition out of IDLE, we cannot transition back to IDLE.
     *    That is why NONE of these list IDLE as a valid destination state
     * 2) The ONLY state we can transition to from PAUSED is PLAYING. Any other
     *    state puts the CAF framework into a broken state
     */
    const validStateTransitions = {
      [this.cast.framework.messages.PlayerState.IDLE]: [
        this.cast.framework.messages.PlayerState.PLAYING,
        this.cast.framework.messages.PlayerState.PAUSED,
        this.cast.framework.messages.PlayerState.BUFFERING
      ],
      [this.cast.framework.messages.PlayerState.PLAYING]: [
        this.cast.framework.messages.PlayerState.PAUSED,
        this.cast.framework.messages.PlayerState.BUFFERING
      ],
      [this.cast.framework.messages.PlayerState.PAUSED]: [
        this.cast.framework.messages.PlayerState.PLAYING
      ],
      [this.cast.framework.messages.PlayerState.BUFFERING]: [
        this.cast.framework.messages.PlayerState.PLAYING,
        this.cast.framework.messages.PlayerState.PAUSED
      ]
    };

    if (this._playerState === value) {
      return;
    }

    if (validStateTransitions[this._playerState] &&
      !validStateTransitions[this._playerState].includes(value)
    ) {
      logger.log(`Ignoring invalid state transition ${ this._playerState } -> ${ value }`);
      return;
    }

    logger.log('Setting CAF playerState to', value);
    this._playerState = value;

    // Triggering a cast.framework.PlayerInterface.EventType.STATE_CHANGED
    // event is required by CAF custom player integration
    const event = new this.cast.framework.PlayerInterface.Event(
      this.cast.framework.PlayerInterface.EventType.STATE_CHANGED);
    this.eventsListener(event);
  }

  /**
   * Player stat from PlayerPlatform
   */
  get playerState() {
    return this._playerState;
  }

  set bitrate(value) {
    if (this._bitrate === value) {
      return;
    }

    const event = new this.cast.framework.PlayerInterface.BitrateChangedEvent(value, this._bitrate);
    this._bitrate = value;

    // Triggering a cast.framework.PlayerInterface.BitrateChangedEvent is
    // required by CAF custom player integration
    this.eventsListener(event);
  }

  /**
   * Video bitrate from PlayerPlatform
   */
  get bitrate() {
    return this._bitrate;
  }

  set currentTimeSec(value) {
    if (this._currentTimeSec === value) {
      return;
    }

    this._currentTimeSec = value;

    // Triggering a cast.framework.PlayerInterface.EventType.TIME_UPDATE
    // event is required by CAF custom player integration
    const event = new this.cast.framework.PlayerInterface.Event(
      this.cast.framework.PlayerInterface.EventType.TIME_UPDATE);
    this.eventsListener(event);
    this._updateMaxSeekablePosition(value);
  }

  /**
   * Current playback position in seconds
   */
  get currentTimeSec() {
    return this._currentTimeSec;
  }

  set durationSec(value) {
    if (this._durationSec === value) {
      return;
    }

    this._durationSec = value;

    // Triggering a cast.framework.PlayerInterface.EventType.DURATION_CHANGED
    // event is required by CAF custom player integration
    const event = new this.cast.framework.PlayerInterface.Event(
      this.cast.framework.PlayerInterface.EventType.DURATION_CHANGED);
    this.eventsListener(event);

    this.contentConfig.media.duration = value;
    this.playerManager.broadcastStatus(true);
  }

  /**
   * Video duration in seconds
   */
  get durationSec() {
    return this._durationSec;
  }

  set audioTracks(tracks) {
    this._audioTracks = tracks;
    this._scheduleTrackUpdate();
  }

  /**
   * Audio track info from Player interface
   */
  get audioTracks() {
    return this._audioTracks;
  }

  set captionTracks(tracks) {
    this._captionTracks = tracks;
    this._scheduleTrackUpdate();
  }

  /**
   * Caption track info from Player interface
   */
  get captionTracks() {
    return this._captionTracks;
  }

  set channelId(value) {
    if (this.contentConfig.media.customData.channelId === value) {
      return;
    }
    this._updateMediaCustomData({ channelId: value });
  }

  get channelId() {
    return this.contentConfig.media.customData.channelId;
  }

  set currentlyAiringProgramId(value) {
    if (this.contentConfig.media.customData.currentlyAiringProgramId === value) {
      return;
    }

    this._updateMediaCustomData({ currentlyAiringProgramId: value });
  }

  /**
   * Currently airing program ID
   */
  get currentlyAiringProgramId() {
    return this.contentConfig.media.customData.currentlyAiringProgramId;
  }

  set watchableUrl(value) {
    if (this.contentConfig.media.customData.watchableUrl === value) {
      return;
    }

    this._updateMediaCustomData({ watchableUrl: value });
  }

  /**
   * XTV API URL for currently playing watchable
   */
  get watchableUrl() {
    return this.contentConfig.media.customData.watchableUrl;
  }

  set prompt(value) {
    if (this.statusCustomData.prompt === value) {
      return;
    }

    this._updateStatusCustomData({ prompt: value });
  }

  /**
   * Details of currently active prompt
   */
  get prompt() {
    return this.statusCustomData.prompt;
  }

  set error(value) {
    if (this.statusCustomData.error === value) {
      return;
    }

    if (this.loadReject && value) {
      this.loadReject(value);
    }

    this._updateStatusCustomData({ error: value });
  }

  /**
   * Details of currently displayed error
   */
  get error() {
    return this.statusCustomData.error;
  }

  set eas(value) {
    if (this.statusCustomData.eas === value) {
      return;
    }

    this._updateStatusCustomData({ eas: value });
    this._updateSupportedMediaCommands();
  }

  /**
   * EAS status
   */
  get eas() {
    return this.statusCustomData.eas;
  }

  set creativeWork(value) {
    if (this._creativeWork === value) {
      return;
    }

    this._creativeWork = value;

    const info = this.playerManager.getMediaInformation();

    if (!info) {
      return;
    }

    const imageSizes = [
      { rule: '16x9_KeyArt', width: 1280, height: 720 } // Used by Google Home device; must be first
    ];

    if (!value) {
      info.metadata = null;
    } else {
      const pubDate = value.datePublishedMs ? new Date(value.datePublishedMs).toISOString().split('T')[0] : undefined;
      if (value.isTvEpisode()) {
        info.metadata = new this.cast.framework.messages.TvShowMediaMetadata();
        info.metadata.season = value.season;
        info.metadata.seasonNumber = value.season;
        info.metadata.episode = value.episode;
        info.metadata.episodeNumber = value.episode;
        info.metadata.seriesTitle = value.series && value.series.title;
        info.metadata.title = value.title;
        info.metadata.contentRating = value.rating && (value.rating.name || value.rating.rating);
        info.metadata.originalAirdate = pubDate;
      } else if (value.isMovie()) {
        info.metadata = new this.cast.framework.messages.MovieMediaMetadata();
        info.metadata.contentRating = value.rating && (value.rating.name || value.rating.rating);
        info.metadata.title = value.title;
        info.metadata.releaseDate = pubDate;
      } else {
        info.metadata = new this.cast.framework.messages.GenericMediaMetadata();
        info.metadata.contentRating = value.rating && (value.rating.name || value.rating.rating);
        info.metadata.releaseDate = pubDate;
        info.metadata.title = value.title;
      }

      if (info.metadata) {
        const entityId = value.entityId || value.programId;
        if (entityId) {
          info.metadata.images = imageSizes.map((params) => {
            const { width, height } = params;
            const url = generateMyriadUrlForPoster(entityId, params);
            const castImage = new this.cast.framework.messages.Image(url);
            castImage.width = width;
            castImage.height = height;

            return castImage;
          });
        }
      }
    }

    this.playerManager.setMediaInformation(info, true);
  }

  /**
   * Currently playing CreativeWork. Used for setting MediaInformation
   */
  get creativeWork() {
    return this._creativeWork;
  }

  set inAdBreak(value) {
    this._inAdBreak = value;
    this._updateSupportedMediaCommands();
  }

  /**
   * Flag indicating the player is currently in an ad break
   */
  get inAdBreak() {
    return this._inAdBreak;
  }

  set seekableAd(value) {
    this._seekableAd = value;
    this._updateSupportedMediaCommands();
  }

  /**
   * Flag indicating the current ad is seekable
   */
  get seekableAd() {
    return this._seekableAd;
  }

  set isFFRestricted(value) {
    this._isFFRestrcicted = value;
    this._updateStatusCustomData({ isFFRestricted: value });
    this._updateSupportedMediaCommands();
  }

  /**
   * Flag indicating the current watchanble is FF-Restricted
   */
  get isFFRestricted() {
    return this._isFFRestrcicted;
  }

  set isRWRestricted(value) {
    this._isRWRestricted = value;
    this._updateStatusCustomData({ isRWRestricted: value });
    this._updateSupportedMediaCommands();
  }

  /**
   * Flag indicating the current watchanble is FF-Restricted
   */
  get isRWRestricted() {
    return this._isRWRestricted;
  }

  set isPauseRestricted(value) {
    this._isPauseRestricted = value;
    this._updateStatusCustomData({ isPauseRestricted: value });
    this._updateSupportedMediaCommands();
  }

  /**
   * Flag indicating the current watchanble is FF-Restricted
   */
  get isPauseRestricted() {
    return this._isPauseRestricted;
  }

  /**
   * Flag indicating pausing playback is disabled
   */
  get pauseDisabled() {
    return this.eas || this.isPauseRestricted;
  }

  /**
   * Flag indicating seeking playback is disabled
   */
  get seekDisabled() {
    return this.eas || (this.inAdBreak && !this.seekableAd)|| this.isLiveStream() || (this.isFFRestricted && this.isRWRestricted);
  }

  _updateMaxSeekablePosition(position) {
    if (position <= this.maxSeekablePosition) {
      return;
    }
    this.maxSeekablePosition = position;
  }

  _updateMediaCustomData(changes) {
    Object.assign(this.contentConfig.media.customData, changes);
    this.playerManager.broadcastStatus(true); // Notify senders of data change
  }

  _updateStatusCustomData(changes) {
    Object.assign(this.statusCustomData, changes);
    this.playerManager.broadcastStatus(false); // Notify senders of data change
  }

  /**
   * Add/remove support media commands based on player state
   */
  _updateSupportedMediaCommands() {
    if (this.pauseDisabled) {
      this.playerManager.removeSupportedMediaCommands(
        this.cast.framework.messages.Command.PAUSE, true);
    } else {
      this.playerManager.addSupportedMediaCommands(
        this.cast.framework.messages.Command.PAUSE, true);
    }

    if (this.seekDisabled) {
      this.playerManager.removeSupportedMediaCommands(
        this.cast.framework.messages.Command.SEEK, true);
    } else {
      this.playerManager.addSupportedMediaCommands(
        this.cast.framework.messages.Command.SEEK, true);
    }
  }

  /**
   * Map CAF caption styles to PlayerPlatform caption styles
   *
   * @return {object} - PlayerPlatform caption style settings
   */
  _mapToPPStyle({
    backgroundColor,
    foregroundColor,
    edgeColor,
    edgeType,
    fontGenericFamily,
    fontScale
  }) {
    const fontMap = {
      [this.cast.framework.messages.TextTrackFontGenericFamily.MONOSPACED_SERIF]: 'monospaced_serif',
      [this.cast.framework.messages.TextTrackFontGenericFamily.SERIF]: 'proportional_serif',
      [this.cast.framework.messages.TextTrackFontGenericFamily.MONOSPACED_SANS_SERIF]: 'monospaced_sanserif',
      [this.cast.framework.messages.TextTrackFontGenericFamily.SANS_SERIF]: 'proportional_sanserif',
      [this.cast.framework.messages.TextTrackFontGenericFamily.CASUAL]: 'casual',
      [this.cast.framework.messages.TextTrackFontGenericFamily.CURSIVE]: 'cursive'
    };
    const colorMap = {
      'FFFFFF': 'white',
      '000000': 'black',
      'FF0000': 'red',
      '00FF00': 'green',
      '0000FF': 'blue',
      'FFFF00': 'yellow',
      'FF00FF': 'magenta',
      '00FFFF': 'cyan'
    };
    const opacityMap = (value) => {
      if (!value || value === 'FF') {
        return 'solid';
      }

      if (value === '00') {
        return 'transparent';
      }

      return 'translucent';
    };
    const styleMap = {
      [this.cast.framework.messages.TextTrackEdgeType.NONE]: 'none',
      [this.cast.framework.messages.TextTrackEdgeType.RAISED]: 'raised',
      [this.cast.framework.messages.TextTrackEdgeType.DEPRESSED]: 'depressed',
      [this.cast.framework.messages.TextTrackEdgeType.OUTLINE]: 'uniform',
      [this.cast.framework.messages.TextTrackEdgeType.DROP_SHADOW]: 'drop_shadow_left'
    };
    const style = {};

    if (fontGenericFamily) {
      style.fontStyle = fontMap[fontGenericFamily] || 'default';
    }

    if (fontScale) {
      if (fontScale < 1) {
        style.penSize = 'small';
      } else if (fontScale > 1) {
        style.penSize = 'large';
      } else {
        style.penSize = 'standard';
      }
    }

    if (backgroundColor) {
      const match = backgroundColor.match(/^#(......)(..)/);
      style.textBackgroundColor = colorMap[match[1]] || 'black';
      style.textBackgroundOpacity = opacityMap(match[2]);
    }

    if (foregroundColor) {
      const match = foregroundColor.match(/^#(......)(..)/);
      style.textForegroundColor = colorMap[match[1]] || 'white';
      style.textForegroundOpacity = opacityMap(match[2]);
    }

    if (edgeColor) {
      const match = edgeColor.match(/^#(......)(..)/);
      style.textEdgeColor = colorMap[match[1]] || 'white';
    }

    if (edgeType) {
      style.textEdgeStyle = styleMap[edgeType];
    }

    logger.log('Mapped style', style);

    return style;
  }

  /**
   * Update track info in CAF
   *
   * CAF player integration merged CC and audio track details into a single
   * value. This function merges current PlayerPlatform CC and audio track data
   */
  _updateTrackInfo() {
    this._tracksInfo = new this.cast.framework.messages.TracksInfo();

    const tracks = [
      ...this.audioTracks.map(({ locale, isActive, isDefault }) => {
        const track = new this.cast.framework.messages.Track();
        track.type = this.cast.framework.messages.TrackType.AUDIO;
        track.language = locale;
        return track;
      }),
      ...this.captionTracks.map(({ locale, isActive }) => {
        const track = new this.cast.framework.messages.Track();
        track.type = this.cast.framework.messages.TrackType.TEXT;
        track.language = locale;
        track.isInband = true;
        return track;
      })
    ];
    const activeTrackIds = [
      ...this.audioTracks.map(({ isActive }) => isActive),
      ...this.captionTracks.map(({ isActive }) => isActive)
    ]
      .map((isActive, i) => isActive ? i : null)
      .filter((i) => i !== null);

    tracks.forEach((track, i) => track.trackId = i);

    this._tracksInfo.tracks = tracks;
    this._tracksInfo.activeTrackIds = activeTrackIds;

    logger.log('Setting track info:', this._tracksInfo);
  }

  /**
   * Update track info in CAF after brief delay
   *
   * This calls _updateTrackInfo after a short delay so that multiple updates
   * can be batched together
   */
  _scheduleTrackUpdate() {
    clearTimeout(this._trackUpdateTimer);
    // Defering update to avoid sending multiple updates when we change CC and
    // audio track data at once
    // there is also a bunch of mediaOpened events coming from tveVod lane.
    this._trackUpdateTimer = setTimeout(() => this._updateTrackInfo(), 100);
  }

  /**
   * Returns bufferend media ranges
   *
   * This is required by the CAF custom player integration
   *
   * @return {cast.framework.messages.Range[]}
   */
  getBufferedRanges() {
    logger.log('getBufferedRanges');
    return [];
  }

  /**
   * Returns current player bitrate
   *
   * This is required by the CAF custom player integration
   *
   * @return {Number}
   */
  getCurrentBitRate() {
    logger.log('getCurrentBitRate:', this.bitrate);
    return this.bitrate;
  }

  /**
   * Returns current playback position
   *
   * This is required by the CAF custom player integration
   *
   * @return {Number}
   */
  getCurrentTimeSec() {
    return this.currentTimeSec;
  }

  /**
   * Returns video duration
   *
   * This is required by the CAF custom player integration
   *
   * @return {Number}
   */
  getDurationSec() {
    logger.log('getDurationSec:', this.durationSec);
    return this.durationSec;
  }

  /**
   * Returns seeksable range for live playback or null
   *
   * This is required by the CAF custom player integration
   *
   * @return {cast.framework.messages.LiveSeekableRange|null}
   */
  getLiveSeekableRange() {
    // logger.log('getLiveSeekableRange');
    if (this.isLiveStream()) {
      return new this.cast.framework.messages.LiveSeekableRange(0, 0, true, false);
    }

    return null;
  }

  /**
   * Returns current playback rate
   *
   * This is required by the CAF custom player integration
   *
   * @return {Number}
   */
  getPlaybackRate() {
    logger.log('getPlaybackRate');
    return 1;
  }

  /**
   * Returns current CAF player state
   *
   * This is required by the CAF custom player integration
   *
   * Return value must be from the cast.framework.messages.PlayerState enumeration
   *
   * @return {string}
   */
  getPlayerState() {
    logger.log('getPlayerState');
    return this.playerState;
  }

  /**
   * Returns player version
   *
   * This is required by the CAF custom player integration
   *
   * @return {Number}
   */
  getPlayerVersion() {
    logger.log('getPlayerVersion');
    // return a non-zero number to prevent CAF from throwing an error
    return 1;
  }

  /**
   * Returns audio/CC track info
   *
   * This is required by the CAF custom player integration
   *
   * @return {cast.framework.messages.TracksInfo|null}
   */
  getTracksInfo() {
    logger.log('getTracksInfo');
    return this._tracksInfo;
  }

  /**
   * Returns if current stream is live
   *
   * This is required by the CAF custom player integration
   *
   * @return {Boolean}
   */
  isLiveStream() {
    logger.log('isLiveStream:', !!this.contentConfig.media.customData.channelId);
    return !!this.contentConfig.media.customData.channelId;
  }

  /**
   * Returns if current seek request is valid
   *
   * @param {Number} seekToTimeSec - Time to seek to in seconds
   * @return {Boolean}
   */
  canSeekToTime(seekToTimeSec) {
    return !this.isFFRestricted || seekToTimeSec < this.maxSeekablePosition;
  }

  /**
   * Start playing selected video
   *
   * This is required by the CAF custom player integration
   *
   * NOTE: This function must resolve **after** the player has started playing!
   * This is due to an issue right now with notifying senders of changes to the
   * duration.
   *
   * @param {HTMLMediaElement} mediaElement
   * @param {Boolean} autoPlay
   */
  async load(mediaElement, autoPlay) {
    logger.log('load called ', mediaElement, autoPlay);

    const player = this.player;
    const handlers = {};

    // This must resolve *after* media is playing. Specifically, we need a
    // valid duration before it resolves.
    const result = new Promise((resolve, reject) => {
      this.loadReject = reject;
      handlers[playerEvents.playStateChanged] = ({ detail }) => {
        if (detail.type === 'PlaybackStarted') {
          logger.log('Resolving load');
          resolve();
        }
      };

      player.addEventListenerCollection(handlers);
      this.dispatchEvent(controllerEvents.load, this.contentConfig);
    });

    result
      .catch(() => {
        logger.log('Load failed');
      })
      .then(() => {
        player.removeEventListenerCollection(handlers);
      });

    return result;
  }

  /**
   * Pause playback
   *
   * This is required by the CAF custom player integration
   */
  async pause() {
    logger.log('pause');

    // Force transition to PAUSED state. This will protect from seeing IDLE
    // state when "pausing" linear
    this.playerState = this.cast.framework.messages.PlayerState.PAUSED;

    this.dispatchEvent(controllerEvents.pause);
  }

  /**
   * Resume playback
   *
   * This is required by the CAF custom player integration
   */
  async play() {
    logger.log('play');
    this.dispatchEvent(controllerEvents.play);
  }

  /**
   * Start preloading media
   *
   * This is required by the CAF custom player integration
   */
  preload() {
    // Preloading isn't supported
  }

  /**
   * Seek playback
   *
   * This is required by the CAF custom player integration
   *
   * @param {Number} seekToTimeSec - Time to seek to in seconds
   */
  async seek(seekToTimeSec) {
    logger.log('seek:', seekToTimeSec);
    this.dispatchEvent(controllerEvents.seek, {
      msec: seekToTimeSec * 1000
    });
    this.delayProgressOnSeek = true;
    this.currentTimeSec = seekToTimeSec;
  }

  /**
   * Select an audio track
   *
   * This is required by the CAF custom player integration
   *
   * @param {Number} trackId - ID from getTracksInfo
   */
  async setActiveAudioTrack(trackId) {
    logger.log('setActiveAudioTrack:', trackId);
    this.dispatchEvent(controllerEvents.setAudioTrack, {
      track: this._tracksInfo.tracks[trackId]
    });
  }

  /**
   * Select text track(s)
   *
   * This is required by the CAF custom player integration
   *
   * @param {Number[]} trackIds - IDs from getTracksInfo
   */
  async setActiveTextTracks(trackIds) {
    logger.log('setActiveTextTracks:', trackIds);
    this.dispatchEvent(controllerEvents.setTextTracks, {
      tracks: trackIds.map((id) => this._tracksInfo.tracks[id])
    });
  }

  /**
   * Sets listener for CAF events
   *
   * This is required by the CAF custom player integration
   *
   * @param {Function} listener
   */
  setEventsListener(listener) {
    logger.log('setEventsListener:', listener);
    this.eventsListener = listener;
  }

  /**
   * Sets playback rate
   *
   * This is required by the CAF custom player integration
   *
   * @param {Number} playbackRate
   */
  async setPlaybackRate(playbackRate) {
    logger.log('setPlaybackRate:', playbackRate);
    // Setting playback rate isn't supported
  }

  /**
   * Cleans up PlayerController after stopping playback
   *
   * This is required by the CAF custom player integration
   *
   * @param {cast.framework.events.EndedReason} reason - Reason playback was stopped
   */
  async unload(reason) {
    logger.log('unload:', reason);

    Object.entries(this.managerListeners).forEach(([event, handler]) =>
      this.playerManager.removeEventListener(this.cast.framework.events.EventType[event], handler));
    this.dispatchEvent(controllerEvents.unload, { reason, controller: this });
  }
}

export default PlayerController;
