/**
 * @module Timer
 * @desc Count down timer, with pause, pause with return interval value and resume
 * @example
 * // returns time difference
 * timer = new Timer();
 * timer.start() - to start the timer
 * timer.end() - to end timer and return the total time
 * timer.pause() , timer.pauseWithInterval() - to step the timer with or without return values
 * timer.resume() - resume/start after the break
 * timer.totalDuration - return the total breaks time value
 * timer.difference - different from start time with current time
 */
class Timer {
  breaks =[];
  start() {
    this.clearTimer();
    this.startTime = Date.now();
  }

  resume() {
    this.startTime = Date.now();
  }

  pause() {
    this.breaks && this.breaks.push(this.difference);
  }

  pauseWithInterval() {
    this.pause();
    return this.difference;
  }

  end(ms = false) {
    this.breaks.push(this.difference);
    return ms ? this.totalDuration : Math.max(this.totalDuration / 1e3, 0);
  }

  clearTimer() {
    this.breaks = [];
    this.startTime = 0;
  }

  get difference() {
    return this.startTime ? Date.now() - this.startTime : 0;
  }

  get totalDuration() {
    this.startTime = 0;
    return this.breaks.reduce((acc, val) => {
      return acc + val;
    }, 0);
  }
}

export default Timer;
