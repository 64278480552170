import SplunkLogger from '../lib/telemetry/splunk-logger';
import { toNest } from '../lib/helpers';

import splunkTypes from '../constants/splunk-types';

class PlayerAdLogging {
  constructor() {
    this.adBreaks = [];
  }

  getTimeLineAdPercent = (adsDuration, assetDuration, adManager='') => {
    const duration = /freewheel/ig.test(adManager) ? assetDuration : (assetDuration - adsDuration);
    return Math.round((adsDuration/duration)*100);
  };

  adTimelineInfo = (adConfig, timelineData, duration) => {
    const adsDuration = timelineData.map((adBreak) => adBreak.duration).reduce((dur, acc) => dur + acc, 0) / 1e3;
    const adTimelineData = {
      adManager: adConfig.type,
      totalAdBreaks: timelineData.length,
      totalAdsCount: timelineData.map((adBreak) => adBreak.adCount).reduce((dur, acc) => dur + acc, 0),
      totalAdsDuration: adsDuration,
      totalAdsPercentage: this.getTimeLineAdPercent(adsDuration, duration/1e3, adConfig.type)
    };
    SplunkLogger.logAdEvent(splunkTypes.AdTimelineInfo, adTimelineData);
    this.adBreaks = timelineData.map((adBreak) => adBreak.startTime).sort();
  };

  adBreakEvent = (adBreakData) => {
    const {
      startTime=0, endTime=0, duration=0, adCount=0, ads=[]
    } = adBreakData.videoAdBreak;
    const adBreakLoggingInfo = {
      startTime, endTime, duration, count: adCount,
      index: this.adBreaks.findIndex((adStartTime) => adStartTime === startTime),
      identifiers: ads.map((ad) => ad.adAssetInfo || { providerId: '' }).map((adInfo) => adInfo.providerId),
      types: ads.map((ad) => ad.vpaid ? 'vpaid' : 'NA'),
      ffRestricted: ads.map((ad) => !ad.seekable)
    };
    SplunkLogger.logAdEvent(splunkTypes[adBreakData.type], toNest('adBreak', adBreakLoggingInfo));
  };

  adError = (adData) => {
    const {
      major=0, minor=0, description=''
    } = adData.error;
    const {
      filteredRenditionUrl='', clickThrough='', vpaid=false, startTime=0
    } = adData.videoAd;
    const adIndex = this.adBreaks.findIndex((adStartTime) => adStartTime === startTime);
    const adErrorLoggingInfo = {
      major, minor, msg: description || adData.description, startTime
    };
    SplunkLogger.logAdEvent(splunkTypes[adData.type],
      { ...toNest('adError', adErrorLoggingInfo), adIndex, adFilter: filteredRenditionUrl, adUrl: clickThrough, isVpaid: vpaid });
  };
}

export default new PlayerAdLogging;
