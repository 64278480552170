/**
 * Constants for prompt types used in the SHOW_PROMPT message
 *
 * @namespace constants.promptTypes
 */
export default {
  parentalControls: {
    promptType: 'parental-controls',
    pausePlayback: true,
    header: 'Enter Parental Controls PIN',
    message: 'To continue, please enter PIN on Xfinity Stream.',
    idleTimeout: 5 * 60e3
  },
  stillWatching: {
    promptType: 'still-watching',
    pausePlayback: true,
    header: 'Are you still watching?',
    message: 'To continue, please confirm on Xfinity Stream.',
    idleTimeout: 5 * 60e3,
    siftTag: 'Are You Still Watching'
  },
  upNext: {
    promptType: 'up-next',
    pausePlayback: false,
    siftTag: 'Next Episode'
  }
};
