/**
 * Constants for productContext values from XVP
 *
 * @namespace constants.productContextTypes
 */

export default {
  tve: 'TVE',
  t6: 'TitleVI'
};
