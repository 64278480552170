import Logger from 'lib/logger';

const maximumAttempts = 5;
const attemptDelay = 1000;

const logger = new Logger('CONFIG', { background: 'crimson', color: 'white' });

/**
 * Application configuration
 *
 * Full configuration loads asynchronously from partner config files in
 * `src/config/<partnerName>`. This process is started during app startup.
 *
 * The `partner`, `env`, `version`, `environment`, and `loading` values are
 * available before loading is complete.
 *
 * @namespace config
 * @property {string} partner - Name of current partner
 * @property {string} env - Current config environment (`staging`, `prod`, `dev`)
 * @property {string} version - Application version number
 * @property {string} buildNumber - Jenkins build number (will be "buildNum" in local development)
 * @property {string} gitCommit - Git commit hash of the current build (will be "gitHash" in local development)
 * @property {string} deployment - Deployment descriptor made from version, build number, and git commit hash
 * @property {object} environment - Environment variables exported to app
 * @property {promise} loading - Resolved with full config is loaded
 */
class Configuration {
  constructor(environment) {
    const { PARTNER, ENV, version } = environment;
    this.gitCommit = process.env.GIT_COMMIT;
    this.buildNumber = process.env.BUILD_NUMBER;
    this.partner = PARTNER;
    this.env = ENV;
    this.version = version;
    this.deployment = `${ this.version }-${ this.buildNumber }#${ this.gitCommit.slice(0, 7) }`;
    this.environment = { ...environment };
    this.loading = null;
  }

  async load() {
    if (!this.loading) {
      const { partner, env } = this;
      this.loading = (async () => {
        let attempts = 0;

        do {
          attempts++;

          try {
            const module = await import(
              /* webpackChunkName: "config-[request]" */
              'config/' + partner + '/' + env + '-env');

            if (module.default === undefined) {
              // TODO: This should probably be logged. It shouldn't happen
              logger.error('Empty config loaded');
            }

            Object.assign(this, module.default);

            return;
          } catch (err) {
            if (err.code === 'MODULE_NOT_FOUND') {
              // TODO: This should definitely be logged
              logger.error(`Attempted to load missing config for ${ partner } / ${ env }`);
              throw err;
            }
          }

          await new Promise((resolve) => setTimeout(resolve, attemptDelay));
        } while (attempts < maximumAttempts);

        // TODO: This should definitely be logged
        logger.error('Failed to load configuration data');
        throw new Error('Failed to load configuration data');
      })();
    }

    return this.loading;
  }
}

export default new Configuration(window.environment || {});
