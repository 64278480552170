
import castMessenger from 'cast-messenger';
import config from 'config';
import Logger from 'lib/logger';
import messageTypes from 'constants/message-types';
import logLevels from 'constants/log-levels';

const logger = new Logger('APP', { background: 'deepskyblue' });
const loggingTypes = {
  debug: '[DEBUG]',
  error: '[ERROR]',
  heartbeat: '[HEARTBEAT]',
  resumePoints: '[RESUME-POINT]',
  network: '[NETWORK]',
  player: '[PLAYER]',
  xvp: '[XVP]',
  xtvapi: '[XTVAPI]',
  playerPlatform: '[PLAYER-PLATFORM]',
  prompt: '[PROMPT]',
  telemetry: '[TELEMETRY]'
};
const forceTargetLog = {
  debug: false,
  error: false,
  heartbeat: false,
  resumePoints: false,
  network: false,
  player: false,
  xvp: false,
  xtvapi: false,
  playerPlatform: false,
  prompt: false
};
/**
 * sendDebugger - manages sending debug messages to sender
 * manages google debuggin module enable/disable
 */
class SenderDebugger {
   currentlySending = false;
   currentLogLevel = logLevels.WARN;
   _sendLogsToSender = true;
   senderListeners = {
     [messageTypes.setDebug]: async ({ detail }) => {
       this.sendDebugMessage('SET DEBUG - SENDER DEBUGGER CALLED - DEBUG', detail);
       if (detail.googleDebugToggle !== undefined) {
         const castDebugLogger = window.cast.debug.CastDebugLogger.getInstance();
         const googleDebugEnabled = detail.googleDebugToggle;
         castDebugLogger.setEnabled(googleDebugEnabled);
         return;
       }
     }
   };
   addDebugCastListeners(castMessengerTarget) {
     castMessengerTarget.addEventListenerCollection(this.senderListeners);
   }
   setForcedLoggers( loggers ) {
     Object.keys(loggers).forEach((key) => {
       if (forceTargetLog[key] !== undefined) {
         forceTargetLog[key] = loggers[key];
       }
     }
     );
   }
   getForcedLoggers() {
     return Object.assign({}, forceTargetLog);
   }

   setLogLevel(level) {
     this.currentLogLevel = level;
     castMessenger.broadcastMessage(messageTypes.debugSender, {
       debugData:
    {
      message: '[streamAppLogLevel] SET LOG LEVEL TO SENDER',
      detail: {
        streamAppLogLevel: this.currentLogLevel,
        levelSetFromSender: level
      }
    }
     });
   }

   getLogLevel() {
     castMessenger.broadcastMessage(messageTypes.debugSender, {
       debugData:
     {
       message: '[streamAppLogLevel]GET LOG LEVEL TO SENDER',
       detail: {
         streamAppLogLevel: this.currentLogLevel
       }
     }
     });
     return this.currentLogLevel;
   }
   getSendLogsToSender() {
     return this._sendLogsToSender;
   }

   setSendLogsToSender(value) {
     this._sendLogsToSender = value;
   }
   constructor() {
     const cast = window.cast;
     if (!cast) {
       // for unit tests
       return;
     }
     // Google Debug Logger
     const castDebugLogger = cast.debug.CastDebugLogger.getInstance();

     const context = cast.framework && window.cast.framework.CastReceiverContext.getInstance();

     logger.log('CONSTRUCTOR SENDEr Rec ENABLED: ' + castDebugLogger);

     context.addEventListener(cast.framework.system.EventType.READY, () => {
       if (castDebugLogger.debugOverlayElement_ !== undefined) {
         // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
         this.sendDebugMessage('Cast Context Ready - GOOGLE DEBUG', {
           detailData: `castDebugLogger state: ${castDebugLogger.debugOverlayElement_}`,
           cfEnabledFlag: `${config.environment.ENABLE_CAC_DEBUG}`
         });
         // TODO - fix sender UI button
         if (config.environment.ENABLE_CAC_DEBUG === 'true') {
           castDebugLogger.setEnabled(true);
         }
       }
     });
     if (castDebugLogger) {
       // TODO Add UI controls to add/remove these log levels
       castDebugLogger.loggerLevelByEvents = {
         'cast.framework.events.category.CORE': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.DEBUG': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.FINE': window.cast.framework.LoggerLevel.DEBUG,
         'cast.framework.events.category.REQUEST': window.cast.framework.LoggerLevel.DEBUG
       };
     }
   }
   /**
     * Takes a debug message object and prepends logging type of network and forwards
     * @param {string} message Message
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugResumePointMessage( message, detail ) {
     if (forceTargetLog.resumePoints || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend(loggingTypes.resumePoints + message, detail);
     }
   }
   /**
     * Takes a debug message object and prepends logging type of heartbeat and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugHeartbeatMessage( message, detail ) {
     if (forceTargetLog.heartbeat || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend(loggingTypes.heartbeat + message, detail);
     }
   }
   /**
     * Takes a debug message object and prepends logging type of network and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugNetworkMessage( message, detail ) {
     if (forceTargetLog.network || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.network + message, detail);
     }
   }

   /**
     * Takes a debug message object and prepends logging type of error and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugErrorMessage( message, detail ) {
     if (forceTargetLog.error || this.currentLogLevel <= logLevels.ERROR) {
       this._castMessageSend( loggingTypes.error + message, detail);
     }
   }
   /**
     * Takes a debug message object and prepends logging type of heartbeat and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugPlayerMessage( message, detail ) {
     if (forceTargetLog.player || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.player + message, detail);
     }
   }
   /**
     * Takes a debug message object related to prompt related messages and prepends logging type of prompt and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugPromptMessage( message, detail ) {
     if (forceTargetLog.prompt || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.prompt + message, detail);
     }
   }
   /**
     * Takes a debug message object and prepends logging type of xvp and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugXVPMessage( message, detail ) {
     if (forceTargetLog.xvp || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.xvp + message, detail);
     }
   }
   /**
     * Takes a debug message object and prepends logging type of heartbeat and forwards
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugXTVAPIMessage( message, detail ) {
     if (forceTargetLog.xtvapi || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.xtvapi + message, detail);
     }
   }
   /**
     * Forwards player platform console logs to sender as a debug message object
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugPlayerPlatformLog( message, detail ) {
     if (forceTargetLog.playerPlatform || this.currentLogLevel <= logLevels.INFO) {
       this._castMessageSend( loggingTypes.playerPlatform + message, detail);
     }
   }
   /**
     * Forwards telemetry - "splunk" / Elk console logs to * sender with the telemetry data and message object
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   debugTelemetryMessage( message, detail ) {
     this._castMessageSend( loggingTypes.telemetry + message, detail);
   }
   /**
     * Takes a debug message object and dispatches to sender
     * @param {string} message Message title
     * @param {string} detail json object of data to be sent to sender
     * message: string
     */
   sendDebugMessage( message, detail ) {
     if (forceTargetLog.debug || this.currentLogLevel <= logLevels.TRACE) {
       this._castMessageSend( loggingTypes.debug + message, detail);
     }
   }
   sendMessage( message, detail ) {
     this._castMessageSend( loggingTypes.player + message, detail);
   }

   _castMessageSend( message, detail ) {
     if (!['local', 'ci'].includes(config.env) && !this._sendLogsToSender) {
       return;
     }

     try {
       castMessenger.broadcastMessage(messageTypes.debugSender, {
         debugData:
         {
           message: new Date().toJSON() +' : '+ message,
           detail: detail
         }
       });
       logger.log(message, detail);
     } catch (error) {
       castMessenger.broadcastMessage(messageTypes.debugSender, { debugData:
            {
              message: message,
              detail: {
                text: 'invalid detail data found - attempt to strip and stringify',
                error: error,
                detailBasics: Object.keys(detail)
              }
            } });
     }
   }
}

export const senderDebugger = new SenderDebugger();
export const sendDebugMessage = senderDebugger.sendDebugMessage.bind(this);
