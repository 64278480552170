import BaseModel from 'model/base';

/**
 * Content provider object
 *
 * @hideconstructor
 */
class ContentProviderModel extends BaseModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();

    return {
      ...await super._propertiesFromResource(resource),
      logo: resource.getFirstAction('logo'),
      ...props
    };
  }

  /**
   * Build content provider from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {ContentProviderModel}
   */
  static async fromResource(resource) {
    return new ContentProviderModel(await ContentProviderModel._propertiesFromResource(resource));
  }
}

export default ContentProviderModel;
