/**
 * API middleware for logging result of complete request (including retries)
 *
 * This should be before [RetryMiddleware]{@link module:API.RetryMiddleware} so
 * that the result of all retried requests are logged collectively.
 *
 * This adds support for a `suppressLogging` option on the request to disable
 * logging.
 *
 * @memberof module:API
 */
class ActionLoggingMiddleware {
  // TODO: Log this stuff is a *useful* way, write tests when you do
  constructor({ logger }) {
    this.logger = logger;
  }

  // No jsdoc
  handler = async (url, options, next) => {
    if (options.suppressLogging) {
      return next(url, options);
    }

    const startTime = Date.now();

    try {
      const result = await next(url, options);

      this.logger.logBlock('Action successful', (logger) => {
        logger.log('url:', url);
        logger.log('runTime:', Date.now() - startTime);
      });

      return result;
    } catch (err) {
      this.logger.logBlock('Action failed', (logger) => {
        logger.log('url:', url);
        logger.log('runTime:', Date.now() - startTime);
        logger.log('fatal:', err.xtv.fatal);
      });

      throw err;
    }
  }
}

export default ActionLoggingMiddleware;
