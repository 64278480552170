import { dispatches } from 'lib/event-dispatcher';
import playerEvents from '../../constants/player-events';
import { getVideoAdBreakObject } from 'lib/helpers';
import Logger from 'lib/logger';


const logger = new Logger('ADDATA', { background: 'bright_white' });
/**
 * Model for ad related data
 */
/* eslint-enable valid-jsdoc */
@dispatches('adEvents')
class AdData {
  adConfig = {};
  adBreakStart = {
    currentAdBreak: {},
    type: playerEvents.adBreakStart,
    videoAdBreak: {}
  };
  _adStart = {
    adType: '',
    index: 0,
    position: 0,
    progress: 0,
    rate: 0,
    type: playerEvents.adStart,
    videoAd: {}
  };
  adMonitoringInterval = null;
  adMonitoringTimeInterval = 500;
  adsPlaying = false;
  adType = '';
  adError = {};
  currentAdBreak = {
    duration: 0,
    currentAdText: '',
    position: 0,
    videoAdBreak: {}
  };
  duration = 0;
  linearAdIndex = 0;
  mediaOpenedFiredDuringAdBreak = false;
  mediaProgressAdCount = 0;
  mediaProgressAdMax = 5;
  timelineAdBreaks = [];
  type = '';
  _videoAdBreak = {};

  set adProgress({ adProgress }) {
    this.adsPlaying = true;
    this.type = playerEvents.adProgress;
    Object.assign(this.currentAdBreak, {
      progress: adProgress.progress,
      currentBreakPosition: adProgress.position,
      rate: adProgress.rate,
      position: adProgress.position
    });
  }
  set adStart({ adStart, adType, adIndex }) {
    this.adsPlaying = true;
    this.currentAdBreak.currentAdCount = adIndex;
    Object.assign(this._adStart, adStart, {
      adIndex: adIndex,
      adType: adType,
      position: adStart.position,
      progress: adStart.progress,
      rate: adStart.rate,
      type: playerEvents.adStart,
      videoAd: adStart.videoAd
    });
    this.type = playerEvents.adStart;

    this.currentAdBreak.progress = adStart.progress;
  }
  get adStart() {
    return this._adStart;
  }
  get currentAd() {
    return this._adStart;
  }

  /**
   * get currentAdIndex - returns the current index of what ad is playing within
   * an ad break;
   *
   * @return {number}  index of which ad is playing within an VideoAdBreak
   */
  get currentAdIndex() {
    if (this.adsPlaying && this.watchable.isLinear()) {
      console.log(`current ad index linear: ${this.watchable.isLinear()} adStart: ${JSON.stringify(this._adStart)}`);
      return this.linearAdIndex;
    }
    return this._adStart && this._adStart.adIndex;
  }
  set adComplete({ adComplete }) {
    this.adsPlaying = false;
    this.type = playerEvents.adComplete;
  }
  get videoAdBreak() {
    return getVideoAdBreakObject({ videoAdBreak: this._videoAdBreak });
  }
  set videoAdBreak({ videoAdBreak }) {
    this._videoAdBreak = videoAdBreak;
  }
  /**
   * Set when ad errors fire from PP. Since errors can now happen per ad,
   * until we figure out what to do with them, we'll keep them stored.
   * @param  {Object[]} adError adError event from Player Platform
   */
  onAdError({ adError }) {
    this.adErrors.push(adError);
  }
  getAdPositionInBreak() {
    // TODO helper to get the total position within a multi-ad break
    // use getAdBreakPosition from helper
  }

  /**
   * isAdBreakNearCurrentPosition - will determine if an ad break is within 3 seconds of current position
   *  @param  {Object} player - instance of player with PP api
   * @return {boolean} - current position is near an ad break
   */
  isAdBreakNearCurrentPosition(player) {
    const apiCurrentPosition = player.api.getCurrentPosition ? player.api.getCurrentPosition() : player.playerPosition;
    const closestVideoAdBreak = player.api.adManager && player.api.adManager.getClosestAdBreak(apiCurrentPosition) || {};
    const closeInFront = closestVideoAdBreak.startTime - apiCurrentPosition < 3000;
    const closeInBack = apiCurrentPosition - closestVideoAdBreak.endTime < 3000;
    const withinBreak = apiCurrentPosition > closestVideoAdBreak.startTime && apiCurrentPosition < closestVideoAdBreak.endTime;

    return closeInFront || closeInBack || withinBreak;
  }
  startAdMonitor(player) {
    if (!player.watchable.isTve() || player.watchable.isTve()) {
      return;
    }

    logger.logBlock('STARTING AD MONITOR', (logger) => {
      logger.log('START ', player.adData);
    });
    this.monitoringAds = {
      bufferFilledCounter: 0
    };

    this.adMonitoringInterval = setInterval(() => {
      const apiContentPosition = player.api.getCurrentPosition ? player.api.getCurrentPosition() / 1000 : player.playerPosition;
      let bufferEndTime = 0;
      let bufferStartTime = 0;
      let bufferAhead = 0;
      let bufferFilledLength = 0;
      const showDetail = true;
      try {
        bufferEndTime = player.api.videoElement.getElementsByTagName('video')[0].buffered.end(0) * 1000;
        bufferStartTime = player.api.videoElement.getElementsByTagName('video')[0].buffered.start(0) * 1000;
      } catch (e) {
        logger.warn('**** ad monitoring **** get buffer time ranges failed');
      }
      try {
        bufferAhead = bufferEndTime > apiContentPosition ? Math.floor(bufferEndTime - apiContentPosition) / 1000 : 0;
      } catch (e) {
        logger.warn('**** ad monitoring **** bufferAhead failed');
        bufferAhead = 0;
      }
      try {
        bufferFilledLength = player.api.getBufferFilledLength() / 1000;
      } catch (e) {
        logger.warn('**** ad monitoring **** getBufferFilledLength failed');
        bufferFilledLength = 0;
      }

      const playerPossibleStuck = (Math.floor(this.monitoringAds.bufferFilledLength) === Math.floor(bufferFilledLength) &&
      Math.floor(apiContentPosition) !== Math.floor(player.playerPosition) )||
      bufferAhead < 2;

      if (playerPossibleStuck) {
        this.monitoringAds.bufferFilledCounter++;
        logger.log('**** ad monitoring **** playerPossibleStuck for ' + this.monitoringAds.bufferFilledCounter + ' times');
      }
      if (showDetail) {
        logger.log('**** ad monitoring **** count filled: '+this.monitoringAds.bufferFilledCounter+': position:[ ' + player.playerPosition +
        ' ] apiContentPosition: [ ' + apiContentPosition +
        ' ] bufferEndTime: [ ' + bufferEndTime +
        ' ] bufferStartTime: [ ' + bufferStartTime +
        ' ] play state :[ ' + player.getPlayerState() +
        ' ] PP play state :[ ' + player.api.getPlayerStatus() +
        ' ] ads playing? :[ ' + this.adsPlaying +
        ' ] bufferAhead:['+ bufferAhead +
        ' ] bufferFilledLength:[' + bufferFilledLength +
        ' ] this.monitoringAds.bufferFilledLength:[' + this.monitoringAds.bufferFilledLength +
        ' ] playerPossibleStuck [ ' + playerPossibleStuck +
        ' ] type label:[' +player.watchable.getTypeLabel()+ '] ');
      }
      // tve vod check when out of ad mode, PP is stuck with a playable buffer
      if (playerPossibleStuck && this.monitoringAds.bufferFilledCounter > 5
        && player.api.getPlayerStatus() !== 'idle' && player.watchable.isTve()) {
        logger.error('**** ad monitoring **** buffer full ! Calling api.setPositionRelative(0) !');
        this.dispatchEvent(playerEvents.adHealing, player.api.getPlayerStatus());
      }
      if (this.adsPlaying && player.api.getPlayerStatus() === 'idle') {
        this.monitoringAds.bufferFilledCounter = 0;
      }
      this.monitoringAds.playerPosition = player.playerPosition;
      this.monitoringAds.bufferFilledLength = bufferFilledLength;
      this.monitoringAds.contentPostion = apiContentPosition;
    }, this.adMonitoringTimeInterval);
  }
  stopAdMonitor() {
    if (this.adMonitoringInterval) {
      clearInterval(this.adMonitoringInterval);
      this.adMonitoringInterval = null;
    }
  }
  findSkippedAdBreak({ videoAdBreak }) {
    const skippedAds = videoAdBreak.ads.filter((ad, index) => {
      return ad.skipped || ad.errorMajorCode !== undefined;
    });
    return (skippedAds.length === videoAdBreak.ads.length);
  }
}
export default AdData;
