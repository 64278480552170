import Logger from 'lib/logger';
import config from 'config';

/**
 * @module telemetry
 * @desc Converted to class from global function.
 * Method calls to update from XTV code given 'import telemetry...':
 * To log event: 'window.telemetry(' -> 'telemetry.log('
 * To configure user: 'window.telemetry.setUserData' -> 'telemetry.setUserData'
 *
 * The 'setUserDataFromCache' method has been left out for now.
 */

const logger = new Logger('TELEMETRY');

class Telemetry {
  constructor() {
    this._reset();
    this._resetUser();
  }

  async _reset() {
    await config.load();
    this.linchpinConfig = { ...config.linchpin }; // Copy config so tests don't modify the original
    this.queue = [];
    this.isConnected = false;
    this.MAX_CACHE_LENGTH = 10;
  }

  _resetUser() {
    this.userData = {};
  }
  /**
   * Add a logging event to the queue
   * @param {String} event - name of event
   * @param {Array} params - array of data to log, no keys here, so order matters!
   * @param {Object} opts - options
  */
  log(event, params, opts) {
    if (this.linchpinConfig && this.linchpinConfig.enabled && event) {
      this._log(event, params, opts);
    }
  }

  setUserData(sessionResponse) {
    this.userData = {
      deviceId: sessionResponse.tokenSummary.deviceId,
      satToken: sessionResponse.serviceAccessToken,
      xboAccountId: sessionResponse.tokenSummary.xboAccountId
    };
  }

  _log(event, params, opts) {
    // logger.log('isconnected', this.isConnected);
    if (!this.isConnected) {
      this._connect();

      logger.log('userData', this.userData);
      this.queue.push({ metricType: event, opts: opts, params: params });

      if (this.queue.length > this.MAX_CACHE_LENGTH) {
        this.queue.shift();
      }
    } else {
      this.queue.push({ metricType: event, opts: opts, params: params });
      this._flushQueue();
    }
  }

  _createSocket() {
    const sat = this.userData.satToken;

    if (this.linchpinConfig.secondLevelAuth) {
      return new WebSocket(this.linchpinConfig.endpoint + '&deviceId=' + this.userData.deviceId, [this.userData.satToken]);
    }

    if (sat) {
      return new WebSocket(this.linchpinConfig.endpoint, [sat]);
    }
    return new WebSocket(this.linchpinConfig.endpoint);
  }

  _connect() {
    try {
      this.socket = this._createSocket();
      this.socket.onclose = (() => {
        this.isConnected = false;
      });
      this.socket.onerror = (() => {
        this.isConnected = false;
      });
      this.socket.onopen = (() => {
        if (this.socket.readyState === this.socket.OPEN) {
          this.isConnected = true;
          this._flushQueue();
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  /**
   * Send pending logs
  */
  _flushQueue() {
    let sent = true;

    try {
      this.queue.forEach((log) => {
        this._send(log);
      });
    } catch (e) {
      sent = false;
    }

    if (sent) {
      this.queue = [];
    }
  }

  _getSmartEvent(log) {
    const requiredOptionalParameters = {
      accountId: this.userData.xboAccountId,
      deviceId: this.userData.deviceId
    };

    let smartEvent = {
      subSourceType: [
        'subSourceType',
        this.linchpinConfig.appName,
        'optionalParameters'
      ]
    };

    if (log.params && log.params.length) {
      smartEvent = Object.assign({}, { parameters: log.params }, smartEvent);
    }

    log.opts = Object.assign({}, {
      requestId: this._getRequestId()
    }, log.opts);

    if (log.opts.eventCount) {
      smartEvent.eventCount = log.opts.eventCount;
    }

    if (log.opts.groupByKeys) {
      smartEvent.groupByKeys = log.opts.groupByKeys;
    }

    if (log.opts.aggregateMetricsList) {
      smartEvent.aggregateMetricsList = log.opts.aggregateMetricsList;
    }

    // eslint-disable-line quote-props
    smartEvent.subSourceType.push(JSON.stringify(Object.assign({}, requiredOptionalParameters, log.opts.optionalParameters))
      .replace(/"/g, '\''));

    return smartEvent;
  }

  _getRequestId() {
    return Math.round(Math.random() * 0xffff);
  }

  _getSocketData(log) {
    const smartEvent = this._getSmartEvent(log);

    let socketData = {
      lType: 'SMART',
      mType: 'xstream.' + log.metricType,
      requestId: 'requestId' + log.opts.requestId,
      ts: Date.now()
    };

    if (log.metricType === 'SPLUNK_LOG') {
      socketData = Object.assign(socketData, {
        lType: 'SPLUNK',
        splunkEvent: {
          logLevel: 'WARN',
          logMessage: smartEvent.parameters,
          subSourceType: this.linchpinConfig.sourceType
        }
      });
    } else {
      socketData.smartEvent = smartEvent;
    }

    if (log.opts.aggregateMetricsList) {
      socketData.type = 'MetricsEvent';
    }

    return socketData;
  }

  _send(log) {
    const socketData = this._getSocketData(log);
    this.socket.send(JSON.stringify(socketData));
  }
}

export default new Telemetry();
export { Telemetry };
