// Copyright (C) 2019,2020 Comcast Corporation, All Rights Reserved
// setup types
const Type = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  LOG: 'LOG',
  TRACE: 'TRACE',
  WARN: 'WARN',
  ERROR: 'ERROR',
  FATAL: 'FATAL'
};

const BadProp = ['_div', '_parent', '_hi', '_entries', '_panel', '_children', '_pollerController', '_sandbox'];

// override console
const oldDebug = console.debug;
const oldLog = console.log;
const oldError = console.error;
const oldWarn = console.warn;
const oldInfo = console.info;
const oldTrace = console.trace;
const localsetting = 'Chromecast.deviceID';
const logCache = [];
const maxCacheSize = 200;

let sequence;
let loggersocket;
let wsserver;
let connectionState;
let disconnectCount;
let clientmac;
let oncommandcb;
let rpcCallback;
let connectionUUID;
let self;

/**
 * @class WSSLogger
 */
class WSSLogger {
  _lastWarning = '';
  _lastError = '';
  _lastJSException = '';

  // does not seem to work against chromecast devices...
  // it appears to initialize once, but never updates afterwards.
  // keeping it here for later evaluation for VPLAY-2605
  /*
  _pollIntervalMsec = 5000;  // every 5 seconds take a snapshot of memory usage.
  _pollTimer = setInterval(function() {
      this.refreshPerformanceValues();
  }.bind(this), this._pollIntervalMsec);

  refreshPerformanceValues() {
    let mem = {
      totalJSHeapSize: performance.memory.totalJSHeapSize,
      usedJSHeapSize: performance.memory.usedJSHeapSize,
      jsHeapSizeLimit: performance.memory.jsHeapSizeLimit,
      percentageUsed: (performance.memory.usedJSHeapSize / performance.memory.totalJSHeapSize) * 100
    };
    console.warn(JSON.stringify(mem));
  };
  */

  /**
   * getUUID
   * @memberof WSSLogger
   * @return {string} saved UUID
   * chromecast does not have a way to retrieve a device UUID such as a MAC address, so
   * this is used for retreiving of the account's deviceID saved in local storage so
   * this chromecast can be uniquely identified for logging.
   */
  getUUID() {
    if (typeof Storage !== 'undefined' && window.localStorage !== undefined) {
      return window.localStorage.getItem(localsetting);
    }
    return undefined;
  }

  /**
   * saveUUID
   * @memberof WSSLogger
   * @param   {String}    uuid     - uuid to be stored across sessions
   * chromecast does not have a way to retrieve a device UUID such as a MAC address, so
   * this is used for saving the account's deviceID so this chromecast can be uniquely identified
   * for logging.
   */
  saveUUID(uuid) {
    console.log(' saving uuid '+ uuid);
    if (typeof Storage !== 'undefined' && window.localStorage !== undefined) {
      window.localStorage.setItem(localsetting, uuid);
    }
  }

  /**
   * uuidv4
   * @memberof WSSLogger
   * @return {string} generated UUID
   * quick implementation of UUID for syncing logs between disconnects
   */
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = (c === 'x' ? r : (r & 0x3 | 0x8));
      return v.toString(16);
    });
  }

  /**
   * Init
   * @memberof WSSLogger
   * @param   {String}    server     - target ws server URL
   * @param   {String}    partition  - target ws partition for multi-partition service
   * @param   {String}    mac        - target ws mac address
   * @return {WSSLogger}
   */
  async init( server, partition ) {
    self = this;
    wsserver = server;
    connectionState = 'Disconnected';
    disconnectCount = 0;
    connectionUUID = self.uuidv4();
    sequence = 0;
    const savedUUID = self.getUUID();
    if (savedUUID) {
      clientmac = savedUUID;
    }

    const io = await import('socket.io-client/dist/socket.io.js');

    // redirect unhandled errors to websocket logger, webconsole and diagnostics overlay
    window.onerror = function(message, url, linenumber) {
      const timestamp = new Date().toJSON();
      const msg = timestamp + ': ' + message + ' on line ' + linenumber + ' for ' + url;
      self._lastJSException = msg;
      console.error('JavaScript error: ' + msg);
      if (oncommandcb) {
        oncommandcb({ command: 'onError', msg: msg });
      }
    };

    console.debug = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.DEBUG, args );
      } else {
        oldDebug.apply(console, [new Date().toJSON(), Type.DEBUG, args]);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.DEBUG, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    console.log = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.LOG, args);
      } else {
        oldLog.apply(console, args);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.LOG, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    console.warn = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.WARN, args );
      } else {
        oldWarn.apply(console, args);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.WARN, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    console.error = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.ERROR, args );
      } else {
        oldError.apply(console, args);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.ERROR, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    console.info = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.INFO, args );
      } else {
        oldInfo.apply(console, args);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.INFO, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    console.trace = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.TRACE, args );
      } else {
        oldTrace.apply(console, args);
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.TRACE, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    // connect to the websocket logger
    const sockoptions = {};
    // in a partition?
    if ( partition.length > 0 ) {
      sockoptions['path'] = partition+'/socket.io/';
    }
    loggersocket = io.connect( wsserver, sockoptions );

    loggersocket.on('disconnect', function( reason ) {
      oldError.apply(console, ['Unhandled loggersocket.ondisconnect: ', reason]);
    });

    loggersocket.on( 'error', function( reason ) {
      oldError.apply(console, ['Unhandled loggersocket.onerror: ', reason]);
    });

    loggersocket.on( 'message', function( message ) {
      oldInfo.apply(console, ['loggersocket.onmessage: ', JSON.stringify(message)]);
    });

    loggersocket.on( 'connect', function( event ) {
      connectionState = 'Connected';

      // reconnect?- flush the cache first!
      if ( logCache.length > 0) {
        self.flushloggercache();
      }

      // keep a sequence of log entries
      const myseq = sequence++;
      self.loggersocketsend(myseq, new Date().toJSON(), Type.DEBUG, ['websocketlogger client start logging'] );
      oldWarn.apply(console, ['WSSLogger: Redirecting all console messages to', wsserver] );
      if (clientmac) {
        loggersocket.emit('clientinfo', JSON.stringify({ 'clientid': clientmac }) );
      }
    });

    // set the connection status
    loggersocket.on( 'connect_error', function( error ) {
      connectionState = 'connect Error';
      console.error('Socket.io Connect Error: ' + JSON.stringify(error));
    });

    loggersocket.on( 'connect_timeout', function( timeout ) {
      connectionState = 'Connect Timeout';
      console.error('Socket.io Connect Timeout: ' + JSON.stringify(timeout));
    });

    loggersocket.on( 'error', function( error ) {
      connectionState = 'Error';
      console.error('Socket.io Error: ' + JSON.stringify(error));
    });

    loggersocket.on( 'disconnect', function( reason ) {
      connectionState = 'Disconnected: ' + reason;
      console.error('Socket.io: ' + connectionState);
      disconnectCount++;
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, we need to reconnect manually
        loggersocket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    loggersocket.on( 'reconnect', function( attempt ) {
      // log the reconnect.
      connectionState = 'Reconnected: ' + attempt;
      console.warn('Socket.io: ' + connectionState);

      // resend the clientid
      if (clientmac === undefined) {
        clientmac = self.getUUID();
      }
      if (clientmac !== undefined) {
        if ( loggersocket.connected === true ) {
          loggersocket.emit('clientinfo', JSON.stringify({ 'clientid': clientmac }) );
        }
      }
    });

    loggersocket.on( 'reconnect_attempt', function( attempt ) {
      connectionState = 'Reconnect: ' + attempt;
      console.warn('Socket.io: ' + connectionState);
    });

    loggersocket.on( 'reconnecting', function( attempt ) {
      connectionState = 'Reconnecting: ' + attempt;
      console.warn('Socket.io: ' + connectionState);
    });

    loggersocket.on( 'reconnect_error', function( error ) {
      connectionState = 'Reconnect Error';
      console.error('Socket.io Reconnect Error: ' + JSON.stringify(error));
    });

    loggersocket.on( 'reconnect_failed', function() {
      connectionState = 'Reconnect Failed';
      console.warn('Socket.io: ' + connectionState);
    });

    loggersocket.on( 'ping', function() {
      connectionState = 'Connected - Ping Sent';
      console.log('Socket.io: ' + connectionState);
    });

    loggersocket.on( 'pong', function( delayMs ) {
      connectionState = 'Connected - Pong Received - Latency: ' + delayMs / 1000.0;
      console.log('Socket.io: ' + connectionState);
    });

    WSSLogger.prototype.setOnCommandCallback = function( callback ) {
      oncommandcb = callback;
    };

    WSSLogger.prototype.setRpcCallback = function( callback ) {
      rpcCallback = callback;
    };

    loggersocket.on( 'command', function( command ) {
      if (command.command !== 'setAlias') {
        console.warn('Socket.io: command received: ' + JSON.stringify(command));
      }
      if (command.command === 'play') {
        self.playCommanderId = command.reqId;
      }

      oldInfo.apply(console, ['loggersocket.oncommand: ', JSON.stringify(command)]);
      if (oncommandcb) {
        oncommandcb(command);
      }
    });

    loggersocket.on( 'rpcReq', function( req ) {
      console.warn('Socket.io: RPC received: ' + JSON.stringify(req));
      oldInfo.apply(console, ['loggersocket.rpcCallback: ', JSON.stringify(req)]);
      if (rpcCallback) {
        rpcCallback(req);
      }
    });

    WSSLogger.prototype.onDataAvailable = function( data ) {
      console.log('------------------ onDataAvailable: '+JSON.stringify(data));
      if ( data && data.clientid ) {
        const savedUUID = self.getUUID();
        if (savedUUID === undefined) {
          clientmac = data.clientid;
          self.saveUUID(clientmac);
          console.log('------------------ onDataAvailable: '+clientmac);
          if ( loggersocket.connected === true ) {
            loggersocket.emit('clientinfo', JSON.stringify(data) );
          }
        }
      }
    };

    WSSLogger.prototype.sendEvent = function( event ) {
      if (loggersocket.connected && self.playCommanderId !== undefined) {
        event.targetId = self.playCommanderId;
        loggersocket.emit('event', JSON.stringify(event));
      }
    };

    WSSLogger.prototype.sendRpcRsp = function( reqId, clientReqId, fnName, rsp ) {
      const event = { reqId: reqId, clientReqId: clientReqId, fnName: fnName, rsp: rsp };
      if (loggersocket.connected) {
        loggersocket.emit('rpcRsp', JSON.stringify(event));
      }
    };

    WSSLogger.prototype.debug = function(...args) {
      // keep a sequence of log entries
      const myseq = sequence++;
      if ( loggersocket.connected === true ) {
        self.loggersocketsend(myseq, new Date().toJSON(), Type.DEBUG, args );
      }
      // we only keep last maxCacheSize entries in cache to make thing managable
      logCache.push([myseq, new Date().toJSON(), Type.DEBUG, args]);
      while (logCache.length > maxCacheSize) {
        logCache.shift();
      }
    };

    WSSLogger.prototype.connectionStatus = function() {
      return loggersocket.connected;
    };

    WSSLogger.prototype.connectionState = function() {
      return connectionState;
    };

    WSSLogger.prototype.disconnectCount = function() {
      return disconnectCount;
    };

    WSSLogger.prototype.getLogCache = function() {
      return logCache;
    };

    WSSLogger.prototype.close = function() {
      if ( loggersocket.connected === true ) {
        loggersocket.close();
      }
    };
  }

  // try to make a copy not circular
  isNotFunction(func) {
    return !(func && {}.toString.call(func) === '[object Function]');
  }

  stripObj(srcobj) {
    const dstobj = {};
    let prop;
    for (prop in srcobj) {
      if ( self.isNotFunction(srcobj[prop]) && BadProp.indexOf(prop) < 0 ) {
        // uncomment to send circular reference debug to chromecast console in web inspector
        // oldDebug.apply(console, ['circular reference!: ', prop, srcobj[prop]]);
        dstobj[prop] = srcobj[prop];
      }
    }
    return dstobj;
  }

  removeOneProp(srcobj) {
    let prop;
    // the guard-for-in rule does not apply here.
    // eslint-disable-next-line
    for (prop in srcobj) {
      delete srcobj[prop];
      break;
    }
    return srcobj;
  }

  // setup sending method
  loggersocketsend(sequence, timestamp, type, data ) {
    if ( loggersocket.connected === true ) {
      // try to send message - removing any circular reference if message is an object.
      try {
        let msg = '';
        if ( data.length > 1 ) {
          let i;
          for (i=0; i < data.length; i++ ) {
            if (typeof data[i] === 'object') {
              msg += ( i === 0 ) ? JSON.stringify(data[i]) : ' ' + JSON.stringify(data[i]);
            } else {
              msg += ( i === 0 ) ? data[i] : ' ' + data[i];
            }
          }
        } else {
          msg = (typeof data[0] === 'string' && data[0][0] === '{' && data[0].indexOf( '}' ) > 0) ? JSON.parse(data[0]) : data[0];
        }
        if ( type === Type.WARN ) {
          if (typeof msg === 'string') {
            self._lastWarning = new Date(timestamp) + ': ' + msg;
          } else {
            self._lastWarning = new Date(timestamp) + ': ' + JSON.stringify(msg);
          }
        }
        if ( type === Type.ERROR ) {
          if (typeof msg === 'string') {
            self._lastError = new Date(timestamp) + ': ' + msg;
          } else {
            self._lastError = new Date(timestamp) + ': ' + JSON.stringify(msg);
          }
        }
        loggersocket.send(JSON.stringify( { timestamp: timestamp, seq: sequence, connectionUUID: connectionUUID, type: type, msg: msg } ));

        // catch the casting device name
        // NOTE: the strings are JSON.stringified - and has embedded escapes that needs to be matched.
        // eslint-disable-next-line
        if (/^.+\\\"deviceName\\\":.+$/.test(msg)) {
          // caught!
          // eslint-disable-next-line
          const deviceName = msg.split(/\\\"deviceName\\\":\\\"/)[1].split(/\\\"/)[0];
          const aliasoverride = JSON.stringify({
            'aliasoverride': {
              'alias': deviceName,
              'uuid': clientmac,
              'manufacturer': navigator.vendor,
              'model': 'Chromecast',
              'year': '2020'
            }
          });
          loggersocket.send(JSON.stringify({
            timestamp: timestamp,
            seq: sequence,
            connectionUUID: connectionUUID,
            type: type,
            msg: aliasoverride
          }));
          loggersocket.emit('clientinfo', aliasoverride );
        }
      } catch (e) {
        const error = e.message;
        oldDebug.apply(console, ['ERROR!: ', data, e]);
        let probObj = self.stripObj(data[0]);
        for (const prop in probObj) {
          if (prop !== undefined) {
            probObj = self.removeOneProp(probObj);
            try {
              // uncomment for debugging circular object reference issues.
              // oldDebug.apply(console, ['Trying: ', JSON.stringify(probObj)]);
              loggersocket.send(JSON.stringify({
                timestamp: timestamp,
                seq: sequence,
                connectionUUID: connectionUUID,
                type: type,
                msg: JSON.stringify(probObj),
                error: error
              }));
              loggersocket.send(JSON.stringify({
                timestamp: timestamp,
                seq: sequence,
                connectionUUID: connectionUUID,
                type: Type.ERROR,
                msg: error
              }));
              self._lastError = JSON.stringify({
                timestamp: timestamp,
                type: type,
                msg: JSON.stringify(probObj),
                error: error
              });
              break;
            } catch (e) {
              oldDebug.apply(console, ['ERROR!: ', probObj, e]);
            }
          }
        }
      }
    }
  }

  /**
   * flushloggercache
   * @memberof WSSLogger
   * flush log cache without removing items
   */
  flushloggercache() {
    for (let i=0; i<logCache.length; i++) {
      const logEntry = logCache[i];
      self.loggersocketsend(logEntry[0], logEntry[1], logEntry[2], logEntry[3]);
    }
  }
}

export default new WSSLogger();
export { WSSLogger }; // For unit testing
