import { LitElement, html, css, customElement, property } from 'lit-element';
import { isLocalized, t } from 'i18n';

import 'components/cast-message';

/**
 * cast-error-message component
 *
 * Error message display component
 *
 * @class cast-error-message
 * @hideconstructor
 */
@customElement('cast-error-message')
@isLocalized
class ErrorMessage extends LitElement {
  /**
   * Error details
   *
   * @see {@link constants.errors}
   * @name cast-error-message#errorDetails
   * @type {object|null}
   * @default null
   */
  @property({
    type: Object
  })
  errorDetails = null;

  static styles = css`
    p {
      margin: 0 0 3rem 0;
    }

    span {
      color: var(--tv-grey-36);
      font-size: 3rem;
      font-weight: 300;
    }
  `;

  render() {
    if (!this.errorDetails) {
      return html``;
    }

    const { header, message, tvapp } = this.errorDetails;

    return html`
      <cast-message header="${ header }">
        <p>${ t(message) }</p>
        ${ tvapp && html`<span>(TVAPP-${ tvapp })</span>` }
      </cast-message>
    `;
  }
}

export default ErrorMessage;
