import { LitElement, html, css, customElement, property } from 'lit-element';

/**
 * cast-spinner component
 *
 * Loading indicator
 *
 * @class cast-spinner
 * @hideconstructor
 */
@customElement('cast-spinner')
class Spinner extends LitElement {
  /**
   * Flag indicating the spinner is animating
   *
   * @name cast-spinner#active
   * @type {boolean}
   * @default false
   */
  @property({
    type: Boolean,
    reflect: true
  })
  active = false;

  static styles = css`
    :host {
      --dot-size: 2.4rem;
      --dot-spacing: 0.6rem;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
    }

    :host(:not([active])) {
      display: none;
    }

    div {
      width: var(--dot-size);
      height: var(--dot-size);
      background-color: #fff;
      opacity: 0.6;
      margin: 0 var(--dot-spacing);
      border-radius: 100%;
      display: inline-block;
      animation-name: bouncedelay;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      /* Prevent first frame from flickering when animation starts */
      animation-fill-mode: both;
    }

    .bounce1 {
      animation-delay: -0.48s;
    }

    .bounce2 {
      animation-delay: -0.32s;
    }

    .bounce3 {
      animation-delay: -0.16s;
    }

    @keyframes bouncedelay {
      0% {
        opacity: 0.5;
      }

      100% {
        opacity: 0.2;
      }
    }
  `;

  render() {
    return html`
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    `;
  }
}

export default Spinner;
