/**
 * Constants for splunk type supported
 *
 * @namespace constants.splunkTypes
 */
export default {
  AdBreakComplete: 'Ad_Break_End',
  AdBreakExit: 'Ad_Break_End',
  AdBreakStart: 'Ad_Break_Start',
  AdError: 'Ad_Abandonment',
  AdTimelineInfo: 'Ad_Timeline_Info',
  Attempt: 'Attempt',
  BufferStart: 'Buffer Start',
  BufferEnd: 'Buffer End',
  End: 'End',
  error: 'Error',
  Retry: 'Retry',
  Start: 'Start',
  success: 'Success'
};
