/**
 * Manages notifying other parts of the app of auth token changes
 *
 * @hideconstructor
 */
class AuthTokenProvider {
  constructor() {
    this.token = null;
    this.createRefreshPromise();
    this.onExpiredToken = Function.prototype;
  }

  /**
   * Promise that is resolved when a new token is provided.
   *
   * This promise will appear to be always pending. It is resolved when a new
   * token is provided (or rejected on failure to get a new token). It is then
   * immediately replaced by a new, unresolved, promise.
   */
  get tokenRefreshPromise() {
    return this.refreshPromise;
  }

  /**
   * Header to use for making authenticated requests with current token
   */
  get tokenHeader() {
    if (!this.token) {
      return {};
    }

    const { sat, xsct } = this.token;

    return {
      Authorization: sat ?
        `SAT ${ sat }` :
        `CCP ${ xsct }`
    };
  }

  /**
   * Flag indicating if a token is currently available
   */
  get isTokenSet() {
    return this.token !== null;
  }

  /**
   * Set function to call on expired XSCT token error
   *
   * @param {function} fn
   */
  set expiredCallback(fn) {
    this.onExpiredToken = fn;
  }

  /**
   * Set the auth token
   *
   * This will resolve the tokenRefreshPromise
   *
   * @param {object} token
   * @param {string} [token.sat] - SAT token
   * @param {string} [token.xsct] - XSCT token
   */
  setToken(token) {
    this.token = token;

    const resolve = this.resolve;
    this.createRefreshPromise();
    resolve();
  }

  /**
   * Mark token refresh as failed
   *
   * This will reject the tokenRefreshPromise
   *
   * @param {object} error - Error object to reject tokenRefreshPromise with
   */
  tokenRefreshFailed(error) {
    const reject = this.reject;
    this.createRefreshPromise();
    reject(error);
  }

  createRefreshPromise() {
    this.refreshPromise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

/*
 * I'm doing some off-label stuff here. Be warned!
 *
 * tl;dr:
 * - If you just want to use this module, then do:
 *     import authTokenProvider from 'lib/auth-token-provider';
 *
 *   The `authTokenProvider` you get is an instance of the AuthTokenProvider
 *   class above.
 *
 * - If you're writing tests that mess with the state of authTokenProvider,
 *   then include this in your spec file:
 *     import { resetAuthTokenProvider } from 'lib/auth-token-provider';
 *
 *   Call `resetAuthTokenProvider in a beforeEach block.
 *
 * The long explanation:
 *
 * The following code implements a resettable singleton. The
 * `authTokenProvider` that is exported isn't strictly an instance of the
 * class above. It's a Proxy that redirects property get/set/delete on to an
 * instance of the AuthTokenProvider class.
 *
 * The `resetAuthTokenProvider` function replaces that class instance. The
 * already-imported `authTokenProvider` Proxies will now redirect property
 * lookups to the new instance.
 */
let instance = new AuthTokenProvider();

/**
 * Regenerate the shared AuthTokenProvider instance
 *
 * **DO NOT USE THIS OUTSIDE OF TESTS!**
 *
 * This will recreate the instance of AuthTokenProvider used throughout the
 * app. This is to be used in tests to prevent inter-test dependency issues.
 *
 *
 */
const resetAuthTokenProvider = () => {
  // const reject = instance.reject;
  instance = new AuthTokenProvider();
  // reject(new Error('AuthTokenProvider reset'));
};

const handler = {
  get(target, prop) {
    return Reflect.get(instance, prop);
  },

  set(target, prop, value) {
    return Reflect.set(instance, prop, value);
  },

  deleteProperty(target, prop) {
    return Reflect.deleteProperty(instance, prop);
  }
};

export default new Proxy(instance, handler);
export { resetAuthTokenProvider };
