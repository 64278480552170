const prefix = 'tv-';

export const clear = () => {
  // Only remove keys that start with the key prefix
  Object.keys(localStorage).filter((k) => {
    return k.indexOf(prefix) === 0;
  }).forEach((k) => {
    localStorage.removeItem(k);
  });
};

export const getItem = (key) => {
  const value = localStorage.getItem(key);

  if (value) {
    // If unprefixed key exists, upgrade it to a prefixed key
    localStorage.setItem(prefix + key, value);
    localStorage.removeItem(key);
    return value;
  }

  return localStorage.getItem(prefix + key);
};

export const removeItem = (key) => {
  localStorage.removeItem(key); // Remove unprefixed key if it exists
  return localStorage.removeItem(prefix + key);
};

export const setItem = (key, value) => {
  localStorage.removeItem(key); // Remove unprefixed key if it exists
  return localStorage.setItem(prefix + key, value);
};
