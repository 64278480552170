
/**
 * @module DiscoAPI
 */
import Logger from 'lib/logger';
import { BrowseApi, PersonalizationApi, SearchApi, WatchOptionsApi, MetadataApi } from '@devplat/xvp-disco-client';
import config from 'config';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';


const SWAGGER_CLIENT_ID = 'swagger-ui';
const STG_BASE_PATH = 'https://disco-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://disco.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP', { background: 'blue', color: 'white' });
/**
 * Capability Required: [x1:xvp-apis:disco-api:prod:discovery], Device Sat Allowed: true
 * For a given program ID (pawId parameter), this API serves content watched by other users who also
 * watched the program associated with the programId in the request. The content served is referred to
 * as \"People Also Watched (PAW)\" (formerly \"More Like This (MOLT)).
 * This API aligns with Browse interface - filters have been limited for simplicity, but other Browse
 * filters can be exposed upon request. For linear catalog only the programs that are getting
 * aired within next 14 days are indexed and returned.
 * Browse programs over linear and vod catalogs applying filters, sorts and personalization.
 */
class DiscoAPI {
  _session = null;
  _ready = false;
  _browseProgramsApi = null;
  _personalizationApi = null;
  _searchApi = null;
  _watchOptionsApi = null;
  environment = 'prod';

  get ready() {
    return this._ready;
  }
  constructor() {
    console.log('  disco constructor here? ');
  }
  init( params = {} ) {
    // TODO error check on session config data
    this._session = params.session;
    this._ready = true;
    this.environment = config.env || config.environment.ENV;
    this._browseProgramsApi = this._getBrowseProgramsApi();
    this._metadataApi = this._getMetadataApi();
    this._personalizationApi = this._getPersonalizationApi();
    this._searchApi = this._getSearchApi();
    this._watchOptionsApi = this._getWatchOptionsApi();

  //  console.log(' can I access getChannelsApi( here? ' + JSON.stringify(config));
  }
  /** -  XVP API Methods -**/

  /**
   * Method returns XVP channelmap payload
   * @param  {Object}  options fields for search options
   * @return {Promise}  XVP payload of programs available
   */
  async programsGet(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    return await this._browseProgramsApi && this._browseProgramsApi.programsGet({
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      contentCatalogTypes: ['vod']

    });
  }
  async getApps(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};

    return await this._browseProgramsApi && this._browseProgramsApi.getApps({
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      contentCatalogTypes: ['app']

    });
  }
  async getEntityMetaDataForNewProgram(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: options.entityId
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getEntityMetaDataForNewProgram using _metadataApi: options ', options);
    senderDebugger.debugNetworkMessage('[XVP][API] getEntityMetaDataForNewProgram using _metadataApi:', paramObj);
    return await this._metadataApi && this._metadataApi.getEntityMetaDataForNewProgramRaw(paramObj);
  }
  async getWatchOptionsForProgram(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: options.entityId
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForProgram using:_watchOptionsApi options ', options);
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForProgram using:_watchOptionsApi', paramObj);
    return await this._watchOptionsApi && this._watchOptionsApi.getWatchOptionsForProgram(paramObj);
  }
  async getWatchOptionsForSeriesMaster(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: options.entityId
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForSeriesMaster using _watchOptionsApi: options ', options);
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForSeriesMaster using: _watchOptionsApi', paramObj);
    return await this._watchOptionsApi && this._watchOptionsApi.getWatchOptionsForSeriesMaster(paramObj);
  }
  async getWatchOptionsForTvSeason(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: options.entityId,
      seasonNumber: options.season
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForTvSeason using: _watchOptionsApi options ', options);
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForTvSeasonUngrouped using: _watchOptionsApi', paramObj);
    return await this._watchOptionsApi && this._watchOptionsApi.getWatchOptionsForTvSeason(paramObj);
  }
  async getWatchOptionsForTvSeasonUngrouped(options = {}) {
    const tokenSummary = this._session.tokenSummary || {};
    const paramObj = {
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: options.entityId
    };
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForTvSeasonUngrouped using _watchOptionsApi: options ', options);
    senderDebugger.debugNetworkMessage('[XVP][API] getWatchOptionsForTvSeasonUngrouped using _watchOptionsApi:', paramObj);
    return await this._watchOptionsApi && this._watchOptionsApi.getWatchOptionsForTvSeasonUngrouped(paramObj);
  }
  /*  async getWatchOptionsForTvSeason(opts = {}) {
    const tokenSummary = this._session.tokenSummary || {};

    return await this._watchOptionsApi && this._watchOptionsApi.getWatchOptionsForTvSeason({
      partnerId: tokenSummary.partnerId,
      deviceId: tokenSummary.deviceId,
      clientId: tokenSummary.clientId || SWAGGER_CLIENT_ID,
      accountId: tokenSummary.xboAccountId,
      entityId: opts.entityId,
      ...opts
    });
  }
  */
  _getBrowseProgramsApi( options = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.error('_getBrowseProgramsApi: did not provide tokens');
      return;
    }
    return new BrowseApi({
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
  _getPersonalizationApi( config = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.log('_getPersonalizationApi: did not provide tokens');
      return;
    }
    return new PersonalizationApi({
      basePath: BASE_PATH,
      fetchApi: window.fetch.bind(window),
      xsct: this._session.xsct,
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
  _getSearchApi( config = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.log('_getSearchApi: did not provide tokens');
    }
    return new SearchApi({
      basePath: BASE_PATH,
      fetchApi: window.fetch.bind(window),
      xsct: this._session.xsct,
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
  _getWatchOptionsApi( config = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.log('_getWatchOptionsApi: did not provide tokens');
    }
    return new WatchOptionsApi({
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
  _getMetadataApi( config = {} ) {
    if (Object.keys(config) === 0 || !this._session.serviceAccessToken || !this.ready) {
      logger.log('_getMetadataApi: did not provide tokens');
    }
    return new MetadataApi({
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: () => {
        return this._session.serviceAccessToken;
      },
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }
  /** - Public getters - **/
  get browseProgramsApi() {
    if (!this._browseProgramsApi || !this.ready) {
      logger.error('browseProgramsApi: browseProgramsApi is: ' + this._browseProgramsApi +
    ' Disco Class Service ready is: ' + this.ready );
      return null;
    }
    return this._browseProgramsApi;
  }
}

/**
 * Spin up & return the XVP Linear Channels client
 * https://github.comcast.com/devplat
 * @param {object} config - session config
 * @return {object} ChannelsApi
 */


/**
 * Spin up & return the XVP Linear Stations client
 * https://github.comcast.com/devplat
 * @param {object} config - session config
 * @return {object} StationsApi
 *
const
*/

export default new DiscoAPI();
