const NO_RATING_REGEX = /nr/i;

const parentalControls = {
  /**
   * Determine if an asset is locked
   *
   * Evaluates if an asset (listing or creative work) is locked by rating, title,
   * channel, or network.
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  async isLocked(settings, model) {
    if (!settings || !settings.enabled) {
      // Controls disabled or not provided, nothing is locked
      return false;
    }

    return (
      await this.isRatingLocked(settings, model) ||
      await this.isAdultLocked(settings, model) ||
      await this.isTitleLocked(settings, model) ||
      await this.isChannelLocked(settings, model) ||
      await this.isNetworkLocked(settings, model)
    );
  },

  /**
   * Determine if asset is locked by rating
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  isRatingLocked({ ratingLocks }, model) {
    if (!model.rating) {
      // No rating for program: Not locked
      // NOTE: This is different from a program being rated NR
      return false;
    }

    const { scheme, name: rating, subRating } = model.rating;
    const locks = scheme && ratingLocks[scheme];

    if (!locks) {
      // Unknown scheme/no scheme found in rating data: Not locked
      return false;
    }

    if (locks[rating]) {
      const advisories = (subRating || []).map((advisory) => locks[rating].advisories[advisory]).filter(Boolean);

      if (advisories.length > 0) {
        // Enforcing based on adivsory locks: Locked if ANY advisory rating is locked
        return advisories.some((advisory) => advisory.locked);
      }

      // Enforcing based on the top-level rating lock
      return locks[rating].locked;
    } else if (NO_RATING_REGEX.test(rating)) {
      // Program is rated NR: Locked if any rating for the scheme is locked
      return Object.values(locks).some((lock) => lock.locked);
    }

    // Non-standard rating for scheme and not rated NR: Not locked
    return false;
  },

  /**
   * Determine is asset is locked by isAdult flag
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  isAdultLocked({ ratingLocks }, model) {
    if (!model.isAdult) {
      // Not adult rated, so skip rating check
      return false;
    }

    return Object.values(ratingLocks).some((locks) => {
      return Object.values(locks).some((lock) => lock.locked);
    });
  },

  /**
   * Determine if asset is locked by title
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  async isTitleLocked({ titleLocks }, model) {
    const creativeWork = model.creativeWork || model;

    if (creativeWork.loadSeries) {
      await creativeWork.loadSeries();
    }

    const merlinId = creativeWork.series
      ? creativeWork.series.merlinId
      : (creativeWork.merlinId || creativeWork.programId);

    return !!(titleLocks[merlinId] && titleLocks[merlinId].locked);
  },

  /**
   * Determine if asset is locked by channel
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  isChannelLocked({ channelLocks }, model) {
    const stationId = model.channel
      ? model.channel.stationId
      : model.stationId;

    return !!channelLocks[stationId];
  },

  /**
   * Determine if asset is locked by network
   *
   * @param {object} settings - Parental controls settings data
   * @param {object} model - Listing or CreativeWork model
   * @return {boolean}
   */
  isNetworkLocked({ networkLocks }, model) {
    const companyId = model.contentProvider
      ? model.contentProvider.companyId
      : model.companyId;

    return !!networkLocks[companyId];
  }
};

export default parentalControls;
