import { LitElement, html, css, customElement, property } from 'lit-element';
import { isLocalized, t } from 'i18n';
import config from 'config';

/**
 * cast-message component
 *
 * Message display component
 *
 * If the default message formatting is insufficient, content may be nested in
 * the component tag
 *
 * @example <caption>Basic usage</caption>
 * <cast-message header="Example message header" message="Example message" />
 *
 * @example <caption>Providing custom message content</caption>
 * <cast-message header="Example message header">
 *   Custom message content
 * </cast-message>
 *
 * @class cast-message
 * @hideconstructor
 */
@customElement('cast-message')
@isLocalized
class Message extends LitElement {
  /**
   * Message header
   *
   * @name cast-message#header
   * @type {string}
   * @default ""
   */
  @property({
    type: String
  })
  header = '';

  /**
   * Message
   *
   * @name cast-message#message
   * @type {string}
   * @default ""
   */
  @property({
    type: String
  })
  message = '';

  static styles = css`
    :host {
      box-sizing: border-box;
      width: 100vw;
      height: 100vh;
      padding: 5vh 5vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    h1 {
      color: var(--tv-grey-9);
      font-size: 6.3rem;
      font-weight: 300;
      margin: 0 0 3rem 0;
    }

    ::slotted(*), .message {
      color: var(--tv-grey-36);
      font-size: 3.6rem;
      font-weight: 400;
    }
  `;

  render() {
    return html`
      <h1>${ t(this.header) }</h1>
      <slot>
        ${ this.message.length > 0 && html`<span class="message">${ t(this.message, { partner: config.partner }) }</span>` }
      </slot>
    `;
  }
}

export default Message;
