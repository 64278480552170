/**
 *  @module DeviceChecker
 *  @desc Provides Chromecast device checks and attempts best guess at device in use
 */
import config from 'config';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';

class DeviceChecker {
  constructor() {
    window.deviceDetect = this; // to be removed after testing - allows easier debugging
    this.highSupportChecks = config.bitrateUnCappedDevices;

    this.supportedResolutions = {
      '720p': { width: 1280, height: 720 },
      '1080p': { width: 1920, height: 1080 },
      '2160p': { width: 3840, height: 2160 }
    };
    this.codecs = {
      h264Lvl4Dot1: 'avc1.640028.mp4a.40.5',
      h264Lvl4Dot2: 'avc1.64002A.mp4a.40.5',
      h265: 'hev1.1.6.L150.B0',
      h265_93: 'hev1.1.6.L93.00',
      h265_123_B0: 'hev1.1.6.L123.B0',
      h265_123_00: 'hev1.1.6.L123.00',
      h265_153_00: 'hev1.1.6.L153.00',
      av01_215M: 'av01.2.15M.10.0.100.09.16.09.0',
      avc3_64001f: 'avc3.64001f,mp4a.40.5',
      avc1_640029: 'avc1.640029,mp4a.40.5',
      avc1_4d401f: 'avc1.4d401f,mp4a.40.5',
      avc1_42d01e: 'avc1.42d01e,mp4a.40.5',
      avc1_42c01e: 'avc1.42c01e,mp4a.40.5',
      avc1_42c015: 'avc3.42c015,mp4a.40.5',
      vp09_00: 'vp09.00.10.08',
      vp09_02: 'vp09.02.10.10.01.09.16.09.01'
    };
    this.context = window.cast && window.cast.framework && window.cast.framework.CastReceiverContext.getInstance();
    this.devices = {
      'ultra4thGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.vp09_02,
        resolution: this.supportedResolutions['2160p'],
        framerate: 30
      },
      '4thGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.vp09_01,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'ultra3rdGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      '3rdGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot2,
        resolution: this.supportedResolutions['1080p'],
        framerate: 60
      },
      '2ndGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      '1stGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'builtIn': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['2160p'],
        framerate: 30
      },
      'hubMax': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot2,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'hub': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['720p'],
        framerate: 30
      }
    };
    this.deviceList = [
      { name: 'ultra4thGen', checkMethod: 'canDeviceSupportUltra4thGen' },
      { name: '4thGen', checkMethod: 'canDeviceSupport4thGen' },
      { name: 'ultra3rdGen', checkMethod: 'canDeviceSupportUltra3rdGen' },
      { name: '3rdGen', checkMethod: 'canDeviceSupport3rdGen' },
      { name: '2ndGen', checkMethod: 'canDeviceSupport2ndGen' },
      { name: '1stGen', checkMethod: 'canDeviceSupport1stGen' },
      { name: 'hubMax', checkMethod: 'canDeviceSupportNestHubMax' },
      { name: 'hub', checkMethod: 'canDeviceSupportNestHub' },
      { name: 'builtIn', checkMethod: 'canDeviceSupportBuiltIn' }
    ];
  }

  async allDeviceChecks() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const deviceResults = [];
    if (this.highSupportChecks === undefined) {
      this.highSupportChecks = config.bitrateUnCappedDevices;
    }
    const deviceCapabilities = JSON.stringify(this.context.getDeviceCapabilities(), undefined, 4);
    const deviceOS = this.getDevicesOSVersion();
    const userAgent = navigator.userAgent.toString();
    const hdcpVersion = await getHdcpVersion();

    this.deviceModelBestGuess = this.getModelDeviceBestGuess();
    deviceResults.push(`Chromecast User Agent: ${userAgent}`);
    deviceResults.push(`Chromecast Device Capabilities: ${deviceCapabilities}`);
    deviceResults.push(`Chromecast DeviceData HDCP CALL: ${hdcpVersion}`);
    deviceResults.push(`DeviceOS: ${deviceOS}`);
    deviceResults.push(`Chromecast Device Best Guess: ${this.deviceModelBestGuess}`);
    deviceResults.push(`Chromecast High Support Devices: ${JSON.stringify(this.highSupportChecks)}`);

    this.deviceList.forEach(async (deviceObj) => {
      const methodCheck = await this[deviceObj.checkMethod]();

      deviceResults.push({
        deviceTypeCheck: `${deviceObj.name} - TEST Result:${methodCheck}`
      });
    });
    const deviceData = Object.assign({}, deviceResults);

    senderDebugger.debugPlayerMessage('[deviceDetect][allDeviceChecks] RESULTS:', {
      deviceCheckData: deviceData
    });
    return deviceData;
  }
  getCodecResults() {
    const deviceDetails = {
      message: '[deviceDetails][vp9tests]: ',
      detail: {
        displayResult: this.displayTestSuite(),
        VP9_tests: this.vp9CodecTests()
      }
    };
    return deviceDetails;
  }
  displayTestSuite(displayResult = []) {
    Object.entries(this.codecs).forEach(async (codecEntry, i)=> {
      displayResult.push( { codec: codecEntry[0], result: this.displayTest(codecEntry[1]) });
    });
    return displayResult;
  }
  async getDeviceDetails() {
    const hdcpVersion = await this.getHdcpVersion();
    const deviceCapabilities = this.context.getDeviceCapabilities();
    const displayResults = [];
    const supportChecks = {
      'ultra4thGen': await this.canDeviceSupport('ultra4thGen'),
      '4thGen': await this.canDeviceSupport('4thGen'),
      'ultra3rdGen': await this.canDeviceSupport('ultra3rdGen'),
      '3rdGen': await this.canDeviceSupport('3rdGen'),
      '2ndGen': await this.canDeviceSupport('2ndGen'),
      '1stGen': await this.canDeviceSupport('1stGen'),
      'builtIn': await this.canDeviceSupport('builtIn'),
      'hub': await this.canDeviceSupport('hub'),
      'hubMax': await this.canDeviceSupport('hubMax')
    };
    this.displayTestSuite(displayResults);

    const VP9tests = this.vp9CodecTests();
    const deviceDetails = {
      message: '[deviceDetails]: ',
      detail: {
        bestGuessDevice: this.getModelDeviceBestGuess(),
        HDRSupport: this.canDeviceSupportHDR(),
        hdcpVersion: hdcpVersion,
        capabilities: deviceCapabilities,
        VP9_tests: VP9tests
      },
      deviceSupportList: supportChecks,
      displayResult: displayResults
    };

    return deviceDetails;
  }
  vp9CodecTests() {
    const VP9tests = [];

    if (MediaSource.isTypeSupported('video/webm; codecs="vp9"')) {
      VP9tests.push('> Some VP9 profile \n- basic video/webm; codecs=vp9');
    } else {
      VP9tests.push('video/webm; codecs="vp9" Basic VP9 Profile Failed.');
    }

    if (MediaSource.isTypeSupported('video/webm; codecs="vp09.00.10.08"')) {
      VP9tests.push('> VP9,codec: vp09.00.10.08 \nProfile 0, level 1, bit depth 8 (later fields defaulted)');
    }

    if (MediaSource.isTypeSupported('video/webm; codecs="vp09.01.20.08.01"')) {
      VP9tests.push('> VP9, codec: vp09.01.20.08.01 \nProfile 1, level 2, bit depth 8, ' +
          '4:2:0 chroma subsampling colocated with (0,0) luma, ' +
          '(later fields defaulted)');
    }

    if (MediaSource.isTypeSupported('video/webm; codecs="vp09.01.20.08.01.01.01.01.00"')) {
      VP9tests.push('> VP9, codec: p09.01.20.08.01.01.01.01.00 \nProfile 1, level 2, bit depth 8, ' +
          '4:2:0 chroma subsampling colocated with (0,0) luma, ' +
          'REC709 color/transfer/matrix, ' +
          'luma/chroma encoded in the "legal" range.');
    }

    if (MediaSource.isTypeSupported('video/webm; codecs="vp09.02.10.10.01.09.16.09.01"')) {
      VP9tests.push('> VP9, codec: vp09.02.10.10.01.09.16.09.01 \nProfile 2, level 1, 10-bit YUV content, ' +
          '4:2:0 colocated with luma (0,0) chroma subsampling, ' +
          'ITU-R BT.2020 primaries, ' +
          'ST 2084 EOTF, ITU-R BT.2020 non-constant luminance color matrix, ' +
          'full-range chroma/luma encoding.');
    }
    return VP9tests;
  }
  getModelDeviceBestGuess() {
    return (this.deviceList.find((deviceObj) => {
      return this[deviceObj.checkMethod]();
    }) || {}).name || 'unknown device';
  }

  getCurrentDeviceSupport() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const deviceCapabilities = this.context.getDeviceCapabilities();
    return `Device Config Partner List: ${JSON.stringify(config.bitrateUnCappedDevices)} 
    Chromecast Device Capabilities: ${JSON.stringify(deviceCapabilities, null, 4)}\r`;
  }

  checkHighBitrateSupport() {
    if (this.canDeviceSupportHDR()) {
      return true;
    }
    if (this.highSupportChecks === undefined) {
      this.highSupportChecks = config.bitrateUnCappedDevices;
    }
    const currentDevice = this.getModelDeviceBestGuess();
    const inHighSupportList = this.highSupportChecks && this.highSupportChecks.includes(currentDevice);
    const deviceObj = this.deviceList.find((deviceObj) => deviceObj.name === currentDevice);

    if (inHighSupportList) {
      return this[deviceObj.checkMethod]();
    }
    return false;
  }

  canDeviceSupportHDR() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const deviceCapabilities = this.context && this.context.getDeviceCapabilities() || {};
    const { is_hdr_supported: isHdrSupported } = deviceCapabilities;
    return isHdrSupported;
  }

  async getHdcpVersion() {
    const castPlatformDisplay = window.cast && window.cast.__platform__ && window.cast.__platform__.display;
    return castPlatformDisplay ? await castPlatformDisplay.getHdcpVersion() : (this.canDeviceSupport4thGen() ? 2.2 : 1.4);
  }

  async canDeviceSupport(deviceName) {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const { mimeType, codec, resolution, framerate } = this.devices[deviceName];
    return this.context.canDisplayType &&
     this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }
  displayTest(codecString) {
    const displayResults = [];
    if (!this.context.canDisplayType) {
      return { result: false };
    }
    Object.entries(this.supportedResolutions).forEach(async (resolution) => {
      const displayResult = this.context.canDisplayType('video/mp4', codecString, resolution[1].width, resolution[1].height, 30);
      displayResults.push({ message: `${codecString} at ${resolution[0]} :
       30FPS = ${displayResult}`, support: displayResult,
      codec: codecString,
      width: resolution[1].width,
      height: resolution[1].height,
      frameRate: 60 });
    });
    Object.entries(this.supportedResolutions).forEach(async (resolution) => {
      const displayResult = this.context.canDisplayType('video/mp4', codecString, resolution[1].width, resolution[1].height, 60);
      displayResults.push({
        message: `${codecString} at ${resolution[0]} : 60FPS = ${displayResult}`,
        support: displayResult,
        codec: codecString,
        width: resolution[1].width, height: resolution[1].height, frameRate: 60 });
    });
    return displayResults;
  }
  canDeviceSupportUltra4thGen() {
    const deviceOS = this.getDevicesOSVersion();
    const androidOS = deviceOS.toLowerCase().search('android') >= 0;
    const userAgent = navigator.userAgent.toString();
    const buildOf4thGenUHD = userAgent.toLowerCase().search('stte.230319.008.h1') >= 0;

    return buildOf4thGenUHD || (this.canDeviceSupport('ultra4thGen') && androidOS);
  }

  canDeviceSupport4thGen() {
    const deviceOS = this.getDevicesOSVersion();
    const androidOS = deviceOS.toLowerCase().search('android') >= 0;
    const userAgent = navigator.userAgent.toString();
    const buildOf4thGen = userAgent.toLowerCase().search('stte.230319.008.r1') >= 0;

    return buildOf4thGen || (this.canDeviceSupport('4thGen') && androidOS);
  }

  canDeviceSupportUltra3rdGen() {
    const userAgent = navigator.userAgent.toString();
    const cpuPlatform = userAgent.toLowerCase().search('aarch64') >= 0;

    return cpuPlatform && this.canDeviceSupport('ultra3rdGen');
  }

  canDeviceSupport3rdGen() {
    const userAgent = navigator.userAgent.toString();
    const cpuPlatform = userAgent.toLowerCase().search('armv7l') >= 0;
    const isDeviceChromecastOS = this.getDevicesOSVersion().toLowerCase() === 'chromecast';
    // Prevents built-in tv devices with HDR support from being ID as gen3
    if (this.canDeviceSupportHDR()) {
      return false;
    }

    return isDeviceChromecastOS && cpuPlatform && this.canDeviceSupport('3rdGen');
  }

  canDeviceSupport2ndGen() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const { mimeType, codec, resolution, framerate } = this.devices['2ndGen'];
    return (
      this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate) &&
      this.isOSHigherThanOrEqual(this.getDevicesOSVersion(), '1.56.275')
    );
  }

  canDeviceSupport1stGen() {
    return this.canDeviceSupport('1stGen') && this.isOSLowerThanOrEqual(this.getDevicesOSVersion(), '1.36');
  }

  canDeviceSupportNestHubMax() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const { mimeType, codec, resolution, framerate } = this.devices.hubMax;
    const touchSupport = this.context.getDeviceCapabilities().touch_input_supported || false;
    return touchSupport && this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }
  canDeviceSupportNestHub() {
    if (!this.context) {
      return 'No Chromecast Context Found';
    }
    const { mimeType, codec, resolution, framerate } = this.devices.hub;
    const touchSupport = this.context.getDeviceCapabilities().touch_input_supported || false;
    return touchSupport && this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }
  canDeviceSupportBuiltIn() {
    return this.canDeviceSupport('builtIn');
  }

  getDevicesOSVersion() {
    const userAgent = navigator.userAgent.toString();
    const userAgentSplit = userAgent.split('/', 8);
    const fullOS = userAgentSplit[userAgentSplit.length - 1];
    return fullOS;
  }

  isOSLowerThanOrEqual(osVersion, expectedOS) {
    const os = osVersion.split('.', 2);
    const expectedOSArray = expectedOS.split('.', 2);
    return Number(os[1]) <= Number(expectedOSArray[1]);
  }
  isOSHigherThanOrEqual(osVersion, expectedOS) {
    const os = osVersion.split('.', 3);
    const expectedOSArray = expectedOS.split('.', 3);
    if (Number(os[1]) === Number(expectedOSArray[1])) {
      return Number(os[2]) >= Number(expectedOSArray[2]);
    }
    return Number(os[1]) > Number(expectedOSArray[1]);
  }
}

const deviceChecker = new DeviceChecker();
export const allDeviceChecks = deviceChecker.allDeviceChecks.bind(deviceChecker);
export const checkHighBitrateSupport = deviceChecker.checkHighBitrateSupport.bind(deviceChecker);
export const getModelDeviceBestGuess = deviceChecker.getModelDeviceBestGuess.bind(deviceChecker);
export const getCurrentDeviceSupport = deviceChecker.getCurrentDeviceSupport.bind(deviceChecker);
export const getDeviceDetails = deviceChecker.getDeviceDetails.bind(deviceChecker);
export const getCodecResults = deviceChecker.getCodecResults.bind(deviceChecker);
export const getHdcpVersion = deviceChecker.getHdcpVersion.bind(deviceChecker);
