import BaseModel from 'model/base';

/**
 * Transaction details object
 *
 * @hideconstructor
 */
class TransactionDetailsModel extends BaseModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps();

    return {
      ...await super._propertiesFromResource(resource),
      date: Number(props.date),
      price: props.price,
      type: props.type
    };
  }

  /**
   * Build transaction details from Hypergard resource
   *
   * @param {object} resource - Hypergard resource
   * @return {TransactionDetailsModel}
   */
  static async fromResource(resource) {
    return new TransactionDetailsModel(await TransactionDetailsModel._propertiesFromResource(resource));
  }
}

export default TransactionDetailsModel;
