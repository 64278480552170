import { LitElement, html, css, customElement, property } from 'lit-element';
import { isLocalized, t } from 'i18n';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { buildSrcSet } from 'lib/helpers';

/**
 * cast-up-next component
 *
 * Metadata for the entity that will play next
 *
 * @class cast-up-next
 * @hideconstructor
 */
@customElement('cast-up-next')
@isLocalized
class UpNext extends LitElement {
  /**
   * Status of autoplay setting toggle
   *
   * @name cast-up-next#autoplayEnabled
   * @type {boolean}
   * @default false
   */
  @property({ type: Boolean })
  autoplayEnabled = false;

  /**
   * HyperGard action for background image URL
   *
   * @name cast-idle#backgroundAction
   * @type {object|null}
   * @default null
   */
  @property({
    type: Object
  })
  backgroundAction = null;

  /**
   * Entity details
   *
   * @name cast-up-next#creativeWork
   * @type {object|null}
   * @default null
   */
  @property({ type: Object })
  creativeWork = null;

  /**
   * Sender partner ID
   *
   * @name cast-up-next#senderPartnerId
   * @type {boolean}
   * @default ''
   */
  @property({ type: String })
  senderPartnerId = '';

  /**
   * Remaining time in episode
   *
   * @name cast-up-next#timeRemaining
   * @type {number}
   * @default 0
   */
  @property({ type: Number })
  timeRemaining = 0;

  static styles = css`
    /* Make opacity work on the component */
    :host > * {
      opacity: inherit;
      transition: opacity 0.25s;
    }

    .title {
      color: var(--tv-grey-18);
      font-size: 4.8rem;
      font-weight: 400;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .details {
      color: var(--tv-grey-9);
      font-size: 3.6rem;
      font-weight: 400;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .countdown {
      color: var(--tv-grey-18);
      font-size: 3.6rem;
      font-weight: 400;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .countdown span {
      color: var(--tv-grey-9);
    }

    .upNextWrapper {
      position: absolute;
      bottom: 8rem;
      left: 10rem;
    }
  `;

  backgroundImageUrl(params) {
    if (!this.backgroundAction) {
      return '';
    }

    return `url(${ this.backgroundAction.setParams({ rule: '16x9_KeyArt', ...params }).getActionUrl() })`;
  }

  getDetails() {
    if (this.creativeWork.isTvEpisode()) {
      const { season, episode, title } = this.creativeWork;
      const hasSeason = season !== undefined;
      const hasEpisode = episode !== undefined;
      const separator = ((hasSeason || hasEpisode) && title) ? ' - ' : null;
      return html`
        ${ hasSeason ? html`S${ season }` : null }
        ${ hasEpisode ? html`Ep${ episode }` : null }
        ${ separator }
        ${ title }
      `;
    }

    return null;
  }

  render() {
    if (!this.creativeWork || this.timeRemaining > 20 || this.timeRemaining < 0) {
      return html``;
    }

    const seriesTitle = this.creativeWork.series ? this.creativeWork.series.title : this.creativeWork.title;

    if (!this.autoplayEnabled) {
      const srcSet = buildSrcSet({
        '1x': this.backgroundImageUrl({ width: 1280, height: 720 }),
        '1.5x': this.backgroundImageUrl({ width: 1920, height: 1080 })
      });

      return html`
        <style>
          :host {
            /* offset horizontal and vertical pad */
            margin-left: -13.3rem;
            margin-bottom: -12.2rem;
            display: block;
            height: 100vh;
            width: 100vw;
            background: -webkit-image-set(
              ${ srcSet }
            );
            background-size: cover;
          }
        </style>
        <div class="upNextWrapper">
          <div class="title">
            ${ t('Next Episode of title', { title: seriesTitle }) }
          </div>
          <div class="details">${ this.getDetails() }</div>
          <div class="countdown">${ t('Go to Partner to play.', { partner: this.senderPartnerId }) }
        </div>`;
    }

    return html`
      <div class="title">
        ${ t('Next Episode of title', { title: seriesTitle }) }
      </div>
      <div class="details">${ this.getDetails() }</div>
      <div class="countdown">${ unsafeHTML(t('Starts in secs', { secs: this.timeRemaining })) }</div>`;
  }
}

export default UpNext;
