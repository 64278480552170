/**
 * @module LinearAPI
 */
import Logger from 'lib/logger';
import {
  LinearStreamControllerApi,
  LinearTVEStreamControllerApi,
  VodTveStreamControllerApi,
  VodStreamControllerApi,
  PurchasedVodStreamControllerApi,
  RecordingStreamControllerApi,
  TveRecordingStreamControllerApi
} from '@devplat/xvp-rights-client';
import config from 'config';
import { senderDebugger } from '../../debug/sender-receiver-debug';

const STG_BASE_PATH = 'https://rights-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://rights.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP', { background: 'blue', color: 'white' });

class RightsAPI {
  _session = null;
  _ready = false;
  _accessTokenResponse= '';
  _tokenSummary = {};
  _linearStreamControllerApi = null;
  _linearTVEStreamControllerApi = null;
  _vodTveStreamControllerApi = null;
  _vodTveStreamControllerApi = null;
  _purchasedVodStreamControllerApi = null;
  _recordingStreamControllerApi = null;
  _tveRecordingStreamControllerApi = null;

  get ready() {
    return this._ready;
  }

  get linearStreamControllerApi() {
    if (!this._linearStreamControllerApi || !this.ready) {
      logger.error('linearStreamControllerApi: linearStreamControllerApi is: ' + this._linearStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._linearStreamControllerApi;
  }

  get linearTVEStreamControllerApi() {
    if (!this._linearTVEStreamControllerApi || !this.ready) {
      logger.error('linearTVEStreamControllerApi: linearTVEStreamControllerApi is: ' + this._linearTVEStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._linearTVEStreamControllerApi;
  }

  get vodStreamControllerApi() {
    if (!this._vodStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: vodStreamControllerApi is: ' + this._vodStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._vodStreamControllerApi;
  }

  get vodTveStreamControllerApi() {
    if (!this._vodTveStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: vodTveStreamControllerApi is: ' + this._vodTveStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._vodTveStreamControllerApi;
  }

  get purchasedVodStreamControllerApi() {
    if (!this._vodTveStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: purchasedVodStreamControllerApi is: ' + this._purchasedVodStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._purchasedVodStreamControllerApi;
  }

  get recordingStreamControllerApi() {
    if (!this._vodTveStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: getRecordingStreamControllerApi is: ' + this._recordingStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._recordingStreamControllerApi;
  }

  get tveRecordingStreamControllerApi() {
    if (!this._tveRecordingStreamControllerApi || !this.ready) {
      logger.error('heartbeatApi: tveRecordingStreamControllerApi is: ' + this._tveRecordingStreamControllerApi +
    ' Rights Class Service ready is: ' + this.ready );
      return null;
    }
    return this._tveRecordingStreamControllerApi;
  }

  init( params = {} ) {
    if (!params.accessTokenResponse) {
      logger.error('Rights API: did not provide accessTokenResponse');
      return;
    }
    this._session = params.session;
    this._accessTokenResponse = params.accessTokenResponse;
    this._tokenSummary = this._session.tokenSummary || {};
    this._requestParams = {
      rightsRequestBody: {
        xsct: this._session.xsct
      }
    };
    this._apiConfig = {
      basePath: BASE_PATH,
      xsct: this._session.xsct,
      fetchApi: window.fetch.bind(window),
      accessToken: this._accessTokenResponse,
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    };
    this._ready = true;
    this._linearStreamControllerApi = this._getLinearStreamControllerApi();
    this._linearTVEStreamControllerApi = this._getLinearTVEStreamControllerApi();
    this._vodStreamControllerApi = this._getVodStreamControllerApi();
    this._vodTveStreamControllerApi = this._getVodTveStreamControllerApi();
    this._purchasedVodStreamControllerApi = this._getPurchasedVodStreamControllerApi();
    this._recordingStreamControllerApi = this._getRecordingStreamControllerApi();
    this._tveRecordingStreamControllerApi = this._getTveRecordingStreamControllerApi();
  }

  _isApiReady() {
    return this._accessTokenResponse && this.ready;
  }

  _getLinearStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_getLinearStreamControllerApi: did not provide tokens');
      return;
    }
    return new LinearStreamControllerApi(this._apiConfig);
  }

  _getLinearTVEStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_getLinearTVEStreamControllerApi: did not provide tokens');
      return;
    }
    return new LinearTVEStreamControllerApi(this._apiConfig);
  }

  _getVodTveStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_getVodTveStreamControllerApi: did not provide tokens');
      return;
    }
    return new VodTveStreamControllerApi(this._apiConfig);
  }

  _getVodStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_VodStreamControllerApi: did not provide tokens');
      return;
    }
    return new VodStreamControllerApi(this._apiConfig);
  }

  _getPurchasedVodStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_VodStreamControllerApi: did not provide tokens');
      return;
    }
    return new PurchasedVodStreamControllerApi(this._apiConfig);
  }

  _getRecordingStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_getRecordingStreamControllerApi: did not provide tokens');
      return;
    }
    return new RecordingStreamControllerApi(this._apiConfig);
  }

  _getTveRecordingStreamControllerApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.error('_getTveRecordingStreamControllerApi: did not provide tokens');
      return;
    }
    return new TveRecordingStreamControllerApi(this._apiConfig);
  }

  async putHeartbeatLinear(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      streamId: (watchable.channel || {}).streamId || watchable.streamId || watchable.listingId || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] putHeartbeatLinear using _linearStreamControllerApi:', paramObj);
    return await this._linearStreamControllerApi && this._linearStreamControllerApi.continueLinearStreamV1(paramObj);
  }

  async deleteHeartbeatLinear(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      streamId: (watchable.channel || {}).streamId || watchable.streamId || watchable.listingId || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] deleteHeartbeatLinear using _linearStreamControllerApi:', paramObj);
    return await this._linearStreamControllerApi && this._linearStreamControllerApi.stopLinearStreamV1(paramObj);
  }

  async putHeartbeatTVELinear(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      streamId: (watchable.channel || {}).streamId || watchable.streamId || watchable.listingId || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] putHeartbeatTVELinear using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.continueLinearTveStreamV1(paramObj);
  }

  async deleteHeartbeatTVELinear(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      streamId: (watchable.channel || {}).streamId || watchable.streamId || watchable.listingId || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] deleteHeartbeatTVELinear using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.stopLinearTveStreamV1(paramObj);
  }

  async putHeartbeatVod(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || '',
      includePurchases: !!watchable.isRental()
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] continueVodStreamV1 using _vodStreamControllerApi:', paramObj);
    return await this._vodStreamControllerApi && this._vodStreamControllerApi.continueVodStreamV1(paramObj);
  }

  async deleteHeartbeatVod(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] stopVodStreamV1 using _vodStreamControllerApi:', paramObj);
    return await this._vodStreamControllerApi && this._vodStreamControllerApi.stopVodStreamV1(paramObj);
  }

  async putHeartbeatTveVod(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] continueVodStreamV1 using _vodTveStreamControllerApi:', paramObj);
    return await this._vodStreamControllerApi && this._vodTveStreamControllerApi.continueVodTveStreamV1(paramObj);
  }

  async deleteHeartbeatTveVod(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] stopVodStreamV1 using _vodTveStreamControllerApi:', paramObj);
    return await this._vodStreamControllerApi && this._vodTveStreamControllerApi.stopVodTveStreamV1(paramObj);
  }

  async putHeartbeatPurchase(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] continuePurchasedVodStreamV1 using _purchasedVodStreamControllerApi:', paramObj);
    return await this._purchasedVodStreamControllerApi && this._purchasedVodStreamControllerApi.continuePurchasedVodStreamV1(paramObj);
  }

  async deleteHeartbeatPurchase(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      mediaGuid: watchable.mediaGuid || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] stopPurchasedVodStreamV1 using _purchasedVodStreamControllerApi:', paramObj);
    return await this._purchasedVodStreamControllerApi && this._purchasedVodStreamControllerApi.stopPurchasedVodStreamV1(paramObj);
  }

  async putHeartbeatRecording(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      recordingId: watchable.id || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] continueRecordingStreamV1 using _recordingStreamControllerApi:', paramObj);
    return await this._recordingStreamControllerApi && this._recordingStreamControllerApi.continueRecordingStreamV1(paramObj);
  }

  async deleteHeartbeatRecording(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      recordingId: watchable.id || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] stopRecordingStreamV1 using _recordingStreamControllerApi:', paramObj);
    return await this._recordingStreamControllerApi && this._recordingStreamControllerApi.stopRecordingStreamV1(paramObj);
  }

  async putHeartbeatTveRecording(options = {}) {
    const watchable = options.watchable || {};
    const paramObj = Object.assign(this._requestParams, {
      recordingId: watchable.id || ''
    }, options);
    senderDebugger.debugNetworkMessage('[XVP][API] continueRecordingTveStreamV1 using _tveRecordingStreamControllerApi:', paramObj);
    return await this._tveRecordingStreamControllerApi && this._tveRecordingStreamControllerApi.continueRecordingTveStreamV1(paramObj);
  }

  async startVodTveExternal(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] startVodTveExternalV1 using _vodTveStreamControllerApi:', paramObj);
    return await this._vodTveStreamControllerApi && this._vodTveStreamControllerApi.startVodTveExternalV1(paramObj);
  }

  async startLinearTveExternal(options = {}) {
    const paramObj = Object.assign(this._requestParams, options);
    senderDebugger.debugNetworkMessage('[XVP][API] startLinearTveExternalV1 using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.startLinearTveExternalV1(paramObj);
  }

  async canStreamTve(options = {}) {
    const serviceZone = options.serviceZoneType &&
      options.serviceZoneType.includes('zipcode') &&
      options.serviceZoneType + ':' + this._tokenSummary.servicePostalCode ||
      '';

    const paramObj = Object.assign(this._requestParams, {
      streamId: options.streamId,
      serviceZone,
      locatorType: options.locatorType,
      ...options
    });
    senderDebugger.debugNetworkMessage('[XVP][API] canStreamV1 using _linearTVEStreamControllerApi:', paramObj);
    return await this._linearTVEStreamControllerApi && this._linearTVEStreamControllerApi.canStreamV1(paramObj);
  }
}

export default new RightsAPI();
