const eventName = (namespace, name) => `${ namespace }:${ name }`;
const dispatches = (namespace) => (descriptor) => {
  return {
    ...descriptor,
    elements: [
      ...descriptor.elements,
      {
        key: 'dispatchEvent',
        kind: 'method',
        placement: 'prototype',
        descriptor: {
          value: function(name, detail) {
            const event = new CustomEvent(eventName(namespace, name), { detail });
            window.dispatchEvent(event);
          },
          configurable: true,
          enumerable: false,
          writable: true
        }
      },
      {
        key: 'addEventListener',
        kind: 'method',
        placement: 'prototype',
        descriptor: {
          value: function(name, handler, ...options) {
            window.addEventListener(eventName(namespace, name), handler, ...options);
          },
          configurable: true,
          enumerable: false,
          writable: true
        }
      },
      {
        key: 'removeEventListener',
        kind: 'method',
        placement: 'prototype',
        descriptor: {
          value: function(name, handler, ...options) {
            window.removeEventListener(eventName(namespace, name), handler, ...options);
          },
          configurable: true,
          enumerable: false,
          writable: true
        }
      },
      {
        key: 'addEventListenerCollection',
        kind: 'method',
        placement: 'prototype',
        descriptor: {
          value: function(listenerMap) {
            Object.keys(listenerMap).forEach((name) => {
              const listener = listenerMap[name];
              if (listener) {
                this.addEventListener(name, listener);
              }
            });
          },
          configurable: true,
          enumerable: false,
          writable: true
        }
      },
      {
        key: 'removeEventListenerCollection',
        kind: 'method',
        placement: 'prototype',
        descriptor: {
          value: function(listenerMap) {
            Object.keys(listenerMap).forEach((name) => {
              const listener = listenerMap[name];
              if (listener) {
                this.removeEventListener(name, listener);
              }
            });
          },
          configurable: true,
          enumerable: false,
          writable: true
        }
      }
    ]
  };
};

export { dispatches };
