class Logger {
  logLevels = {
    ALL: -2147483648,
    TRACE: 1000,
    INFO: 3000,
    WARN: 4000,
    ERROR: 5000,
    FATAL: 6000,
    NONE: 2147483647
  };
  constructor(label, labelStyle = {}) {
    this.label = label;
    this.labelCss = Object.entries({ fontWeight: 'bold', color: 'black', ...labelStyle }).map(([name, value]) => {
      const dashedName = name.replace(/[A-Z]/g, (ch) => '-' + ch.toLowerCase());

      return `${ dashedName }: ${ value }`;
    }).join(';') + ';';
  }

  log(message, ...args) {
    console.log(`%c[%s]%c ${ message }`, this.labelCss, this.label, '', ...args);
  }

  logBlock(message, blockFn) {
    console.groupCollapsed(`%c[%s]%c ${ message }`, this.labelCss, this.label, '');
    blockFn({
      log(message, ...args) {
        console.log(message, ...args);
      }
    });
    console.groupEnd();
  }

  debug(message, ...args) {
    console.debug(`%c[%s]%c ${ message }`, this.labelCss, this.label, '', ...args);
  }

  warn(message, ...args) {
    console.warn(`%c[%s]%c ${ message }`, this.labelCss, this.label, '', ...args);
  }

  error(message, ...args) {
    console.error(`%c[%s]%c ${ message }`, this.labelCss, this.label, '', ...args);
  }
}

export default Logger;

