import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import errors from 'constants/errors';
import adTypes from 'constants/ad-types';
import { checkHighBitrateSupport } from './device-detection';
/**
 * @module helpers
 */

export const createDeferred = () => {
  const deferred = {};

  deferred.promise = new Promise(function(resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
};

/**
 * Returns a bitrate value from bytes
 *
 * @function
 * @param {number} num bitrate value in bytes
 * @return {object} returns a bitrate number
 */
export const getBitrate = (num) =>{
  return Math.round(num / 1024);
};
/**
 * formats an array of bitrates as a human readable string
 * @param  {array} list Array of bitrate values
 * @return {string}     human readable string of bitrates
 */
export const getBitratesAsString = (list) => {
  if (!Array.isArray(list)) {
    return list;
  }
  return (list || []).map((num) => {
    return Math.round(num / 1024);
  }).join(', ');
};
/**
 * Return time as a human-friendly string
 *
 * @function
 * @param {number} milliseconds - Time to format in milliseconds
 * @return {string}
 */
export const formatTime = (milliseconds) => {
  if (typeof milliseconds === 'undefined') {
    return '0:00';
  }

  if (typeof milliseconds === 'string') {
    milliseconds = new Date(milliseconds).getTime();
  }

  if (isNaN(milliseconds)) {
    return '0:00';
  }

  const rawSeconds = Math.round(milliseconds / 1000);
  const seconds = rawSeconds % 60;
  const minutes = Math.floor(rawSeconds % 3600 / 60);
  const hours = Math.floor(rawSeconds / 3600);

  const displayList = hours ? [hours, minutes, seconds] : [minutes, seconds];

  return displayList
    .map((value, index) => index === 0 ? value : String(value).padStart(2, '0'))
    .join(':');
};

/**
 * Format a timestamp for display as a time
 *
 * @function
 * @param {number} timestamp - Timestamp to format
 * @return {string}
 */
export const formatTimeOfDay = (timestamp) => {
  if (typeof timestamp === 'string') {
    timestamp = new Date(timestamp).getTime();
  }
  return dayjs(timestamp).format('h:mma').replace(/m$/, '');
};

/**
 * Find if the current time is between given times
 *
 * @function
 * @param {number} start - Timestamp to format
 * @param {number} end - Timestamp to format
 * @return {Boolean}
 */
export const timeIsBetween = (start, end) => {
  dayjs.extend(isBetween);
  return dayjs().isBetween(start, end);
};

/**
 * Query an object for a nested property without worrying about whether it exists
 *
 * @function
 * @param {object} object - Object to inspect.
 * @param {object} property - Path separated by 0 or more "."
 * @return {object} Returns the value or undefined if the path does not exist
*/
export const getProperty = (object, property) => {
  if (object && typeof object === 'object') {
    if (typeof property === 'string' && property !== '') {
      return property.split('.').reduce(function(obj, prop) {
        return obj && obj[prop];
      }, object);
    }
    return object;
  }

  return object;
};

/**
 * Returns a pixel value scaled for the screen size. 1080p is the base screen
 * size, so a 720p pixel will be smaller and a 4k pixel will be larger.
 *
 * @function
 * @param {number} pixels - Pixel value to convert
 * @return {number} Pixel value rescaled based on window dimensions
 */
export const scaledPixel = (pixels) => pixels * window.innerHeight / 1080;

/**
 * Builds a srcset attribute value based on URL-to-scale mapping
 *
 * The `srcSetDef` param has keys that are the device pixel scale values such
 * as `'1x'`, `'1.5x'`, etc. The values for those keys are the image URLs
 *
 * @example
 * buildSrcSet({
 *   '1x': 'http://example.com/67x33-pixel-image.png',
 *   '1.5x': 'http://example.com/100x50-pixel-image.png',
 *   '3x': 'http://example.com/200x100-pixel-image.png'
 * })
 *
 * @function
 * @param {object} srcSetDef - Image src URL mapping
 * @return {string} srcset attribute value
 */
export const buildSrcSet = (srcSetDef) => Object.keys(srcSetDef).map((scale) => {
  return `${ srcSetDef[scale] } ${ scale }`;
}).join(',\n');

export const generateUUID = () => {
  let d = Date.now();
  let r;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};

/**
 * Lookup an error object based on error code parts
 *
 * This will return an error object based on {@link constants.errors} using the
 * values passed in as `parts`
 *
 * @function
 * @param {string[]} parts - List of error code parts for lookup
 * @return {object} Error object that can be used with {@link cast-error-message}
 */
export const lookupError = (parts) => {
  let fullCode = null;
  return parts.reduce((details, part) => {
    fullCode = [fullCode, part].filter(Boolean).join('.');
    return { ...details, ...errors[fullCode] };
  }, errors.generic);
};


/**
 *  query PP if an advertisement is playing
 *  @function
 *  @param {object} playerPlatformApi - running instance of PlayerPlaformAPI
 *  @return {boolean}
 */
export const isAdPlaying = (playerPlatformApi) => {
  return playerPlatformApi && playerPlatformApi.adManager && playerPlatformApi.adManager.isAdPlaying();
};

/**
 * returns array of ad break objects
 * @function
 * @param {object} playerPlatformApi - running instance of PlayerPlaformAPI
 * @param {boolean} stripId - strip the id string from ad objects from PP
 * @return {array} - array of ad break objects
 */
export const getAdBreaks = (playerPlatformApi, stripId) => {
  const timeline = playerPlatformApi && playerPlatformApi.getTimeline() || [];
  if (!stripId) {
    return timeline;
  }
  return timeline.map((ad) => {
    return ad.ads.map((ad) => {
      if (ad['id']) {
        delete ad['id'];
      }
      return ad;
    });
  });
};

/**
 * return the nested object
 * @function
 * @param {string} name - name of the property,
 * @param {object} properties - values to be nested
 * @return {object} - name : { properties: value}
 */
export const toNest = (name, properties) => {
  return {
    [name]: {
      ...properties
    }
  };
};

/**
 * Will calculte the correct position during an ad break
 * Position is found by the previous ads combined duration and adding the current position
 * @function
 * @param {array} ads - array of ads in an ad break
 * @param {number} currentAdsShown - the current number ad being shown
 * @param {number} position - the current known playhead position in the ad
 * @return {number} - the position in the total ad break
 */
export const getAdBreakPosition = ({ ads, currentAdsShown, position }) =>{
  const totalBreakPosition = ads.reduce((result, ad, adIndex) => {
    if (adIndex < currentAdsShown) {
      result += ad.duration;
    }
    return result;
  }, 0);

  return totalBreakPosition + position;
};

/**
 * Returns a new object copying Player Platform's video ad break object
 * JSON is unable to stringify pp getters on these properties
 * @param  {object} videoAdBreak player platform video ad break
 * @return {object} videoAdBreak Copy of player platform video ad break
 */
export const getVideoAdBreakObject = ({ videoAdBreak }) => {
  const adBreak = Object.assign({}, videoAdBreak, {
    adCount: videoAdBreak.adCount || 0,
    startTime: videoAdBreak.startTime || 0,
    duration: videoAdBreak.duration || 0,
    endTime: videoAdBreak.endTime || 0,
    watched: videoAdBreak.watched || false

  });
  adBreak.ads.forEach((ad, index) => {
    ad.index = index;
  });

  return adBreak;
};
/**
 * Returns a new object copying Player Platform's timelineData object
 * JSON is unable to stringify pp getters on these properties
 * @param  {object} timelineAdBreaks - array of ad breaks
 * @return {object} trimmedData copy of pp timelineData
 */
export const getTrimmedTimeline = (timelineAdBreaks) => {
  const trimmedData = [];
  timelineAdBreaks && timelineAdBreaks.forEach((videoAdBreak) => (
    trimmedData.push({
      startTime: videoAdBreak.startTime || 0,
      duration: videoAdBreak.duration || 0,
      endTime: videoAdBreak.endTime || 0
    })
  ));

  return trimmedData;
};

export const isAdSeekable = (videoAd, adType) => {
  const seekable = (videoAd || {}).seekable || false;
  if (adType === adTypes.freewheel) {
    return (videoAd || {}).watched;
  }
  return seekable && adType === adTypes.manifestManipulator;
};

export const getMaxBitrateForDevice = (deviceCapabilities, configMaxBitrate) => {
  const tier1 = checkHighBitrateSupport();
  /**
   * We need to limit the resolution to 720p for gen1, home hub and all non hdr devices.
   * PP doesn't have an API to control the resolution for now.
   * So we need to keep the max bitrate to 2200kb, as that can limit the resolution under 720p.
   */
  return tier1 ? configMaxBitrate : 2200 * 1024;
};

/**
 * Create a comparison function for use with Array.sort
 *
 * This creates a function to pass to Array.sort that will sort a list of
 * objects by multiple properties. Each `sort` provided is an Array with
 * two elements: the property to sort by and the direction to sort.
 *
 * Each sort will be tested in turn. If the two properties are equal, then the
 * next sort will be attempted and so on until a difference is found.
 *
 * For boolean values, an ascending sort (direction = 1) will sort with
 * `false` values first. Descending sort will put `true` values first.
 *
 * @example <caption>Sort by `isHD` (HD first), then `contentProvider.name` in alphabetical order</caption>
 * const comparisonFn = propertySort(
 *   ['isHD', -1],
 *   ['contentProvider.name', 1]
 * );
 * myArray.sort(comparisonFn);
 *
 * @function
 * @param {...Array} sorts - Sort definitions
 * @param {string} sorts[].0 - Property to sort by
 * @param {number} sorts[].1 - Direction to sort. `1` = ascending, `-1` = descending
 * @return {function}
 */
export const propertySort = (...sorts) => (a, b) => {
  return sorts.reduce((result, [prop, direction]) => {
    if (result) {
      return result;
    }

    const aProp = getProperty(a, prop);
    const bProp = getProperty(b, prop);

    if (aProp === bProp) {
      return 0;
    }

    return (aProp > bProp ? 1 : -1) * direction;
  }, 0);
};

const throttlerObj = {};
/**
 * Can be used to throttle any function, by reducing the calls with the delay we give.
 * @function
 * @param {string} throttler - name of the throttle var
 * @param {functiom} callback - the function to be throttled
 * @param {number} delay - the delay you want to give between each calls
 */

export const throttle = (throttler, callback, delay) => {
  if (!throttlerObj[throttler]) {
    callback.call();
    throttlerObj[throttler] = true;
    setTimeout(() => throttlerObj[throttler] = false, delay);
  }
};

export const generateMyriadUrls = ({
  entityId,
  type = 'image'
}) => {
  const scales = {
    '1x': {
      logo: { width: 67, height: 33 }
    },
    '1.5x': {
      logo: { width: 100, height: 50 }
    },
    '3x': {
      logo: { width: 200, height: 100 }
    }
  };
  const urlSet = [];

  Object.keys(scales).forEach((scale)=> {
    const url = new URL(`https://edge.myriad-gn-xcr.xcr.comcast.net/select/${type}`);
    url.searchParams.set('entityId', entityId);
    url.searchParams.set('width', scales[scale][type]['width']);
    url.searchParams.set('height', scales[scale][type]['height']);
    url.searchParams.set('gravity', 'SouthWest');
    url.searchParams.set('extent', 'true');
    urlSet.push(`${ url.toString() } ${ scale }`);
  });

  return urlSet.join(',\n');
};

export const generateMyriadUrlForPoster = (entityId, { width, height, rule }) => {
  const url = new URL('https://edge.myriad-gn-xcr.xcr.comcast.net/select/image');

  url.searchParams.set('entityId', entityId.toString());
  url.searchParams.set('width', width.toString());
  url.searchParams.set('height', height.toString());
  url.searchParams.set('rule', rule);

  return url.toString();
};

export const selectVchipRating = (ratings)=> {
  if (!ratings.length) {
    return;
  }

  return ratings.find((rating) => rating.scheme.includes('v-chip'));
};
