import playerEvents from '../constants/player-events';
import { getProperty } from '../lib/helpers';
import splunkTypes from '../constants/splunk-types';

class PlayerErrorHandler {
  constructor() {
    this.api = {};
    this.imageDownloadCompleted = true;
    this.imageDownloadRequested = false;
    this.isOfflineErrorActive = false;
    this.player = {};
    document.body.ononline = this.confirmOnlineStatus.bind(this);
    document.body.onoffline = this.confirmOnlineStatus.bind(this);
  }
  onPlayerReady(_player) {
    this.player = _player;
    this.api = _player.api;
  }

  async handleMediaError(eventType, ppEvent) {
    if (this.player.error && this.isOfflineErrorActive) {
      return;
    }
    let error = {
      isMidStream: this.player.isMidStream,
      loggingInfo: this.player.loggingInfo,
      module: 'Video',
      watchable: this.player.watchable
    };

    switch (eventType) {
      case playerEvents.mediaKeyStatus:
        error = {
          ...error,
          code: 'outputRestricted',
          eventType: splunkTypes.error,
          fatal: false,
          msg: (getProperty(ppEvent, 'error.description') || getProperty(ppEvent, 'error.desc') || ppEvent.description || '').toLowerCase(),
          subCode: ''
        };
        break;
      case playerEvents.mediaFailed:
        error = {
          ...error,
          code: getProperty(ppEvent, 'error.major') || ppEvent.code,
          eventType: splunkTypes.error,
          fatal: true,
          msg: (getProperty(ppEvent, 'error.description') || getProperty(ppEvent, 'error.desc') || ppEvent.description || '').toLowerCase(),
          subCode: ppEvent.subCode || getProperty(ppEvent, 'error.minor')
        };
        break;
      case playerEvents.mediaRetry:
        error = {
          ...error,
          code: getProperty(ppEvent, 'data.error.major') || ppEvent.code,
          eventType: splunkTypes.Retry,
          fatal: false,
          msg: (getProperty(ppEvent, 'data.error.description') || getProperty(ppEvent, 'data.error.desc') ||
            ppEvent.description || '').toLowerCase(),
          subCode: getProperty(ppEvent, 'data.error.minor') || ppEvent.subCode
        };
        break;
    }

    const offlineDetected = await this._isOfflineDetected(eventType, ppEvent);

    if (this._isAutoPlayBlocked(error)) {
      if (this.api.getPlayerStatus() !== 'playing') {
        this.api.play();
      }
      this.player.dispatchEvent(playerEvents.playStateChanged, {
        playState: 'playing'
      });

      error = {
        ...error,
        fatal: false
      };
      eventType = playerEvents.mediaRetry;
    } else if (this._isEspnOnAuthError(error) || this._isDisneyOnAuthError(error)) {
      error = {
        ...error,
        fatal: false
      };
      eventType = playerEvents.mediaRetry;
      this.player.restartPlayback();
    } else if (offlineDetected) {
      error = {
        ...error,
        eventType: splunkTypes.error,
        fatal: false,
        msg: 'Playback stopped because of no internet access',
        offlineDetected: true
      };
      eventType = playerEvents.mediaFailed;
      this.imageDownloadCompleted = true;
      this.imageDownloadRequested = false;
      this.isOfflineErrorActive = true;
    }

    error = {
      ...error,
      subCode: error.subCode === 0 ? String(error.subCode) : error.subCode
    };

    this.player.error = error;
    this.player.dispatchEvent(eventType, error);
  }

  _isEspn8300Error(error) {
    return Number(error.code) === 8300 && (!error.subcode || [403, 0].includes(Number(error.subCode))) &&
      this.api.getAssetEngineType() === 'espn5';
  }

  _isEspnOnAuthError(error) {
    return this._isEspn8300Error(error) && error.msg.includes('token');
  }

  _isDisneyOnAuthError(error) {
    return String(error.code) === '8500' && ['1', '1002'].includes(String(error.subCode)) &&
      this.api.getAssetEngineType() === 'disney5';
  }

  _isAutoPlayBlocked(error) {
    return Number(error.code) === 15 && !(error.subCode);
  }

  async _isOfflineDetected(eventType, ppEvent) {
    const exceededThirdRetry = (eventType === playerEvents.mediaRetry) && (Number(ppEvent.retryCount) >= 3);
    const mediaFailedEvent = eventType === playerEvents.mediaFailed;

    if (!this.imageDownloadRequested) {
      this.imageDownloadRequested = true;
      this.imageDownloadCompleted = false;
      this.downloadImage().then( ()=> this.imageDownloadCompleted = true );
      /**
       * We cannot wait till the downloadImage() is failed as it may take around ~1 minute if it's a network timeout.
       * So to cover direct media_failed events, giving a timeout of 6sec for the image to be downloaded.
       * Which should be sufficient for a 200kb JPG on an average network.
      */
      if (mediaFailedEvent) {
        await new Promise((resolve) => setTimeout(resolve, 6e3));
      }
    }
    return (mediaFailedEvent || exceededThirdRetry) && this.imageDownloadRequested && !this.imageDownloadCompleted;
  }

  downloadImage() {
    const imageAddress = '/sample-200KB.jpg?n=' + Math.random();
    return new Promise(function(resolve, reject) {
      const downloadImg = new Image();
      downloadImg.src = '';
      downloadImg.onload = function() {
        resolve('Image downloaded successfully!');
      };
      downloadImg.onerror = function() {
        reject(new Error('Failed to load image!'));
      };
      downloadImg.src = imageAddress;
    });
  }
  confirmOnlineStatus() {
    if (!['error', 'idle'].includes(this.api.getPlayerStatus()) || !navigator.onLine || !this.player.watchable) return;
    this.downloadImage()
      .then( ()=> this.player.restartPlayback() );
  }

  reset() {
    this.imageDownloadCompleted = true;
    this.imageDownloadRequested = false;
    this.isOfflineErrorActive = false;
    this.player.error = null;
  }
}

export default new PlayerErrorHandler();
