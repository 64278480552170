/**
 * Log levels for sender-logger
 * @readonly
 * @enum {number}
 * @namespace constants.logLevels
 */
export default {
  ALL: -2147483648,
  TRACE: 1000,
  INFO: 3000,
  WARN: 4000,
  ERROR: 5000,
  FATAL: 6000,
  NONE: 2147483647
};
