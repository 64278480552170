/**
 * Constants for ad Manager keys
 *
 * @namespace constants.adManager
 */
export default {
  NONE: 'none',
  MANIFEST: 'manifest',
  TVELINEAR: 'tvelinear',
  FREEWHEELVOD: 'freewheelvod',
  FREEWHEELINTIMELINE: 'freewheelintimeline'
};
