import Logger from 'lib/logger';
import { formatTime, getAdBreaks, getBitratesAsString, getBitrate, isAdPlaying } from 'lib/helpers';
import castMessenger from 'cast-messenger';
import config from 'config';
import messageTypes from 'constants/message-types';
import playerEvents from 'constants/player-events';


const receiverAppId = () => {
  try {
    return window.cast.framework.CastReceiverContext.getInstance().getApplicationData().id;
  } catch (err) {
    return 'UNKNOWN';
  }
};
const logger = new Logger('DEBUG_SENDER', { background: 'deepskyblue' });

/**
 * @module debugSender
 * @desc Prepares and sends data to the sender to display a receiver debug data
 */
class DebugSender {
  debugData = {};
  debugInterval = 1000;
  debugSendTimer = 0;
  mediaProgress = 0;
  playerplatformAdEvents = [];
  playerPlatformApi = null;
  playerplatformEvents = [];
  bitrateData = {
    type: '',
    changeReason: '',
    bitrate: 0,
    height: 0,
    width: 0
  };

  error = { major: 0, minor: 0 };

  constructor(interval) {
    this.debugInterval = interval;
  }
  startTimer() {
    this.debugSendTimer = setInterval(() => {
      castMessenger.broadcastMessage(messageTypes.debugSender, { debugData: this.debugData });
      logger.log('senderDebug Debug Data sent', this.debugData);
    }, this.debugInterval);
  }

  stopTimer() {
    clearInterval(this.debugSendTimer);
  }

  playerReady(player) {
    const ppEvents = player.ppEvents;

    this.player = player;
    this.playerPlatformApi = player.api;
    this.playerplatformEvents = [];
    this.playerPlatformApi.addEventListener(ppEvents.MEDIA_PROGRESS, ( detail ) => {
      this.mediaProgress = detail.position;
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.MEDIA_OPENED, ( detail ) => {
      this.playerplatformEvents.push(detail.type);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.PLAY_STATE_CHANGED, ( detail ) => {
      this.playerplatformEvents.push(detail.playState);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.PLAYBACK_STARTED, ( detail ) => {
      this.playerplatformEvents.push(detail.type);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.MEDIA_FAILED, ( detail ) => {
      this.playerplatformEvents.push(detail.type);
      this.updateDebugData();
    });

    this.playerPlatformApi.addEventListener(ppEvents.AD_BREAK_START, ( detail ) => {
      this.playerplatformAdEvents.push(detail.type);
      this.playerplatformEvents.push(detail.type);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.AD_BREAK_COMPLETE, ( detail ) => {
      this.playerplatformAdEvents.push(detail.type);
      this.playerplatformEvents.push(detail.type);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.AD_START, ( detail ) => {
      this.playerplatformAdEvents.push(detail.type);
      this.updateDebugData();
    });
    this.playerPlatformApi.addEventListener(ppEvents.AD_COMPLETE, ( detail ) => {
      this.playerplatformAdEvents.push(detail.type);
      this.updateDebugData();
    });

    player.addEventListener(playerEvents.bitrateChange, ({ detail }) => {
      this.bitrateData = { ...detail };
      this.updateDebugData();
    });

    logger.logBlock('PLAYER READY', (logger) => {
      logger.log('Player Ready ', this.debugData);
    });
    return this;
  }

  updateDebugData() {
    this.debugData = {
      adBreakCount: getAdBreaks(this.playerPlatformApi).length,
      adConfig: JSON.stringify((((this.playerPlatformApi || []).asset || {}).contentOptions || {}).adConfig),
      assetDuration: formatTime(this.playerPlatformApi.getDuration()),
      adEvents: this.playerplatformAdEvents.join(' -> '),
      adPlaying: isAdPlaying(this.playerPlatformApi),
      assetEngine: this.playerPlatformApi.getAssetEngineType(),
      assetType: this.playerPlatformApi.getAssetType(),
      bitrateAvailable: getBitratesAsString(this.playerPlatformApi.getAvailableBitrates()),
      bitrateData: this.bitrateData,
      bufferTime: formatTime(this.playerPlatformApi.getBufferTime()),
      bufferFilledLength: formatTime(this.playerPlatformApi.getBufferFilledLength()),
      currentBitrate: getBitrate(this.playerPlatformApi.getCurrentBitrate()),
      currentFPS: this.playerPlatformApi.getCurrentFPS(),
      deployment: config.deployment,
      duration: this.playerPlatformApi.getDuration(),
      width: this.playerPlatformApi.getVideoWidth(),
      height: this.playerPlatformApi.getVideoHeight(),
      partner: config.partner,
      playerPlatformVersion: this.playerPlatformApi.getVersion(),
      // language: i18n.language,
      playerStatus: this.playerPlatformApi.getPlayerStatus(),
      progress: this.mediaProgress,
      playerPlatformEvents: this.playerplatformEvents.join(' -> '),
      receiverAppId: receiverAppId(),
      videoDimensions: { width: this.playerPlatformApi.getVideoWidth() === -1 ? '?' : this.playerPlatformApi.getVideoWidth(),
        height: this.playerPlatformApi.getVideoHeight() === -1 ? '?' : this.playerPlatformApi.getVideoHeight() },
      videoType: this.playerPlatformApi.getVideoType(),
      watchable: this.player.watchable
    };
  }
}
export { DebugSender };
