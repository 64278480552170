import api from 'api';
import BaseModel from 'model/base';
import CreativeWorkModel from 'model/creative-work';
import StreamModel from 'model/stream';
import watchableTypes from '../constants/watchable-types';
import { timeIsBetween } from '../lib/helpers';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import productContextTypes from '../constants/product-context-types';
import featuresTypes from '../constants/xvp-ads-types';
import XVP from 'lib/xvp';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
const getStreams = async (resource) => {
  const xtvApiStreams = resource.hasEmbedded ? (resource.getEmbedded('streams') || resource.getEmbedded('stream')) : null;
  const streams = xtvApiStreams ? xtvApiStreams : resource.streams || (resource.channel && resource.channel.streams);

  if (streams) {
    return {
      streams: await Promise.all(streams.map(StreamModel.fromResource))
    };
  }
};

const types = [];

/**
 * Base class for watchable types
 */
class WatchableModel extends BaseModel {
  static async _propertiesFromResource(resource) {
    const props = resource.getProps ? resource.getProps() : resource._resource || resource;
    const [inheritedProps, streams] = await Promise.all([
      super._propertiesFromResource(resource),
      getStreams(resource)
    ]);

    // XVP now has a case where the _type prop can be found in the channel object
    const fallBackType = inheritedProps._resource && inheritedProps._resource.channel ? inheritedProps._resource.channel._type : undefined;

    return {
      ...inheritedProps,
      ...streams,
      accountName: props.accountName,
      _type: props._type ? props._type : fallBackType,
      adBrand: props.adBrand,
      auditudeId: props.auditudeId,
      contentProvider: props.contentProvider,
      duration: props.duration,
      mediaGuid: props.mediaGuid,
      mediaId: props.mediaId,
      providerId: props.providerId,
      streamId: props.streamId,
      castInfo: props.castInfo,
      restrictStreaming: props.restrictStreaming || [],
      trickModesRestricted: props.trickModesRestricted,
      ivod: props.ivod || false
    };
  }

  static addType(typeClass, matcher) {
    types.push({ typeClass, matcher });
  }

  static async fromResource(resource) {
    const props = resource.getProps ? resource.getProps() : resource._resource || resource;
    const type = types.find(({ matcher }) => matcher(props, resource));

    if (!type) {
      throw new Error('No type found for watchable');
    }

    const TypeClass = type.typeClass;

    return new TypeClass(await TypeClass._propertiesFromResource(resource));
  }

  static async fromUrl(url) {
    const response = await api.fetch(url);
    return WatchableModel.fromResource(response.data);
  }

  findPlayableStream() {
    // TODO: Confirm this is the correct logic
    const playableStreams = this.getLinearProp('streams') || [];
    return playableStreams.length && playableStreams[0];
  }

  getErrorCategory() {
    if (this.isLinear() || this.isOTTInProgress()) {
      return 'linear';
    } else if (this.isRecording()) {
      return 'recording';
    } else if (this.isAnyVod()) {
      return 'vod';
    }
  }

  async getExternalPlayerResource(streamIdOverride) {
    let response = {};
    if (XVP.getFeature(featuresTypes.xvpHeartbeats)) {
      const playableStream = this.findPlayableStream() || {};
      const xvpParams = {
        endPoint: 'startLinearTveExternal',
        provider: playableStream.provider || '',
        streamId: this.streamId || (this.channel || {}).streamId || '',
        externalStreamId: encodeURIComponent(playableStream.externalStreamId || '')
      };

      try {
        response = await XVP.send(xvpParams);
        senderDebugger.debugNetworkMessage('[XVP][RIGHTS] startLinearTveExternal response: '+JSON.stringify(response));
      } catch (error) {
        senderDebugger.debugErrorMessage('[XVP][RIGHTS] startLinearTveExternal ERROR: '+JSON.stringify(error));
      }
    } else {
      const apiParams = {
        endpoint: 'startExternalTveLinearStream',
        params: {
          streamId: streamIdOverride || this.getLinearProp('streamId')
        },
        suppressLogging: true
      };

      try {
        response = await api.send(apiParams);
        response = response.resource.getProps();
      } catch (error) {
        senderDebugger.debugErrorMessage('[XTVAPI] startExternalTveLinearStream ERROR: '+JSON.stringify(error));
        return {};
      }
    }
    return response;
  }

  async getExternalOttStream(locator) {
    let response = {};
    if (XVP.getFeature(featuresTypes.xvpHeartbeats)) {
      const xvpParams = {
        endPoint: 'startVodTveExternal',
        mediaLocator: locator
      };
      try {
        response = await XVP.send(xvpParams);
        senderDebugger.debugNetworkMessage('[XVP][RIGHTS] startVodTveExternal response: '+JSON.stringify(response));
      } catch (error) {
        senderDebugger.debugErrorMessage('[XVP][RIGHTS] startVodTveExternal ERROR: '+JSON.stringify(error));
      }
    } else {
      try {
        response = await api.send({
          endpoint: 'startExternalTveVodStream',
          params: {
            mediaId: locator,
            mediaLocator: locator
          },
          suppressLogging: true
        });
        response = response.resource.getProps();
      } catch (error) {
        senderDebugger.debugErrorMessage('[XTVAPI] startExternalTveVodStream ERROR: '+JSON.stringify(error));
      }
    }
    return response;
  }

  async loadCreativeWork() {
    if (this.creativeWork) {
      return;
    }
    senderDebugger.sendDebugMessage('[WATCHABLE][loadCreativeWork()] 1 ',
      {
        watchable: JSON.stringify(this.creativeWork, getCircularReplacer(), 4)
      } );
    const hasResourceFuncs = this._resource && this._resource.hasAction && this._resource.hasEmbedded;

    senderDebugger.sendDebugMessage('[WATCHABLE][loadCreativeWork()] 2 ',
      {
        hasResourceFuncs: hasResourceFuncs,
        hasAction: !!this._resource.hasAction,
        hasEmbedded: !!this._resource.hasEmbedded
      } );


    if (hasResourceFuncs) {
      if (!this._resource.hasEmbedded('encodesCreativeWork')
      && !this._resource.hasAction('encodesCreativeWork')) {
        return;
      }
      const response = await this._resource.fetchEmbedded('encodesCreativeWork');
      this.creativeWork = await CreativeWorkModel.fromResource(response.data);
      if (!this.creativeWork._resource) {
        this.creativeWork._resource = response;
      }
    }
  }

  async canStream() {
    const playableStream = this.findPlayableStream();
    const streamId = (playableStream && playableStream.streamId) || this.streamId || '';
    const mediaId = (playableStream && playableStream.mediaId) || this.mediaId || '';

    const canStream = await api.send({
      endpoint: 'canStream',
      params: {
        streamId,
        mediaId
      }
    });

    return canStream;
  }

  async streamCheck({ sourceStreamId, signalId, zipCode }) {
    const streamCheckResponse = await api.send({
      endpoint: 'streamCheck',
      params: {
        virtualStreamId: this.channel.streamId || this.streamId,
        streamId: sourceStreamId,
        signalId,
        zipCode,
        deviceType: 'urn:scte:224:audience:Device:COMPUTER'
      }
    });

    return streamCheckResponse.resource.getProp('isValid');
  }

  /**
   * @return {'linearTve' | 'linearT6' |  'cdvr' | 'purchase' | 'tveVod' | 't6Vod' | 'iVod' }
   * */
  getAssetType() {
    const isLinear = this.isLinear();
    const isTve = this.isTve() || this.isLinearTve() || false;
    const isAnyVod = this.isAnyVod();

    switch (true) {
      case this.isRecording():
        return 'cdvr';
      case this.isIvod():
        return 'iVod';
      case this.isPurchase():
        return 'purchase';
      case isLinear && isTve:
        return 'linearTve';
      case isLinear && !isTve:
        return 'linearT6';
      case isAnyVod && isTve:
        return 'tveVod';
      case isAnyVod && !isTve:
        return 't6Vod';
    }
  }

  getTypeLabel() {
    const streamProviderMapping = {
      'CBS': 'CBS',
      'disney': 'Disney',
      'espn': 'ESPN',
      'HULU': 'Hulu',
      'nbc': 'nbc'
    };

    const isEspnOtt = this.isOTT() && /espn/ig.test((this.findPlayableStream() || {}).contentUrl);
    const streamProvider = (this.findPlayableStream()|| {}).streamProvider;
    const isExternalPlayer = Object.keys(streamProviderMapping).some(function(provider) {
      return streamProvider === provider;
    });

    if (isEspnOtt) {
      return 'OTT';
    } else if (this.isFastLane(this)) {
      return 'FAST';
    } else if (isExternalPlayer) {
      return streamProviderMapping[streamProvider];
    } else if (this.isIvod(this)) {
      return 'IVOD';
    } else if (this.isTveListing(this)) {
      return 'TVE LINEAR';
    } else if (this.isListing(this) && !this.isTveListing(this)) {
      return 'T6 LINEAR';
    } else if (this.isPurchase(this)) {
      return 'PURCHASE';
    } else if (this.isRental(this)) {
      return 'RENTAL';
    } else if (this.isRecording(this)) {
      return 'RECORDING';
    } else if (this.isVod(this)) {
      return 'T6 VOD';
    } else if (this.isTve(this)) {
      return 'TVE VOD';
    }
  }
  isTve() {
    const isTve = this._type === watchableTypes.Tve;
    /*
    senderDebugger.sendDebugMessage(`[WATCHABLE][CHANNEL]
    [isTve] type: ${JSON.stringify(this._type, getCircularReplacer(), 4)}`, {
      isTve: isTve,
      watchableAttempt: JSON.stringify(this, getCircularReplacer(), 4)
    });
    */
    return isTve;
  }

  isTveRecording() {
    return this.isRecording() && (this.isTveFlag || (this.channel && this.channel.isTveFlag));
  }

  isTvSeries() {
    return this._type === watchableTypes.TvSeries;
  }

  isTveChannel() {
    return this.isChannel() && !!(this.isTveFlag || (this.channel && this.channel.isTveFlag));
  }

  isTveListing() {
    return this.isListing() && !!(this.channel && this.channel.isTveChannel());
  }
  isListing() {
    return this._type === watchableTypes.Listing || this._type === watchableTypes.ListingDetail;
  }
  isAnyTve() {
    return this.isLinearTve() || this.isTve();
  }
  getLinearProp(prop) {
    return this[prop] || (this.channel || {})[prop];
  }

  isCastable() {
    return !!(this.getLinearProp('castInfo') || {}).chromecast;
  }

  isAnyVod() {
    return this.isPurchase() || this.isVod() || this.isTve();
  }

  isChannel() {
    const isChannel = this._type === watchableTypes.Channel;
    /*
    senderDebugger.sendDebugMessage(`[WATCHABLE][CHANNEL]
    [isChannel] type: ${this._type}`, {
      isChannel: isChannel,
      outOfHomeChannelId: this.outOfHomeChannelId
    });
    */
    return isChannel;
  }

  isExternalStream() {
    const stream = this.findPlayableStream() || {};
    return stream._type === watchableTypes.ExternalStream || stream.isExternal === true;
  }

  isLinear() {
    return this.isChannel() || this.isListing();
  }

  isLinearTve() {
    return this.isTveChannel() || this.isTveListing();
  }

  isLinearT6() {
    return this.isLinear() && !this.isLinearTve();
  }

  isExternalOTT( streamType ) {
    return this.isExternalStream() && ( this.findPlayableStream() || {} ).streamProvider === streamType;
  }

  isFastLane() {
    // Xumo is the first and only 'FAST' lane at the moment.
    return this.isXumoStream();
  }

  isXumoStream() {
    return /xumo/ig.test((this.findPlayableStream() || {}).streamId) ||
    /xumo/ig.test((this.findPlayableStream() || {}).externalStreamId);
  }

  isOTT() {
    return this._type === watchableTypes.Ott || /ott-stream/.test((this.findPlayableStream() || {}).contentUrl);
  }

  isOTTInProgress() {
    return this.isOTT() && this.liveWindowEndTime &&
    timeIsBetween(this.startTime, this.endTime);
  }

  isTransactional() {
    return !!this.transactionDetails;
  }

  isPurchase() {
    return this._type === watchableTypes.Purchase;
  }

  isRental() {
    return this.isTransactional() && !this.isPurchase();
  }

  isRecording() {
    return this._type === watchableTypes.Recording;
  }

  isRecordingInProgress() {
    return this.isRecording() && timeIsBetween(this.dateRecordedTimestamp, Number(this.dateRecordedTimestamp) + Number(this.duration));
  }


  isRestartable() {
    let isCurrentlyLive = false;

    if (this.isListing()) {
      return false;
    }
    if (this.liveWindowEndTime) {
      isCurrentlyLive = timeIsBetween(this.startTime, this.liveWindowEndTime);
    }

    return this.findPlayableStream().restartable && !isCurrentlyLive;
  }

  isVod() {
    return this._type === watchableTypes.Vod;
  }

  isIvod() {
    return this.ivod;
  }

  isGeoFenced() {
    const linearStream = this.isLinear() && this.findPlayableStream();

    if (linearStream) {
      return linearStream.streamType === 'GeoFenced' || !!linearStream.geofenced;
    }

    return this.hasOwnProperty('geofenced') && this.geofenced;
  }

  isFFRestricted() {
    return /ff/i.test(this.trickModesRestricted);
  }

  isRWRestricted() {
    return /rw/i.test(this.trickModesRestricted);
  }

  isPauseRestricted() {
    return /pause/i.test(this.trickModesRestricted);
  }

  hasTveContextType() {
    const productContexts = this.productContexts ||
      (this.channel || {}).productContexts ||
      ((this.channel || {}).station || {}).productContexts ||
      [];

    if (!productContexts.length) {
      return false;
    }

    return !!productContexts.find((context) => {
      return context.type === productContextTypes.tve;
    });
  }

  hasT6ContextType() {
    const productContexts = this.productContexts ||
      (this.channel || {}).productContexts ||
      ((this.channel || {}).station || {}).productContexts ||
      [];

    if (!productContexts.length) {
      return false;
    }

    return !!productContexts.find((context) => {
      return context.type === productContextTypes.t6;
    });
  }

  isVirtualStream() {
    return (this.findPlayableStream() || {}).streamType === 'virtual' || (this.findPlayableStream() || {}).type === 'Virtual';
  }
}

export default WatchableModel;
