import authTokenProvider from 'lib/auth-token-provider';

/**
 * API middleware for retrying failed requests.
 *
 * Each request will be attempted upto the value provided by the
 * `retry` request option. The count of retries will reset for a `403-103`
 * response (and further retries will be held until a new auth token is
 * available).
 *
 * In the event all attempts are failures, the result will be the failure
 * response of the last request.
 *
 * This middleware provides a `retriesRemaining` option to downstream
 * middleware functions.
 *
 * @memberof module:API
 */
class RetryMiddleware {
  async delay() {
    await new Promise((resolve) => setTimeout(resolve, 500));
  }

  // No jsdoc
  handler = async (url, { retry, ...options }, next) => {
    if (!retry || retry <= 0) {
      retry = 0;
    }

    do {
      try {
        return await next(url, {
          ...options,
          retriesRemaining: retry
        });
      } catch (err) {
        if (err.xtv && err.xtv.subCode === '501-102') {
          // User hit something they should not have -- Do not retry
          throw err;
        }

        if (err.xtv && err.status === 403 && err.xtv.subCode === '103') {
          retry = retry + 1; // Additional retry

          authTokenProvider.onExpiredToken();
          await authTokenProvider.tokenRefreshPromise;
        }

        const willRetry = (retry > 0);

        if (!willRetry) {
          throw err;
        }
      }
      retry--;
      // TODO: Adding backoff or circuit breaker
      await this.delay();
    } while (retry >= 0);
  }
}

export default RetryMiddleware;
