/**
 * @module PPJSLogger
 * @desc Contains player platform js logging features
 */
import { dispatches } from 'lib/event-dispatcher';
import Logger from 'lib/logger';
import playerEvents from 'constants/player-events';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import player from '../../player';

const logger = new Logger('PLAYERPLATFORM LOGGING', { background: 'darkblue', color: 'white' });

/* eslint-enable valid-jsdoc */
@dispatches('ppjs-logger')
/**
 * Player Platform JS Logging
 * @hideconstructor
 */
class PPJSLogger {
    api = {};
    logLevel = '';
    player = {};
    playerPlatform = {};
    playerListeners = { [playerEvents.playerReady]: async ({ detail }) => {
      logger.logBlock('LOGGER playerReady FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
    } };
    init(streamPlayer) {
      this.playerPlatform = streamPlayer.playerPlatform;
      this.api = streamPlayer.api;
      this.player = streamPlayer;
      player.addEventListenerCollection(this.playerListeners);
      logger.log(`this player: ${this.player} ppjs ${this.api.getVersion()}`);
      const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
      const settingsSticky = cacToolLocalStorage.settingsSticky || false;

      if (settingsSticky) {
        this.addPPJSLogging();
      }
      this.logLevel = this.playerPlatform.LogLevel['WARN'];
      this.api && this.api.player.setLogLevel(this.logLevel);
    }
    setPPJSLogLevel(level) {
      if (!this.playerPlatform) {
        return 'Player Platform not ready! Try after playback is started';
      }
      this.logLevel = level;
      this.api && this.api.player.setLogLevel(level);
    }
    getLogLevel() {
      if (!this.api) {
        return 'Log level not ready. Player Platform not ready! Try after playback is started';
      }
      return this.api.logger.level;
    }
    getPPJSDiagnosis() {
      if (!this.api && this.api.player && this.api.player.playerReady) {
        return 'Player Platform not ready! Try after playback is started';
      }
      const ppDiagnosis = this.api.getDiagnostics();
      const diagnosisResult = ppDiagnosis.length > 0 ? diagnosisResult : '';
      const ppjsData = {
        getDiagnostics: diagnosisResult
      };
      logger.log(`[PPJS Diagnosis] result: ${JSON.stringify(ppjsData, undefined, 4)}`);
      return ppjsData;
    }
    togglePPJSVideoMonitor() {
      if (!this.api && this.api.player && this.api.player.playerReady) {
        return 'Player Platform not ready! Try after playback is started';
      }
      if (this.ppjsVideoMonitor) {
        this.api.hideVideoObjectMonitor();
        this.ppjsVideoMonitor = false;
        return;
      }
      this.ppjsVideoMonitor = true;
      this.api.showVideoObjectMonitor();
    }
    getPPJSDetails() {
      if (!this.api && this.api.player && this.api.player.playerReady) {
        return 'Player Platform not ready! Try after playback is started';
      }
      const localSettingsStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
      const settingsSticky = localSettingsStorage.settingsSticky || false;
      const ppjsConsoleLogging = localSettingsStorage.ppjsConsoleLogging || false;
      const ppjsData = {
        ppLogLevel: this.logLevel,
        settingsSticky: settingsSticky,
        ppjsConsoleLogging: ppjsConsoleLogging,
        version: this.api.getVersion(),
        playerStatus: this.api.getPlayerStatus(),

        assetType: this.api.getAssetType(),
        assetEngineType: this.api.getAssetEngineType(),
        getEndPosition: this.api.getEndPosition(),
        getStartPosition: this.api.getStartPosition(),
        getManifestStart: this.api.getManifestStart(),
        getManifestOffset: this.api.getManifestOffset(),
        getCurrentPlaybackSpeed: this.api.getCurrentPlaybackSpeed(),
        getClosedCaptionsStatus: this.api.getClosedCaptionsStatus(),
        getBitrateRange: this.api.getBitrateRange(),
        getAvailableBitrates: this.api.getAvailableBitrates(),
        getCurrentBitrate: this.api.getCurrentBitrate(),
        getBufferTime: this.api.getBufferTime(),
        getBufferFilledLength: this.api.getBufferFilledLength(),
        getCurrentAudioLanguage: this.api.getCurrentAudioLanguage(),
        getCurrentClosedCaptionTrack: this.api.getCurrentClosedCaptionTrack(),
        getVideoFormat: this.api.getVideoFormat(),
        getVideoType: this.api.getVideoType(),
        hasDRM: this.api.hasDRM(),
        currentFPS: this.api.getCurrentFPS(),
        currentDroppedFrames: this.api.getCurrentDroppedFrames()
      };
      return ppjsData;
    }
    removePPJSLogging() {
      const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
      cacToolLocalStorage.ppjsConsoleLogging = false;
      this.ppjsConsoleLogging = false;
      this.playerPlatform.setLogSinks([]);

      localStorage.setItem('tv-streamCast-cac-tool', JSON.stringify(cacToolLocalStorage));
    }
    addPPJSLogging() {
      const streamSink = new PPJSLogSink(console.trace);
      this.playerPlatform.setLogSinks([streamSink]);
      const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
      cacToolLocalStorage.ppjsConsoleLogging = true;
      this.ppjsConsoleLogging = true;
      localStorage.setItem('tv-streamCast-cac-tool', JSON.stringify(cacToolLocalStorage));
    }
}
export default new PPJSLogger(player);

export class PPJSLogSink {
  constructor(msgSender) {
    this.msgSender = msgSender;
  }
  log(level, name, msg) {
    const logMessage = msg && msg.join(',') || 'no log message';
    const message = `Level:${level} - Name:${name} - ppJSLog: ${logMessage}`;
    if (this.msgSender) {
      console.log('PP: [logLevelName] '+ logMessage);
      senderDebugger.debugPlayerPlatformLog('[PlayerPlatformLog]: ', {
        message: message,
        logMessage: logMessage,
        name: name,
        level: level
      });
    }
  }
}
