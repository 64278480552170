/* eslint-disable no-console */
import Logger from 'lib/logger';
import wsslogger from 'sundog/wss-logger';
import player from 'player';
import config from 'config';
import { formatTimeOfDay } from 'lib/helpers';
import messageTypes from 'constants/message-types';
import castMessenger from 'cast-messenger';
import controllerEvents from 'constants/controller-events';

const logger = new Logger('CAST-SUNDOG', { background: 'black', color: 'white' });


/**
 * sundog
 *
 * Wrapper around wssLogger
 *
 * @class sundog
 * @hideconstructor
 */
class Sundog {
  controllerListeners = {
    [controllerEvents.load]: ({ detail }) => {
      logger.log(`controller loaded media: ${detail.media} : customData: ${detail.customData}`);
      logger.log(`controller loaded app enabled: ${wsslogger.enable} session: ${JSON.stringify(this.app.sessionDetails)}`);
      const { sessionId } = detail.media.customData;
      console.log(`request auth in sundog: ${JSON.stringify(this.app.sessionDetails)}`);
      // set the wsslogger device ID (use account deviceId during init).
      // commented out for now - maybe needed to ID Vizio devices (chromecast built-ins)
      // otherwise would always be a random UUID at every playback session.
      if (wsslogger.enable && wsslogger ) {
        if (this.app.session.tokenSummary.deviceId) {
          wsslogger.saveUUID(`${formatTimeOfDay(Date.now())}_devId:${this.app.session.tokenSummary.deviceId}`);
          if (typeof Storage !== 'undefined' && window.localStorage !== undefined) {
            window.localStorage.setItem('Chromecast.deviceID',
              `${formatTimeOfDay(Date.now())}_devId:${this.session.tokenSummary.deviceId}`);
          }
        } else {
          wsslogger.saveUUID(`${formatTimeOfDay(Date.now())}_devId:na`);
        }
      }
      try {
        this.session = this.app.sessionDetails[sessionId];

        logger.log(JSON.stringify({
          status: 'SUCCESS '+ JSON.stringify(this.session),
          sessionId
        }));
      } catch (error) {
        logger.log(JSON.stringify({
          status: 'FAILED',
          sessionId,
          error: {
            code: error.code,
            subCode: error.subCode
          }
        }));
      }
    } };
  session = {};
  senderListeners = {
    [messageTypes.controllerCreated]: ({ detail }) => {
      this.playerController = detail.controller;
      this.playerController.addEventListenerCollection(this.controllerListeners);
    }
  };

  async init(app) {
    window.wsslogger = wsslogger;
    const wssService = config.wssService ? config.wssService : 'https://sundog.player.xcal.tv';
    const partition = config.partition ? config.partition : '/wpollard';
    /** logger Init Promises */
    const loggerInitPromise = wsslogger.init(wssService, partition);
    await loggerInitPromise;

    this.app = app;
    // update for yarn test
    if (wsslogger && wsslogger.enable) {
      wsslogger.enable( wssService, partition );
      wsslogger.setRpcCallback( this.onRpcReq.bind(this) );
      wsslogger.setOnCommandCallback( this.onCommand.bind(this) );
    }

    castMessenger.addEventListenerCollection(this.senderListeners);
    console.log('%%%%%%%%% wsslogger set ' + Object.keys(window.wsslogger) + ' castMess ' + castMessenger);
  }

  saveUUID(nameStr) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    const todayLabel = mm + '/' + dd + '/' + yyyy;

    wsslogger.saveUUID(`${formatTimeOfDay(Date.now())}_${todayLabel}_${nameStr}`);
  }
  onRpcReq(req) {
    // Call directly into PP API.
    const fnName = req.fnName;
    const reqId = req.reqId; // Who to send response to
    const clientReqId = req.clientReqId; // Requestor's own unique ID for this call
    const pp = player.playerPlatform;

    console.info( 'Got RPC call: ', fnName);

    if (pp[fnName]) {
      const rc = pp[fnName](...req.args);

      wsslogger.sendRpcRsp(reqId, clientReqId, fnName, rc);
    } else {
      logger.error( 'ERROR -> invalid RPC fn name: ', fnName);
    }
  }

  onCommand(command) {
    // Call directly into Chromecast API.
    const line = command.line;
    const reqId = command.reqId; // Who to send response to
    const clientReqId = command.clientReqId; // Requestor's own unique ID for this call

    console.warn( `Got command line: ${line}` );
    try {
      const rc = eval( line );
      wsslogger.response(reqId, clientReqId, line, rc);
    } catch (e) {
      const errorln = `${line} threw error ${JSON.stringify(e)}`;
      wsslogger.response(reqId, clientReqId, line, errorln);
      logger.error(errorln);
    }
  }
}
export default Sundog;
export { Sundog };
