import { LitElement, html, css, customElement, property } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { isLocalized, t } from 'i18n';
import { buildSrcSet } from 'lib/helpers';

import 'components/cast-spinner';

import castIcon from 'icons/cast-connected.svg';

/**
 * cast-idle component
 *
 * Idle state component behind the player. Display entity background image,
 * "Ready to Cast" message, partner logo, and initial cast-spinner
 *
 * @class cast-idle
 * @hideconstructor
 */
@customElement('cast-idle')
@isLocalized
class Idle extends LitElement {
  /**
   * Idle component state
   *
   * One of `"loading"`, `"playing"`, or `"ready"`
   *
   * @name cast-idle#state
   * @type {string}
   * @default "loading"
   */
  @property({
    type: String
  })
  state = 'loading';

  /**
   * HyperGard action for background image URL
   *
   * @name cast-idle#backgroundAction
   * @type {object|null}
   * @default null
   */
  @property({
    type: Object
  })
  backgroundAction = null;

  static styles = css`
    /* Make spinner zero-height so logo stays on ceter-line */
    .content {
      overflow: visible;
      height: 0;
      font-size: 3.6rem;
      font-weight: 400;
      color: var(--tv-grey-36);
    }

    cast-spinner {
      margin-top: 9rem;
      height: 2.4rem;
    }

    .ready {
      margin-top: 9rem;
      display: flex;
      align-items: center;
    }

    .ready svg {
      width: 3.3rem;
      height: 3.3rem;
      margin-right: 1.8rem;
      margin-top: 0.4rem;
    }
  `;

  backgroundImageUrl(params) {
    if (!this.backgroundAction) {
      return '';
    }

    return `url(${ this.backgroundAction.setParams({ rule: '16x9_KeyArt', ...params }).getActionUrl() })`;
  }

  render() {
    if (this.state === 'playing') {
      const srcSet = buildSrcSet({
        '1x': this.backgroundImageUrl({ width: 1280, height: 720 }),
        '1.5x': this.backgroundImageUrl({ width: 1920, height: 1080 })
      });
      return html`
        <style>
          :host {
            background: -webkit-image-set(
              ${ srcSet }
            );
            background-size: cover;
          }
        </style>
      `;
    }

    return html`
      <slot name="logo"></slot>
      <div class="content">
        ${ this.state === 'loading' ? html`<cast-spinner active slot="spinner"/>` : null }
        ${ this.state === 'ready' ? html`<div class="ready">${ unsafeHTML(castIcon) } ${ t('Ready to cast') }</div>` : null }
      </div>
    `;
  }
}

export default Idle;
