import Logger from 'lib/logger';
import player from 'player';
import splunkLogger from 'lib/telemetry/splunk-logger';
const logger = new Logger('MONITOR', { background: 'bright_green' });

/**
 * Seek Monitor - ensures that playback resumes after ad breaks correctly
 *  AAEWT-11119 - Casting_Receiver_T6VOD_Playback does not resume from scrubbed point and it resumes from the end of the ad playback
 * @module seekMonitor
 * @class seekMonitor
 * @hideconstructor
 */
class SeekMonitor {
  isAfterAdBreak = false;
  ppSeekStartPosition = 0;
  ppSeekCompletePosition = 0;
  seekInterval = null;
  SEEK_ERROR_MARGIN = 3000; // when checking a seek and if an ad break begins ensure it's within this margin of error
  userRequestedPosition = 0;

  /**
   * checkPosition - when called will wait until ad break complete to check if
   * the user has been placed in the correct position.
   * Limit to t6 vod VEX behaviors
   * Splunk: eventType: 'seekAfterAdAttempt'
   */
  checkPosition() {
    if (player.watchable && (player.watchable.getTypeLabel() === 'T6 VOD' || player.watchable.getTypeLabel() === 'TVE VOD')) {
      const aboveMargin = this.userRequestedPosition + this.SEEK_ERROR_MARGIN;
      const belowMargin = this.userRequestedPosition - this.SEEK_ERROR_MARGIN;
      const adModePP = player.api.adManager && player.api.adManager.isAdPlaying() || player.adData.adsPlaying;
      logger.logBlock('@@@@ CHECK POSITION: ', (logger) => {
        logger.log(' this.userRequestedPosition: ' + this.userRequestedPosition
        +' aboveMargin: ' + aboveMargin + ' belowMargin: ' + belowMargin);
        logger.log('adModePP: ' + adModePP
        + ' player.playerPosition: ' + player.playerPosition
        + ' adModePP: ' + adModePP
        + ' getp player status: ' + player.api.getPlayerStatus());
      });

      clearInterval(this.seekInterval);
      this.seekInterval = null;
      this.seekInterval = setInterval(()=>{
        const aboveMargin = this.userRequestedPosition + this.SEEK_ERROR_MARGIN;
        const belowMargin = this.userRequestedPosition - this.SEEK_ERROR_MARGIN;
        const adModePP = player.api.adManager && player.api.adManager.isAdPlaying() || player.adData.adsPlaying;
        logger.log('@@@@ CHECK POSITION: this.userRequestedPosition: ' + this.userRequestedPosition
        +' aboveMargin: ' + aboveMargin + ' belowMargin: ' + belowMargin);
        logger.log('@@@@ CHECK POSITION: adModePP: ' + adModePP
        + ' player.playerPosition: ' + player.playerPosition
        + ' adModePP: ' + adModePP
        + ' getp player status: ' + player.api.getPlayerStatus());
        if (this.userRequestedPosition && !adModePP) {
          if (player.playerPosition <= belowMargin && player.api.getPlayerStatus() === 'playing') {
            player.onSetPosition({
              msec: this.userRequestedPosition
            });

            const healInfo = {
              code: 'healSeekAfterAds',
              description: player.watchable.getTypeLabel()+' VOD position adjusted by app instead of PlayerPlatform'
            };
            splunkLogger.seekAfterAdAttempt({ watchable: player.watchable, healInfo });
            logger.log('seek monitor seek requested:', this.userRequestedPosition);
            this.reset();
            return;
          }
        }
        if ((player.playerPosition >= aboveMargin || player.playerPosition >= belowMargin)
          && player.api && player.api.getPlayerStatus() !== 'idle') {
          this.reset();
        }
      }, 2000);
    }
  }
  adBreakComplete(videoAdBreak) {
    logger.logBlock('@@@@@@@@@@@@@@@@  MEDIA PROGRESS', (logger) => {
      logger.log('@@@ AD BREAK COMPLETE setting isAfterAdBreak to true :' +
      ' userRequestedPosition: ' + this.userRequestedPosition +
      ' videoAdBreak end time: ' + videoAdBreak.endTime);
    });
    this.isAfterAdBreak = true;
    if (this.userRequestedPosition === 0) {
      this.userRequestedPosition = videoAdBreak.endTime;
    }
  }
  mediaProgressCheck() {
    if (this.isAfterAdBreak) {
      logger.logBlock('@@@@@@@@@@@@@@@@  MEDIA PROGRESS', (logger) => {
        logger.log('MEDIA PROGRESS WITH IS AFTER AD BREAK playerPosition: ' + this.playerPosition
    + ' seek monitor userRequestedPosition: ' + this.userRequestedPosition
    + ' duration currently on rec side: ' + this.duration );
      });
      this.checkPosition();
      player._onDurationChanged();
      return;
    }
    if (player.api.getPlayerStatus() === 'playing' && player.playerPosition > this.userRequestedPosition && !player.adMode) {
      this.userRequestedPosition = 0;
    }
  }
  reset() {
    clearInterval(this.seekInterval);
    this.seekInterval = null;
    this.userRequestedPosition = 0;
  }
}


export default new SeekMonitor();
