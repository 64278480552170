/**
 * @memberof module:Player
 */
import Logger from 'lib/logger';
import { getProperty } from 'lib/helpers';
import config from 'config';
import adManager from '../../constants/ad-manager-keys';

const logger = new Logger('FREEWHEEL', { background: 'white', color: 'red' });

/**
 * adds configuration values to content options
 */

export const buildManifestDAI = ({ contentOptions, deviceId }) => {
  contentOptions.adConfig = config.t6VodManifestAds ? {
    type: adManager.MANIFEST,
    terminalAddress: deviceId
  } : { type: adManager.NONE };
  logger.log('FW linear ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const buildFWVodAds = ({ contentOptions, watchable, currentTokenSummary }) => {
  const adConfig = watchable.isTve() || watchable.hasTveContextType() ?
    getFreewheelConfig({ type: 'vodTve' }) :
    getFreewheelConfig({ type: 'vodT6' });

  contentOptions.adConfig = config.freewheelTveVodEnabled || config.freewheelT6VodEnabled ? {
    ...adConfig,
    siteSectionFallbackBrand: watchable.adBrand,
    type: adManager.FREEWHEELVOD
  } : { type: adManager.NONE };
  contentOptions.brand = watchable.adBrand;
  contentOptions.serviceZip = currentTokenSummary.servicePostalCode;
  logger.log('FW vod ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const buildFWTVELinearAds = ({ contentOptions, watchable, currentTokenSummary, assetEngine }) => {
  contentOptions.adConfig = config.freewheelTveLinearEnabled ? {
    assetFallbackId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetFallbackId') ||
      getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelAssetFallbackId'),
    assetNetworkId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetNetworkId') ||
      getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelVideoAssetNetworkId'),
    type: adManager.FREEWHEELINTIMELINE,
    ...getFreewheelConfig({ type: 'linearTve' })
  } : { type: adManager.NONE };
  contentOptions.brand = getProperty(watchable.findPlayableStream(), 'adInfo.adBrand') ||
    getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelBrand');
  contentOptions.serviceZip = currentTokenSummary.servicePostalCode;
  logger.log('FW linear ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const buildFWT6LinearAds = ({ contentOptions, watchable, currentTokenSummary, assetEngine }) => {
  contentOptions.adConfig = config.freewheelT6LinearEnabled ? {
    assetFallbackId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetFallbackId') ||
      getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelAssetFallbackId'),
    assetNetworkId: getProperty(watchable.findPlayableStream(), 'adInfo.freewheelAssetNetworkId') ||
      getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelVideoAssetNetworkId'),
    type: adManager.FREEWHEELINTIMELINE,
    ...getFreewheelConfig({ type: 'linearT6' })
  } : { type: adManager.NONE };
  contentOptions.brand = getProperty(watchable.findPlayableStream(), 'adInfo.adBrand') ||
    getProperty(watchable.findPlayableStream(), 'playerConfig.freewheelBrand');
  contentOptions.serviceZip = currentTokenSummary.servicePostalCode;
  logger.log('FW linear ads built: ' + JSON.stringify(contentOptions.adConfig));
};

export const getFreewheelConfig = ({ type }) => {
  if (type === 'linearTve') {
    return config.freewheelConfigs.tveLinear;
  } else if (type === 'linearT6') {
    return config.freewheelConfigs.title6Linear;
  } else if (type === 'vodTve') {
    return config.freewheelConfigs.tveVod;
  }
  return config.freewheelConfigs.title6Vod;
};
