/**
 * API middleware to add `Fingerprint` header to requests
 *
 * The fingerprint is made by concatenating the `fingerprint` option provided
 * to the constructor, the current timestamp, and a random number.
 *
 * This should be after [RetryMiddleware]{@link module:API.RetryMiddleware} so
 * that a unique fingerprint is added to each retried request.
 *
 * @memberof module:API
 */
class FingerprintMiddleware {
  /**
   * @param {object} options
   * @param {string} options.fingerprint - Fingerprint prefix
   */
  constructor({ fingerprint }) {
    this._fingerprint = fingerprint || '';
  }

  generateFingerprint() {
    return [
      this._fingerprint,
      Date.now(),
      Math.round(Math.random() * 0xffff)
    ].join('_');
  }

  /**
   * Most recently used fingerprint
   */
  get lastFingerprint() {
    return this._lastFingerprint;
  }

  // No jsdoc
  handler = (url, { headers, ...options }, next) => {
    const fingerprint = this.generateFingerprint();

    this._lastFingerprint = fingerprint;

    return next(url, {
      headers: {
        ...headers,
        Fingerprint: fingerprint
      },
      ...options
    });
  }
}

export default FingerprintMiddleware;
