import authTokenProvider from 'lib/auth-token-provider';

/**
 * API middleware for adding authorization header from AuthTokenProvider
 *
 * This should be after [RetryMiddleware]{@link module:API.RetryMiddleware} so
 * that the most up-to-date authorization header is used for retried requests.
 *
 * @memberof module:API
 */
class AuthTokenMiddleware {
  // No jsdoc
  handler = (url, { headers, ...options }, next) => {
    return next(url, {
      headers: {
        ...authTokenProvider.tokenHeader,
        ...headers
      },
      ...options
    });
  }
}

export default AuthTokenMiddleware;
