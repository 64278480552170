import * as CastLocalStorage from './index.js';

class LocalSettings {
  constructor(options) {
    options = {
      name: 'settings',
      maxUsers: 3,
      migrate: [], // Old local storage keys to migrate to user-based keys.
      migrateJSON: false,
      ...options
    };

    this.options = {
      ACCOUNTS: options.name + '-acc',
      MAX_USERS: options.maxUsers,
      SETTINGS_AUTH: options.name + '-',
      SETTINGS_UNAUTH: options.name + '-u',
      MIGRATE: options.migrate,
      MIGRATE_JSON: options.migrateJSON
    };
  }

  setAccountId(id) {
    this.accountId = id;
    this._saveAccountId(id);
    this._migrate();
  }

  save(name, value) {
    const change = {};
    change[name] = value;
    this._store(Object.assign(this._refreshSettings(this.options) || {}, change), this.options);
  }

  load(name, defaultValue) {
    const settings = this._refreshSettings(this.options);

    // Return default or undefined for empty values, but not 0 or false!
    if (settings && settings[name] !== undefined && settings[name] !== null && settings[name] !== '') {
      // Return default or undefined for empty objects
      if (typeof settings[name] !== 'object' || Object.keys(settings[name]).length) {
        return settings[name];
      }
      return defaultValue;
    }
    return defaultValue;
  }

  /*
    * Load multiple settings (will only load what is specified in defaults)
    * If the setting does not exist in localStorage return value in defaults
    */
  loadSettings(defaults) {
    const result = {};
    Object.keys(defaults).forEach((key) => {
      result[key] = this.load(key, defaults[key]);
    });
    return result;
  }

  loadNullable(names) {
    const defaults = {};
    names.forEach((name) => {
      defaults[name] = null;
    });
    return this.loadSettings(defaults);
  }

  remove(name) {
    const settings = this._refreshSettings();
    if (settings) {
      delete settings[name];
      this._store(settings);
      return true;
    }
    return false;
  }

  removeAll() {
    // Remove all local storage items controlled by the current instance of LocalSettings
    Object.keys(localStorage).forEach((key) => {
      if (key.indexOf(this.options.SETTINGS_AUTH) > -1) { // Allows for prefix added byCastLocalStorage
        // Note: do not use CastLocalStorage here, since we pass the key from localStorage
        localStorage.removeItem(key);
      }
    });
  }

  // Private methods
  _saveAccountId(id) {
    const storedAccountIds = this._read(this.options.ACCOUNTS) || [];
    let isChanged;

    const accountIds = storedAccountIds.filter((storedId, index) => {
      // Dedupe when current id is found anywhere but in first position
      return index === 0 || id !== storedId;
    });

    if (accountIds.length !== storedAccountIds.length) {
      isChanged = true;
    }

    if (accountIds[0] !== id) {
      // Keep current id in the first position
      accountIds.unshift(id);
      isChanged = true;
    }

    if (accountIds.length >= this.options.MAX_USERS) {
      // Remove old ids over the max
      accountIds.splice(this.options.MAX_USERS).forEach((id) => {
        // And remove old settings over the max from storage
        CastLocalStorage.removeItem(this.options.SETTINGS_AUTH + id);
      });
      isChanged = true;
    }

    if (isChanged) {
      // Update list of ids in storage
      CastLocalStorage.setItem(this.options.ACCOUNTS, JSON.stringify(accountIds));
    }
  }

  _read(key) {
    let settings;
    try {
      settings = JSON.parse(CastLocalStorage.getItem(key));
    } catch (e) {
      CastLocalStorage.removeItem(key);
    }
    return settings;
  }

  _storeUnAuth(val) {
    CastLocalStorage.setItem(this.options.SETTINGS_UNAUTH, JSON.stringify(val));
  }

  _storeAuth(val) {
    if (this.accountId) {
      CastLocalStorage.setItem(this.options.SETTINGS_AUTH + this.accountId, JSON.stringify(val));
    }
  }

  _store(val) {
    this._storeUnAuth(val);
    this._storeAuth(val);
  }

  _getStorage() {
    return {
      auth: this._read(this.options.SETTINGS_AUTH + this.accountId),
      unAuth: this._read(this.options.SETTINGS_UNAUTH)
    };
  }

  _refreshSettings() {
    const storage = this._getStorage();

    if (this.accountId && storage.auth) {
      // If user is logged in and has user-settings, make sure unauth settings match user settings.
      // Copy the auth settings to unauth settings
      // (Unauth settings always match the latest applicable settings)
      this._storeUnAuth(storage.auth);
    }

    return this.accountId ? storage.auth : storage.unAuth;
  }

  _migrate() {
    this.options.MIGRATE.forEach((name) => {
      let val = CastLocalStorage.getItem(name);
      if (val) {
        if (this.options.MIGRATE_JSON) {
          val = JSON.parse(val);
        }
        this.save(name, val);
        CastLocalStorage.removeItem(name);
      }
    });
  }
}

export default LocalSettings;
